import React from "react";
import style from "../../style/createArea.module.css";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy, faHandsHelping, faLink, faStop, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import QRCode from "qrcode.react"
import { cleanManageSuccess, fetchTeam, joinTeam, teamManageCleanSuccess } from "../../store/teams";


class TeamJoin extends React.Component {

  constructor(props){
    super(props)

    this.teamSlug = this.props.match.params.teamSlug;
    this.token = this.props.match.params.inviteToken;
  }

  handleSubmit = e => {
    e.preventDefault();
   this.props.joinTeam(this.token, this.teamSlug) 
  };

  componentDidMount() {
     this.props.fetchTeam(this.teamSlug)
  }
  
  componentWillUnmount() {
    this.props.cleanSuccess()
  }

  render() {
    const {error, loading, authenticated, team, joinLoading, joinSuccess} = this.props;
    if(joinSuccess) this.props.history.push("/teams/"+this.teamSlug)
    return (
        <div className={style.form}>
          <ReactTitle title="Team beitreten - TreePlantingProjects" /> 
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className={"col-md-6"}>
              <img className={"img-fluid"} src="/images/login_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              {!team ? (<h2 className={style.mainHeading}>Team wird geladen....</h2>):(
                <>
              <h2 className={style.mainHeading}>Möchtest du {team.name} beitreten?</h2>
              <div className={"row"}>
                <div className={"col-md-12"}>
                {/* display team card here */}
                {joinLoading ? (<p>Einen moment bitte</p>):(
                  <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>
                    <div className="text-left">
                      {authenticated ? (
                        <button type="submit" className={style.formBtn}>
                        <FontAwesomeIcon icon={faHandsHelping} className="fa-fw" />
                        Team beitreten</button>
                        ):(
                          <button type="button" className={style.formBtn} onClick={()=>this.props.history.push(`/login?redirectTo=${window.location.pathname}`)}>
                          <FontAwesomeIcon icon={faHandsHelping} className="fa-fw" />
                          Team beitreten</button>
                        )}
                        <button type="button" className={`${style.formBtn} ${style.greyBtn} ml-1`}
                          onClick={()=>this.props.history.push(`/teams/${team.slug}`)}>
                        <FontAwesomeIcon icon={faStop} className="fa-fw" />
                        Abbrechen</button>
                    </div>
                  </form>
                  )}
                </div>
              </div>
              </>
              )}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authenticated: state.auth.token !== null,
    teams: state.teams.list,
    team: state.teams.list[ownProps.match.params.teamSlug],
    joinLoading: state.teams.joinLoading,
    joinSuccess: state.teams.joinSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeam: (slug) => dispatch(fetchTeam(false, slug)),
    joinTeam: (inviteToken, team) => dispatch(joinTeam(inviteToken, team)),
    cleanSuccess: () => dispatch(cleanManageSuccess())
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamJoin));
