import React, { useState } from "react";
import style from "../../style/Header.module.css";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCog, faEdit, faMegaphone } from "@fortawesome/pro-regular-svg-icons";

export default function Header({ data, titleSlug, isManager, isReporter }) {
  const { pageLinks, ActionBtn, mainBtn } = data;
  const [link, setLink] = useState(pageLinks[0]);
  return (
  <div className={style.menu}>
    <div style={{position: "sticky", top: "0", zIndex: "2"}}>
      <div
        className={`${style.Header} justify-content-center justify-content-lg-between`}
      >
        <div className="mb-2 mb-md-0">
          {pageLinks.map((item) => (
            <a key={item}
              href={`#${item}`}
              className={link == item ? style.active : ""}
              onClick={() => setLink(item)}
            >
              {item}
            </a>
          ))}
        </div>
        <div className="">
          {isReporter ? (
              <Link to={`${titleSlug}/moderate`} className={`${style.actionBtn} mb-2 mb-sm-0`}>
              <FontAwesomeIcon icon={faMegaphone} className="mr-1 fa-fw" />
                Moderation
              </Link>
          ):("")}
          {isManager ? (
            <>
              <Link to={`${titleSlug}/eventsmanage`} className={`${style.actionBtn} mb-2 mb-sm-0`}>
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw mr-1" />
                Aktionen
              </Link>
              <Link to={`${titleSlug}/settings`} className={`${style.actionBtn} mb-2 mb-sm-0`}>
                <FontAwesomeIcon icon={faCog} className="fa-fw mr-1" />
                Einstellungen
              </Link>
            </>
          ):("")}
        </div>
      </div>
    </div>
  </div>
  );
}
