import React from "react";
import style from "../../style/createArea.module.css";
import {Link, Redirect} from "react-router-dom";
import data from "../../data/projectRoles.json";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { joinProject } from "../../store/projects"
import {ReactTitle} from 'react-meta-tags';
import ProfileToggle from "../profile/ProfileToggle";
import { updatePublicProfile } from "../../store/profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckDouble, faPen } from "@fortawesome/pro-regular-svg-icons"; 
import { getProjectIdBySlug } from "../../store/utility";

class ProjectsJoin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      choosen: false,
      roles: []
    }
    this.roles = data;
  }

  handleClick = e => {
    this.props.joinProject(this.props.project, e, this.props.profile.profile_id);
    this.setState({choosen: true})
    if(this.props.profile.public_profile && this.profileCompleteCheck()) this.props.history.goBack();
  }

  profileCompleteCheck = () => {
    const {first_name, last_name, image_url, location} = this.props.profile;
    let completed = false;
    if(first_name && last_name && image_url && location) completed = true
    return completed
  }
  handleProfileToggle = e => {
    this.props.performUpdate()
    this.props.history.goBack();
  }

  componentDidMount() {
    this.setState({roles:data})
    if(this.props.members) {
      this.props.members.map((member => {
        if(member.user == this.props.profile.profile_id) {
          let arr = this.roles.filter(function(item) {
            return item.id !== member.role
          })
          this.roles = arr
          this.setState({roles: arr})
        }
      }))
    }
  }

  render() {
    const {error, loading, token, about, project, profile} = this.props;
    if (!token) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    const projectName = about.name;
    return (
        <div className={style.form}>
          <ReactTitle title={projectName+" beitreten - TreePlantingProjects"} />
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className="col-md-6">
              <img className={"img-fluid"} src="/images/join_project_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              {!this.state.choosen ? (
              <div>
              <h2 className={style.mainHeading}>Möchtest du {projectName} beitreten?</h2>
              <div className={style.mainText}>Du kannst <strong>{projectName}</strong> in folgenden Rollen unterstützen:</div>
              {this.state.roles.map((role => (
                <div className={"row pt-3"}>
                <div className={"col-md-12"}>
                <a onClick={() => {this.handleClick(role.id)}}>
                  <div className={style.userInfo}>
                    <div className={style.imageSide}>
                      <div className={style.darkOverlay} />
                      <img src={role.image} alt="" />
                      <img src="/images/Sergei_Filatov.svg" alt="" />
                    </div>
                    <div>
                      <div className={style.subHeading}>{role.name}</div>
                      <div className={style.mainText}>{role.description}</div>
                    </div>
                  </div>
                </a>
                </div>
                </div>
              )))}
              </div>
              ):(
                this.profileCompleteCheck() && !this.props.profile.public_profile ? (
                <div>
                  <h2 className={style.mainHeading}>Dich für andere sichtbar machen</h2>
                  <div className={style.mainText}>Sollen wir dein Profil unter dem Projekt <strong>{projectName}</strong> und anderen anzeigen?</div>
                  <div className={"row mt-5"}>
                    <div className={"col"}>
                      <ProfileToggle handleChange={this.handleProfileToggle}/>
                      <div className="text-left mt-2">
                        <button type="submit" onClick={this.props.history.goBack} className={style.formBtn}>
                          <FontAwesomeIcon className="fa-fw" icon={faCheckDouble} />
                          Fertig</button>
                      </div>
                    </div>
                  </div>
                </div>
                ):(
                  <div>
                  <h2 className={style.mainHeading}>Dich für andere sichtbar machen</h2>
                  <div className={style.mainText}>Du musst dein Profil vervollständigen wenn wir dich im Projekt <strong>{projectName}</strong> und anderen anzeigen sollen</div>
                  <div className={"row mt-5"}>
                    <div className={"col"}>
                      <form className={"mt-5"} action="" onSubmit={this.props.history.push("/settings")}>
                      <div className="text-left mt-2">
                        <button type="submit" className={style.formBtn}>
                          <FontAwesomeIcon className="fa-fw" icon={faPen} />
                          Profil ausfüllen</button>
                      </div>
                    </form>
                    </div>
                  </div>
                </div>
                )
              )}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id

  return {
    loading: state.auth.loading,
    profile: state.profile,
    project: id,
    about: state.projects.about[id],
    members: state.projects.members[id],
    error: state.auth.error,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    joinProject: (id, role, profile) => dispatch(joinProject(id, role, profile)),
    performUpdate: () => dispatch(updatePublicProfile())
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsJoin));
