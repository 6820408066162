import React from "react";
import { Link } from "react-router-dom";
import EventCard from "../EventCard";

export default function ProjectEvents({ events, project}) {
  const [ showAllEvents, setShowAllEvents ] = React.useState(false)
  let list = events
  if(events.length > 2 && !showAllEvents) list = [list[0], list[1]]
  return (
    <>
        {list.map((event => (
          <Link to={`/projects/${event.project_slug}/events/${event.id}`}>
            <EventCard projectId={project} key={event.id} event={event} />
          </Link>
        )))}
       {events.length > 2 ? ( 
       showAllEvents ? (
        <p style={{marginLeft: "1em", cursor: "pointer", fontSize: "2em"}} onClick={()=>setShowAllEvents(!showAllEvents)}>{showAllEvents ? 
          ("Weniger Aktionen anzeigen"):("+ "+(events.length-2)+"")}
        </p>
       ):(
            <p style={{marginLeft: "1em", cursor: "pointer", fontSize: "3em"}} onClick={()=>setShowAllEvents(!showAllEvents)}>{showAllEvents ? 
              ("Weniger Aktionen anzeigen"):("+ "+(events.length-2)+"")}
            </p>
            )
          ):("")}
        </>
  );
}
