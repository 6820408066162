import React from "react";
import style from "../../style/createArea.module.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import areas, { reportArea } from "../../store/areas";
import { toast } from "react-toastify";
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCube, faGlobeEurope, faImage, faLink, faMapMarkedAlt, faMapMarkerAlt, faPlus, faSave, faTree, faTreeAlt, faTreeLarge, faTrees } from "@fortawesome/pro-regular-svg-icons";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { createTeam } from "../../store/teams";
import CopyToClipboard from "react-copy-to-clipboard";
import { validURL } from "../../store/utility";

const styles = {

};


class NewTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploadState: "",
      name: "",
      link: "",
      image: null,
      about: "",
      treesToPlant: 0,
      stepper: 1
    }
    this.inputReference = React.createRef();

   }


  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    this.setState({ image: e.target.files[0] });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.stepper <= 4) return this.setStepper(this.state.stepper+1)
    this.setStepper(6)
    const { name, about, link, image, treesToPlant } = this.state;
    this.props.createNewTeam(name, about, link, image, treesToPlant);
    
  };

  setStepper = e => {
    this.setState({stepper: e})
  }

  resetStepper = () => {
    this.setState({stepper: 1})
  }

  render() {
    const { classes, children, className, ...other } = this.props;
    const { name, about, link, image, treesToPlant } = this.state;
    const { slug, inviteToken } = this.props.createSuccessData;
    
    if (!this.props.authenticated) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Team erstellen - TreePlantingProjects" /> 
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className="col-md-6">
            <img className={"img-fluid"} src="images/report_area_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <Link to="/teams" className={style.backLink}>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </Link>
            <h2 style={{marginTop: "0.8em"}}className={style.mainHeading}>Erstelle ein Team!</h2>
            <div className={style.mainText}>Am Ende bekommst du einen Link, mit dem du neue Teammitglieder einladen kannst.
              </div>
              <div className="mt-2">
                <LinearProgress variant="determinate" color="primary" className={classes.bar} value={(16.6*(this.state.stepper-1))} />
                
              </div>
            <form style={{marginTop: "2em"}} action="" onSubmit={this.handleSubmit}>
              {this.state.stepper === 1 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>1. Wie heißt euer Team?</h1>
                  <div className={style.formInput}>
                      <input
                        className={style.commonInput}
                        type="text"
                        onChange={this.handleChange}
                        value={name}
                        name="name"
                        required
                        placeholder="Name"
                        maxLength={50}
                      />
                      <FontAwesomeIcon icon={faGlobeEurope} className={style.inputIcon} />
                    </div>
                    <div className={"mt-3"}>
                   <button type="button" disabled={!name} onClick={()=>this.setStepper(2)} className={style.formBtn}>
                    Weiter
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                  </div>
                  </div>
                </div>}
                {this.state.stepper === 2 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>2. Ziel - Wie viele Bäume möchtet ihr pflanzen? (Optional)</h1>
                  <div className={style.formInput}>
                      <input
                        className={`${style.commonInput} ${style.inputHideArrow}`}
                        type="number"
                        onChange={this.handleChange}
                        name="treesToPlant"
                        defaultValue={treesToPlant}
                        placeholder="Jetzt bin ich gespannt ..."
                      />
                      <FontAwesomeIcon icon={faTreeAlt} className={style.inputIcon} />
                      </div>
                      <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(1)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button" onClick={()=>this.setStepper(3)} className={`${style.formBtn} ml-1`}>
                    {treesToPlant ? ("Weiter"):("Mach ich später")}
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                    </div>
                  </div>
                </div>}
                {this.state.stepper === 3 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>3. Stelle dein Team vor.</h1>
                  <div className={style.formInput}>
                    <textarea
                      className={style.commonInput}
                      onChange={this.handleChange}
                      value={about}
                      name="about"
                      cols="30"
                      rows="10"
                      required
                      maxLength={500}
                      placeholder="Erzähl etwas über euch! Wer seid Ihr? Wo kommt ihr her? Wie wollt ihr euch mit TreePlantingProjects engagieren?"
                    ></textarea>
                    </div>
                    <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(2)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button" disabled={!about} onClick={()=>this.setStepper(4)} className={`${style.formBtn} ml-1`}>
                    Weiter
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                    </div>
                  </div>
                </div>}
                {this.state.stepper === 4 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>4. Link zu eurer Webseite (Optional)</h1>
                  <div className={style.formInput}>
                      <input
                        className={style.commonInput}
                        type="url"
                        onChange={this.handleChange}
                        name="link"
                        defaultValue={link}
                        required
                        placeholder="Link der Webseite (Optional)"
                        pattern="https://.*"
                      />
                      <FontAwesomeIcon icon={faLink} className={style.inputIcon} />
                      <p>Beispiel: https://treeplantingprojects.com</p>
                      </div>
                      <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(3)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button" onClick={()=>this.setStepper(5)} disabled={!validURL(this.state.link)}className={`${style.formBtn} ml-1`}>
                    {link ? ("Weiter"):("Ohne Link")}
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                    </div>
                  </div>
                </div>}
                {this.state.stepper === 5 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>5. Hast du ein Bild parat? (Optional)</h1>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      readOnly="readOnly"
                      required
                      placeholder={this.state.fileUploadState ? (this.state.fileUploadState.replace("C:\\fakepath\\", "")) : ("Bild hochladen")}
                      onClick={this.fileUploadAction}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="image"
                      accept="image/*"
                      data-type='image'
                      hidden
                      ref={this.inputReference}
                      onChange={this.fileUploadInputChange}
                    />
                    <FontAwesomeIcon icon={faImage} className={style.inputIcon} />
                    <p className={style.required}>{!this.state.fileUploadState ? ("Das Bild kannst du auch später noch hochladen oder ändern."):("")}</p>
                    </div>
                    <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(4)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="submit" onClick={this.handleSubmit} className={`${style.formBtn} ml-1`}>
                    {!this.state.fileUploadState ? ("Ohne Bild"):("Weiter")}
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                    </div>
                  </div>
                </div>}
               
            </form>
            {this.state.stepper === 6 && <div>
                {this.props.createLoading && <p>Team wird angelegt <CircularProgress size={10}/></p>}
                {this.props.createSuccessData ? (
                  <div>
                    <p>Dein Team wurde erstellt 🎉</p><br/>
                    <p>Freunde einladen:</p>
                    <p>Mit diesem Link können TreePlanter deinem Team beitreten</p>
                    <CopyToClipboard text={`${window.location.origin}/teams/${slug}/join/${inviteToken}`}>
                    <div className={style.formInput} onClick={()=>toast.success('👍 Link in die Zwischenablage kopiert.')}>
                      <input
                        className={style.commonInput}
                        style={{cursor: "pointer", width: "340px"}}
                        type="text"
                        name="link"
                        id="link"
                        fluid="left"
                        required
                        value={`${window.location.origin}/teams/${slug}/join/${inviteToken}`}
                        readOnly
                      />
                      <FontAwesomeIcon icon={faLink} className={style.inputIcon} />
                      

                    </div>
                    </CopyToClipboard>
                    <button className={`${style.formBtn} ml-1`} onClick={()=>this.props.history.push('/teams/'+slug)}>
                      Los geht's
                    </button>
                  </div>
                ):("")}
                {this.props.error ? (
                   <div>
                     <p>Ein Fehler ist aufgetreten!</p><br/>
                     <code>{this.props.error.message}</code><br/><br/>
                     <button className={`${style.formBtn}`} onClick={this.resetStepper}>
                        Nochmal probieren
                      </button>
                    </div>
                  ):("")}
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.teams.createloading,
    createLoading: state.teams.createLoading,
    error: state.teams.createError,
    createSuccessData: state.teams.createSuccessData,
    authenticated: state.auth.token,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createNewTeam: (name, about, link, image, treesToPlant) => {
      dispatch(createTeam(name, about, image, link, treesToPlant))
    }
  };
};



export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTeam));
