import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

export default function Information({ data, imagesLoaded }) {
  const { about, images } = data;
  return (
    <div className="row">
      <div className={images ? ("col-md-8 mb-3 mb-md-0") : ("col-md-12 mb-3 mb-md-0")}>
        <div
          className="bg-white h-100"
          style={{ padding: "1.5em", borderRadius: "2em" }}
        >
          <p
          >
            { about ? (
              about.information.split('\r\n').map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                )
              })):(
                <>
                <p><Skeleton height={10} width="80%"/></p>
                <p><Skeleton height={10} width="70%"/></p>
                <p><Skeleton height={10} width="60%"/></p>
                </>
              )
            }
          </p>
        </div>
      </div>
      {images ? (
        <div className="col-md-4 ">
          <div
            className="row bg-white"
            style={{ padding: "1.5em 0", borderRadius: "2em" }}
          >
            <div className="col-12">
              <div>
                <img
                  src={images[0].image}
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "2em" }}
                />
              </div>
            </div>
            {images.slice(1).splice(0,3).map((image => (
              <div className="col-md-4 mt-3">
                <img
                  src={image.image}
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "2em" }}
                />
              </div>
            )))}
          </div>
        </div>
        ):(
        ""
      )}

    </div>
  );
}
