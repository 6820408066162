import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { toast } from "react-toastify";
import { googleSocialLogin } from "../store/auth";
import { connect } from "react-redux";

class GoogleSocialAuth extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const onSuccess = (response) => {
      this.props.googleSocialLogin(response.accessToken)
    }
    const onFailure = (response) => {
      console.log(response)
    }
    return (
      <GoogleLogin
        clientId={"517857437085-6hnjreb3rfs0mhtbttefeojtataf1csa.apps.googleusercontent.com"}
        buttonText="Google"
        onSuccess={onSuccess}
        cssClass="btnGoogle"
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <button class="btnGoogle" onClick={renderProps.onClick} disabled={renderProps.disabled}>
            Google
          </button>
        )}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    googleSocialLogin: (access_token) => dispatch(googleSocialLogin(access_token)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(GoogleSocialAuth);
