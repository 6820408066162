import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


const slice = createSlice({
    name: 'admin',
    initialState: {
        stats: {
            live_projects: 0,
            saved_area: 0,
            all_area: 0,
            total_users: 0,
            open_events: 0,
            last_updated: '',
            event_algo: 0 
        },
        statsLoading: false,
        talkServerLoading: true,
        talkServerData: {
            status: 0,
            statusName: "loading",
            action: "n/a",
            created: "n/a",
            updated: "n/a",
            host: "..."
        },
        algoChangeLoading: false,
        users: [],
        usersFetchLoading: false
    },
    reducers: {
        adminStatsFetchLoading: (admin) => {
            admin.statsLoading = true
        },
        adminStatsFetchSuccess: (admin, action) => {
            admin.stats = action.payload
            admin.statsLoading = false
        },
        adminStatsFetchFail: (admin) => {
            admin.statsLoading = false
        },
        adminFetchTalkServerLoading: (admin) => {
            admin.talkServerLoading = true;
            //admin.talkServerData.statusName = "Connecting..."
            //admin.talkServerData.status = 1
        },
        adminFetchTalkServerSuccess: (admin, action) => {
            admin.talkServerLoading = false;
            admin.talkServerData = {
                status: action.payload.status,
                statusName: action.payload.status_name,
                action: action.payload.action,
                created: action.payload.created,
                updated: action.payload.updated,
                serverStatus: action.payload.server_status,
                serverStatusName: action.payload.server_status_name,
                serverHost: action.payload.server_host
            }
         },
        adminFetchTalkServerFail: (admin) => {
            admin.talkServerLoading = false
            
        },
        adminTriggerTalkServerLoading: admin => {
            admin.talkServerTriggerLoading = false;
            admin.talkServerData.status = 0;
            admin.talkServerData.statusName = "Connecting..."
        },
        adminTriggerTalkServerSuccess: admin => {
            admin.talkServerTriggerLoading = false;
        },
        adminTriggerTalkServerFail: admin => {
            admin.talkServerTriggerLoading = false;
        },
        adminAlgoChangeLoading: admin => {
            admin.algoChangeLoading = true;
        },
        adminAlgoChangeSuccess: admin => {
            admin.algoChangeLoading = false;
            toast.success('👍 Algorithmus aktualisiert')

        },
        adminAlgoChangeFail: admin => {
            admin.algoChangeLoading = false;
        },
        adminUsersFetchLoading: admin => {
            admin.usersFetchLoading = true;
        },
        adminUsersFetchSuccess: (admin, action) => {
            admin.usersFetchLoading = false;
            admin.users = action.payload;
        },
        adminUsersFetchFail: admin => {
            admin.usersFetchLoading = false;
        }
    }
});

export const { 
    adminStatsFetchLoading, adminStatsFetchSuccess, adminStatsFetchFail,
    adminFetchTalkServerLoading, adminFetchTalkServerSuccess, adminFetchTalkServerFail,
    adminTriggerTalkServerLoading, adminTriggerTalkServerSuccess, adminTriggerTalkServerFail,
    adminAlgoChangeLoading, adminAlgoChangeSuccess, adminAlgoChangeFail,
    adminUsersFetchLoading, adminUsersFetchSuccess, adminUsersFetchFail
             
} = slice.actions
export default slice.reducer;

// Actions
export const fetchAdminStats = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/stats",
                method: "GET",
                auth: true,
                onSuccess: adminStatsFetchSuccess.type,
                onLoading: adminStatsFetchLoading.type,
                onError: adminStatsFetchFail.type
            }
        })
    };
};

export const fetchTalkServerStatus = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/talk_server",
                method: "GET",
                auth: true,
                onSuccess: adminFetchTalkServerSuccess.type,
                onLoading: adminFetchTalkServerLoading.type,
                onError: adminFetchTalkServerFail.type
            }
        })
    };
};


export const triggerTalkServer = (action) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/talk_server",
                method: "POST",
                auth: true,
                data: {action},
                onSuccess: adminTriggerTalkServerSuccess.type,
                onLoading: adminTriggerTalkServerLoading.type,
                onError: adminTriggerTalkServerFail.type
            }
        })
    };
};

export const changeAlgo = (algo) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/changeAlgo",
                method: "POST",
                auth: true,
                data: {algo},
                onSuccess: adminAlgoChangeLoading.type,
                onLoading: adminAlgoChangeSuccess.type,
                onError: adminAlgoChangeFail.type,
                onSuccessAction: fetchAdminStats
             }
        })
    };
};


export const fetchUsers = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/admin-api/users",
                method: "GET",
                auth: true,
                onSuccess: adminUsersFetchSuccess.type,
                onLoading: adminUsersFetchLoading.type,
                onError: adminUsersFetchFail.type,
             }
        })
    };
};
