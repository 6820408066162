import React from "react";
//import ProjectAreas from "../components/ProjectAreas";
import ProjectGoal from "../components/projects/ProjectGoal";
import style from "../style/projectDeatil.module.css";
import ProjectAreas from "../components/projects/ProjectAreas";
import ProjectMembers from "../components/projects/ProjectMembers";
import MobProjectGoal from "../components/projects/MobProjectGoal";
import Information from "../components/Information";
import Header from "../components/projects/Header";
import data from "../data/projectDetail.json";
import defaultNews from "../data/defaultNews.json";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';;


import { connect } from "react-redux";
import {
  fetchProjectDetails,
  fetchProjectDetailsData,
  resetProjectsLoad,
  checkIfLoaded,
} from "../store/projects";
import ProjectNews from "../components/projects/ProjectNews";
import { getEventsByProjectId, getProjectIdBySlug } from "../store/utility";
import ProjectEvents from "../components/projects/ProjectEvents";
import LoadingGoal from "../components/projects/LoadingGoal";
import LoadingCard from "../components/projects/LoadingCard";
import SmallCardLoading from "../components/SmallCard";
import LoadingHeader from "../components/projects/LoadingHeader";
import TeamCard from "../components/TeamCard";

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.project_name = this.props.match.params.projectName;
  }

  componentDidMount() {
    this.props.resetProjectDetailsLoad()
    var result = this.props.p_map.filter(obj => {
      return obj.title_slug === this.project_name
    })
    if(!result.length) result = [{id: this.project_name}]
    this.props.getProjectDetails(this.project_name, this.props.authenticated, false, this.props.project)
  }

  isManager() {
    let m = false
    this.props.members.map((member => {
      if(member.role === 3 && member.user == this.props.user && member.verified) m = true;
    }))
    return m;
  }

  isReporter() {
    let m = false
    this.props.members.map((member => {
      if(member.role >= 2 && member.user == this.props.user && member.verified) m = true;
    }))
    return m;
  }

  componentWillUnmount() {
    this.props.resetProjectDetailsLoad()
  }



  render() {
    const { loading, loaded, imagesLoaded, areasLoaded, subscribersLoaded, authenticated, membersLoaded, newsLoaded, selected, eventsLoaded } = this.props;
    const { about, images, news, areas, members, subscribers, events, teams } = this.props;
    if (false) {
      return "";
    } else {
      let projectNews = defaultNews;
      if(newsLoaded && news !== undefined){
        
        if(news.length > 0) projectNews = news
      }
      return (
        <div className={style.ProjectDetail}>
          <HtmlMeta about={about} />
          <div className={style.DesktopViewOnly}>
            {about ? (
              <>
              <Header data={data[0]} titleSlug={this.project_name} isManager={this.isManager()} isReporter={this.isReporter()}/>
              <ProjectGoal id={"Übersicht"} data={about} has_sub={this.props.subState(selected)} authenticated={authenticated} />
              </>
            ):(
              <>
              <LoadingHeader />
              <LoadingGoal />
              </>
            )}
          </div>
          <div className={style.MobileViewOnly}>
            {about && <MobProjectGoal id={"Übersicht"} data={about} has_sub={this.props.subState(selected)} isReporter={this.isReporter()} authenticated={authenticated} isManager={this.isManager()}/>}
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: "2.5em" }}
            id={"Neuigkeiten"}
          >
            <h1>Neuigkeiten</h1>
          </div>
          <div className={style.Projects} style={{marginLeft: "-1em", marginBottom: "2em"}}>
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start">
            {newsLoaded ? (<ProjectNews news={projectNews} projectName={this.project_name}/>):(
              Array.from(Array(2).keys()).map((key => (
                <LoadingCard key={key} variant="small"/>
              )))
            )}
            </div>
          </div>
          {events.length || !eventsLoaded ? ( 
            <div
              className="d-flex align-items-center"
              style={{ marginBottom: "2.5em" }}
              id={"Neuigkeiten"}
            >
              <h1>Aktionen</h1>
            </div>
            ):("")
            }
            <div className={style.Projects} style={{marginLeft: "-1em"}}>
            <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start">
                {!eventsLoaded && Array.from(Array(2).keys()).map((key => (
                  <LoadingCard key={key} variant="small"/>
                ))) }
                {eventsLoaded && about ? (
                  <ProjectEvents events={events} project={about.id}/>
                ):(
                  ""
                )}
                </div>
            </div>
          <div
            className="d-flex align-items-center mt-5"
            style={{ marginBottom: "2.5em" }}
            id={"Unterstützer*innen"}
          >
            <h1>Unterstützer*innen</h1>
            {authenticated ? (
              <Link to={"/projects/"+this.project_name+"/join"}>Ich will auch mitmachen</Link>
            ) : (
              <Link to={`/login?redirectTo=${window.location.pathname}`}>Melde dich an um ein Projekt Mitglied zu werden</Link>
            )}
          </div>
          <div
            style={{
              marginLeft: "-1em",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
          {membersLoaded ?(
            <ProjectMembers members={members} user={this.props.user}/>
            ):(
              Array.from(Array(10).keys()).map((key => (
                <SmallCardLoading key={key}/>
              )))
            )}
            </div>
            {teams && Boolean(teams.length) && 
            <>
            <div
            className="d-flex align-items-center mt-5"
            style={{ marginBottom: "2.5em" }}
            id={"Unterstützer*innen"}
          >
            <h1>Teams</h1>
            <a>Diese Teams unterstützen das Projekt</a>
          </div>
          <div
            style={{
              marginLeft: "-1em",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {teams.map(team=>(
              <Link to={'/teams/'+team.slug}>
                <TeamCard team={team} />
              </Link>
           ))}
            </div>
            </>
            }
              
            <div>

              <div
                className="d-flex align-items-center pt-5"
                style={{ marginBottom: "2.5em" }}
                id={"Flächen"}
              >
                <h1>Flächen</h1>
                <Link to={"/report_area/"}>Melde eine Fläche</Link>
              </div>
              <div className={style.Projects} style={{marginLeft: "-1em"}}>
              <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start">
                {!areasLoaded && Array.from(Array(2).keys()).map((key => (
                  <LoadingCard key={key} variant="small"/>
                ))) }
                {areasLoaded && areas !== undefined ? (
                  <ProjectAreas areas={areas} />
                ):(
                  areasLoaded && <p className="ml-3">Es wurden noch keine Flächen in diesem Projekt hinterlegt. <Link to="/report_area">Du möchtest eine Fläche zur Verfügung stellen?</Link></p>
                )}
                </div>
              </div>
            </div>

          <div
            className="d-flex align-items-center pt-5"
            style={{ marginBottom: "2.5em" }}
            id={"Informationen"}
          >
            <h1>Informationen</h1>
          </div>
          <Information data={{about, images}} imagesLoaded={imagesLoaded} />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    authenticated: state.auth.token !== null,
    loading: state.projects.detailsIsLoading,
    loaded: state.projects.detailsLoaded,
    imagesLoaded: state.projects.imagesLoaded,
    areasLoaded: state.projects.areasLoaded,
    subscribersLoaded: state.projects.subscribersLoaded,
    membersLoaded: state.projects.membersLoaded,
    newsLoaded: state.projects.newsLoaded,
    user: state.profile.profile_id,
    subState: (x) => !!state.projects.subscribed[id],
    p_map: state.projects.mapping,
    news: state.projects.news[id],
    images: state.projects.images[id],
    members: state.projects.members[id] || [],
    about: state.projects.about[id],
    subscribers: state.projects.subscribers[id],
    areas: state.projects.areas[id],
    project: id,
    events: state.events.list,
    eventsLoaded: state.events.loaded,
    teams: state.projects.teams[ownProps.match.params.projectName] || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectDetails: (projectId, auth, fetched, id_) =>
      dispatch(fetchProjectDetails(projectId, auth, fetched, id_)),
    resetProjectDetailsLoad: (fetched) => dispatch(resetProjectsLoad(fetched)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);

const HtmlMeta = (about) => {
  if(about.about) {
    return (
      <Helmet>
        <title>{about.about.name} - TreePlantingProjects</title>
        <meta name="description" content={about.about.short_description} />
        <meta property="og:title" content={about.about.name}/>
        <meta property="og:image" content={about.about.image} />
      </Helmet>
    )
  }
  else return ""
}
