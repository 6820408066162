import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export default function EventInformation({data}) {
  const {information, gallery_images} = data || {information: "", gallery_images: []};
  return (
    <div className="row">
      <div className={gallery_images.length ? ("col-md-8 mb-3 mb-md-0") : ("col-md-12 mb-3 mb-md-0")}>
        <div
          className="bg-white h-100"
          style={{ padding: "1.5em", borderRadius: "2em" }}
        >
          <p
          >
            {information ? (
              information.split('\n').map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                )
              })):(
                <>
                <p><Skeleton height={10} width="80%"/></p>
                <p><Skeleton height={10} width="70%"/></p>
                <p><Skeleton height={10} width="60%"/></p>
                </>
              )
            }
          </p>
        </div>
      </div>
      {gallery_images.length ? (
        <div className="col-md-4 ">
          <div
            className="row bg-white"
            style={{ padding: "1.5em 0", borderRadius: "2em" }}
          >
            <div className="col-12">
              <div>
                <img
                  src={gallery_images[0]}
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "2em" }}
                />
              </div>
            </div>
            {gallery_images.slice(1).splice(0,3).map((image => (
              <div className="col-md-4 mt-3">
                <img
                  src={image}
                  alt=""
                  className="img-fluid"
                  style={{ borderRadius: "2em" }}
                />
              </div>
            )))}
          </div>
        </div>
        ):(
        ""
      )}

    </div>
  );
}
