import React, { useState } from "react";
import style from "../../style/sidebar.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faFileAlt, faSearch, faHome, faCube, faBars, faCog, faSignOut, faSignIn, faUsers, faUser, faArrowLeft } from "@fortawesome/pro-regular-svg-icons"; 
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import { MobileMenu } from "./DashboardLayout";

function PlanLayout(props) {
    return <div>
        {props.children}
        {props.maintenance ? (""):(
          <MobileMenu 
            authenticated={props.authenticated} 
            notifications={props.profile.notifications.length}
            layout={true}
            handleBack={() => props.history.goBack()}
            />
        )}
        </div>
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
    authenticated: state.auth.token !== null,
    maintenance: state.version.maintenance
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    null
  )(PlanLayout));
