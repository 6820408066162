import React from "react";
import style from "../style/createArea.module.css";
import { Link, Redirect } from "react-router-dom";
import { resetPassword } from "../store/auth";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faUndo, faUser } from "@fortawesome/pro-regular-svg-icons";


class ResetPassword extends React.Component {
  state = {
    email: "",
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.resetPassword(this.state.email);
  };

  render() {
    const { error, loading, token, started } = this.props;
    const { email } = this.state;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Passwort zurücksetzen - TreePlantingProjects" />
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className={"col-md-6"}>
            <img className={"img-fluid"} src="/images/reset_password_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <p onClick={this.props.history.goBack} className={style.backLink}>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
            <h2 className={style.mainHeading}>Passwort vergessen?</h2>
            <div className={"row"}>
              <div className={"col-md-12"}>
                {started ? (
                  <div>
                    <p>Wir haben dir an {email} eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts geschickt.</p>
                    <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>
                      <button type="submit" className={style.formBtn}>
                        <FontAwesomeIcon icon={faUndo} className="fa-fw" />
                        Nochmal schicken
                        </button>
                    </form>
                  </div>
                ) : (
                  <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>
                    <div className={style.formInput}>
                      <input
                        className={style.commonInput}
                        type="email"
                        onChange={this.handleChange}
                        value={email}
                        required
                        name="email"
                        placeholder="E-Mail-Adresse"
                      />
                      <FontAwesomeIcon icon={faUser} className={style.inputIcon} />
                    </div>
                    <div className="text-left mt-2 row">
                      <div className={"col-md-8"}>
                        <button type="submit" className={style.formBtn}>
                          <FontAwesomeIcon icon={faUndo} className="fa-fw" />
                        Passwort zurücksetzen</button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token !== null,
    started: state.auth.resetPasswordStarted
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (email) => dispatch(resetPassword(email)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword));
