import roles from "../../data/projectRoles.json";
export default function ProjectRoleDropdown({role}) {
    let role_name = ""
    roles.map((r => {
        if(r.id == role.role) role_name = r.name
    }))
    return (
        <div>
          {role_name}
      </div>
    )
}