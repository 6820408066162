import React from "react";
import style from "../../style/goal.module.css";
import { Progress } from "antd";
import { toast } from 'react-toastify';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { subscribeProject, unsubscribeProject } from "../../store/projects";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBellOn, faBellSlash, faMapMarkerAlt, faSpinner, faTreeAlt, faTreeLarge, faTrees, faUserPlus } from "@fortawesome/pro-regular-svg-icons";


class ProjectGoal extends React.Component {
  constructor(props) {
    super(props);
  };

  handleWhatsapp = () => {
    window.open('whatsapp://send?text=Kennst du schon ' + this.props.data.about.name + '? \n\nSchau mal hier: ' + window.location.href, "_blank");
  }

  handleFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, '_blank')
  }

  render() {
    const { id, image, description, name, progress, location_name, trees, project_meta_profiles, title_slug } = this.props.data;
    const { authenticated, has_sub, subLoading, unsubLoading } = this.props;
    const meta_images = project_meta_profiles.images;
    const metaMemberCount = project_meta_profiles.count-project_meta_profiles.images.length
    return (
      <div className={style.goal}>
        <div className={`d-block  d-md-flex justify-content-between`} style={{ position: "relative" }}>
          <img src={image} alt="" className={style.coverBg} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div>
            <div className={style.contentSide}>
              <div className={style.contentHeader}>
                <div>
                  <p className={style.grayBorder}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-fw" /> {location_name}
                  </p>
                  {trees.ttp ? (
                    <p className={style.grayBorder}>
                      <FontAwesomeIcon icon={faTreeAlt} className="fa-fw"/> {trees.sum}/{trees.ttp} 
                    </p>
                  ):("")}
                  {meta_images ? (
                    <p className={style.grayBorder}>
                    {meta_images.map((image => (
                      <img
                      className={style.smImage}
                      src={image}
                      alt=""
                      key={image}
                    />
                    )))}
                    {metaMemberCount ? (
                      <span className="ml-1">+ {metaMemberCount}</span>
                    ):("")}
                  </p>
                  ):("")}
                  
                </div>
              </div>
              <h1 className={style.mainHeading}>
                {name}
              </h1>
              <div className={style.mainText}>
                {
                  description.split('\r\n').map(function (item, idx) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    )
                  })
                }
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "2em" }}
              >
                {authenticated ? (
                  subLoading || unsubLoading ? (
                    <button disabled className={`${style.grayBtn} mt-0`}>
                      <FontAwesomeIcon icon={faSpinner} className="fa-fw" />
                    </button> 
                  ) : (
                  has_sub ? (
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
                      <button 
                        className={`${style.grayBtn} mt-0`} 
                        onClick={() => this.props.handleUnsubscribe(id)}
                        disabled={this.props.unsubLoading}
                        >
                        <FontAwesomeIcon icon={faBellSlash} className="fa-fw" />
                        Deabonnieren
                      </button>
                    </motion.div>
                  ) : (
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
                      <button 
                        className={`${style.greenBtn} mt-0`} 
                        onClick={() => this.props.handleSubscribe(id)}
                        disabled={this.props.subLoading}
                      >
                        <FontAwesomeIcon icon={faBellOn} className="fa-fw" />
                        Abonnieren
                      </button>
                    </motion.div>
                  ))
                ) : (
                  <div>
                    <Link to={`/login?redirectTo=${window.location.pathname}`} className={`${style.greenBtn} mt-0`}>
                    <FontAwesomeIcon icon={faBellOn} className="fa-fw" />
                    Abonnieren
                    </Link>
                  </div>
                )
                }
                <button className={`${style.greenBtn} ml-2 mt-0`} onClick={() => this.props.history.push('/projects/'+title_slug+'/join')}>
                        <FontAwesomeIcon icon={faUserPlus} className="fa-fw" />
                        Projekt beitreten
                      </button>
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center mr-3">

                  </div>
                  <div className="d-flex align-items-center">
                    <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                      <CopyToClipboard text={window.location.href}>
                        <button className={style.externalLink} onClick={()=>{toast.success('👍 Link in die Zwischenablage kopiert.')}}>
                          <img
                            src="/images/link.svg"
                            alt=""
                            className="img-fluid"
                          />
                        </button>
                      </CopyToClipboard>
                    </motion.div>
                    <button className={style.externalLink} onClick={this.handleWhatsapp}>
                      <img
                        src="/images/whatsapp-brands.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <button className={style.externalLink} onClick={this.handleFacebook}>
                      <img
                        src="/images/facebook-f-brands.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </div>

                </div>
                {/*<Progress*/}
                {/*  percent={progress}*/}
                {/*  style={{*/}
                {/*    width: "50%",*/}
                {/*    marginLeft: "1.5em",*/}
                {/*    display: "flex",*/}
                {/*    alignItems: "center",*/}
                {/*    showInfo: false*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
            </div>
          </div>
          <div className={style.coverImage}>
            <img
              className=""
              src={image}
              alt=""
              style={{ width: "50em", height: "40em", objectFit: "cover" }}
            />
          </div>

        </div >
      </div >
    );
  }
}


const mapStateToProps = (state) => {
  return {
    subLoading: state.projects.subLoading,
    unsubLoading: state.projects.unsubLoading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getSubState: (projectId) => dispatch(fetchProjectSub(projectId)),
    handleSubscribe: (projectId) => dispatch(subscribeProject(projectId)),
    handleUnsubscribe: (projectId) => dispatch(unsubscribeProject(projectId))

  };
};

export default withRouter(
  connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectGoal));
