import React from "react";
import style from "../../style/projectCard.module.css";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCube, faMapMarkedAlt, faMapMarkerAlt, faTreeAlt } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

export default function AreaCard({ data, show_buttons }) {
  const {
    id,
    uuid,
    image,
    location_name,
    name,
    size,
    progress,
    project,
    published,
    trees,
    status
  } = data;
  return (
    <div className={style.ProjectCard}>
      <img src={image} alt="" />
      <div className={style.cardContent}>
        {location_name ? (
          <div className="d-flex align-items-center">
            {published ? (
              <div
                style={{
                  backgroundColor: "rgba(93,230,143, 0.1)",
                  color: "#5DE68F",
                }}
              >
                <span>bestätigt</span>
              </div>
            ) : (
              <>
              {published === false ? (
                status === 'DECLINED'?(
                  <div
                style={{
                  backgroundColor: "rgba(50,58,53, 0.1)",
                  color: "#323a35",
                }}
              >
                <span>abgelehnt</span>
              </div>
                ):(
                <div
                style={{
                  backgroundColor: "rgba(50,58,53, 0.1)",
                  color: "#323a35",
                }}
              >
                <span>ausstehend</span>
              </div>
              )
              ):("")}
              </>
            )}
            {show_buttons && 
              <div className={style.cardActionButton}>
                <a href={`https://area.tpp.app/area/${id}`} target="_blank"><FontAwesomeIcon icon={faTreeAlt} className="fa-fw"/><FontAwesomeIcon icon={faCog} /></a>
              </div>
            }
          </div>
        ) : ("")}
        
        <h1>{name}</h1>
        
        <p className={style.address}>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-fw" /> {location_name}
        </p>
                
        {trees.ttp ? (
          <div className={`row ${style.cardFooter}`}>
            <p className={style.project}>
              <FontAwesomeIcon icon={faCube} className="fa-fw" /> {size} Hektar
            </p>
            <p className={style.project}>
              <FontAwesomeIcon icon={faTreeAlt} className="fa-fw" /> {trees.sum}/{trees.ttp}
            </p>
          </div>
        ):
        (
          <p className={style.project}>
            <FontAwesomeIcon icon={faCube} className="fa-fw" /> {size} Hektar
          </p>
        )}
          
        {progress ? <Progress percent={progress} showInfo={false} /> : ""}
      </div>
    </div>
  );
}
