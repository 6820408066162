import React from "react";
import style from "../style/projectCard.module.css";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUserAlien, faUserEdit } from "@fortawesome/pro-regular-svg-icons";

export default function BlogCard({ post }) {
  const {
    image,
    title,
    date,
    description,
    author,
  } = post;
  let lDate = new Date(date).toLocaleDateString('de-DE', {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
  })
  let customCss = Boolean(['/blog', '/'].indexOf(window.location.pathname)+1)
  let img = image
  if (image !== null && image.includes('wp-content')) img = 'https:/' + image.split('%3A')[1];
  const slug = description.substring(0, 90) + '...';
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div className={style.BlogCard}>
      <img src={img} alt="" />
      <div className={style.cardContent}>
        <h1>{renderHTML(title)}</h1>
        {description ? (
          <span>{renderHTML(slug)}</span>
        ) : ("")}
        <div className={`row ${style.cardFooter}`}>
        {date ? (
          <p className={style.project}>
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/> {lDate}
          </p>
        ) : (
          ""
        )}
        <p className={style.project}>
            <FontAwesomeIcon icon={faUserEdit} className="fa-fw" />von {author}
          </p>
        </div>
      </div>
    </div>
  );
}
