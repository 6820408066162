import React from "react";
import style from "../style/goal.module.css";
import { Progress } from "antd";
import {Link} from "react-router-dom";

export default function HomeGoal({authenticated}) {
  return (
    <div className={style.goal}>
      <div className={`d-block  d-md-flex justify-content-between`} style={{position: "relative"}}>
        <img src="/images/HomeInfo.jpg" alt="" className={style.coverBg} />
        <div className={style.contentSide}>
          <div className={style.contentHeader}>

          </div>
          <h1 className={style.mainHeading}>
            Willkommen bei TreePlantingProjects!
          </h1>
          <div className={style.mainText}>
            Schön, dass Du zu uns gefunden hast. <br />
            Aktuell befindet sich die Plattform noch im Aufbau, 
            daher können manche Funktionen noch fehleranfällig sein.
            <br />
            Du kannst dich aber bereits anmelden, Dein Profil 
            ausfüllen, nach Projekten suchen und diese abonnieren 
            oder Dich als Helfer*in, Reporter*in oder Organisator*in 
            eintragen. Außerdem kannst Du uns Flächen zur Verfügung 
            stellen, die wir dann den Projektteams zur Bepflanzung anbieten. 
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "2em" }}
          >
            {authenticated ? (
                ""
            ):(
              <Link className={`${style.greenBtn} mt-0`} to={`/login?redirectTo=${window.location.pathname}`}>
                Jetzt bei TPP anmelden
              </Link>
            )
            }

          </div>
        </div>
        <div className={style.coverImage}>
          <img
            className={""}
            src="/images/HomeInfo.jpg"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}
