import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

export default function EventLocationDescription({data}) {
  const { route_link, route_description, link } = data;
  return (
    <>
    <div className="row">
      <div className={"col-md-10 mb-3 mb-md-0"}>
        <div
          className="bg-white h-100"
          style={{ padding: "1.5em", borderRadius: "2em" }}
        >
          <p
          >
            {route_description ? (
              route_description.split('\n').map(function (item, idx) {
                return (
                  <span key={idx}>
                    {item}
                    <br />
                  </span>
                )
              })):(
                <>
                <p><Skeleton height={10} width="80%"/></p>
                <p><Skeleton height={10} width="70%"/></p>
                <p><Skeleton height={10} width="60%"/></p>
                </>
              )
            }
          </p>
        </div>
      </div>
      <div className="col-md-2 mb-3 mb-md-0 mt-2">
        <a href={route_link} target={'blank'}>
          <img src="/images/gmapsIcon.png" style={{height:'60px'}}/>
        </a>
        <a href={link} target={'blank'} className='ml-2'>
          <img src="/images/bayernAtlasIcon.jpeg" style={{height:'60px'}}/>
        </a>
      </div>
    </div>

    </>
  );
}
