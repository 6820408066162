import React from "react";
import style from "../../style/createArea.module.css";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCalendarAlt, faChartArea, faClock, faCube, faDownload, faHouse, faImage, faLink, faPencilRuler, faPlayCircle, faPlus, faSave, faSquare, faTrash, faUpload, faUser, faUsers } from "@fortawesome/pro-regular-svg-icons"; 
import EventCard from "../EventCard";
import { getEventIndexFromList, getProjectIdBySlug } from "../../store/utility";
import { fetchProjectEvents, createEvent, deleteEvent, fetchEventManage, updateEvent } from "../../store/events";
import { getEventsByProjectId } from "../../store/utility";
import ProfileToggle from "../profile/ProfileToggle";
import listStyle from "../../style/Admin.module.css";
import AreaSmallCard from "../AreaSmallCard";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";


class EventsManage extends React.Component {

  constructor(props) {
    super(props);
    this.inputReference = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    let eventSelected = this.props.location.search.split("?");
    if (eventSelected.length > 1) eventSelected = eventSelected[1].replace("selected=", "");
    else eventSelected = false;
    this.state = {
        title: "",
        image: "",
        description: "",
        information: "",
        beta_accept: false,
        update: false,
        eventId: false,
        locationType: false,
        locationValue: false,
        selected: eventSelected,
        selectedId: eventSelected
    }

    this.project_name = this.props.match.params.projectName;
    this.columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        width: "24em",
        cell: row => <div className={style.profileImage}><img src={row.image} alt="profile" /><span>{row.name}</span></div>
      },
      {
        name: 'E-Mail',
        selector: 'email',
        sortable: true,
        width: "25em",
        cell: row => row.email
      },
      {
        name: 'Weitere Anmeldungen',
        selector: 'additional_participants',
        sortable: true,
        cell: row => row.additional_participants
      }
    ]

    
  }


  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    this.setState({ image: e.target.files[0] });
  }

  handleChange = e => {
    let k = e.target.value
    if(e.target.checked) k = e.target.checked
    this.setState({ [e.target.name]: k });
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.update === 0 || this.state.update) {
      this.props.updateEvent(this.props.project, this.project_name, this.state)
    }
    else {
      this.props.createEvent(this.props.project, this.project_name, this.state)
    }
    this.handleClearSelect();
  };

  componentDidMount() {
    this.props.fetchProjectEvents(this.project_name, this.props.authenticated, false);
    
  }

  handleSelect = event_id => {
    const id = getEventIndexFromList(this.props.events, event_id);
    let status = false;
    if(this.props.events[id].status >= 2) status = true;
    this.setState({
      update: id,
      title: this.props.events[id].title,
      date: this.props.events[id].date.split("T")[0],
      time: this.props.events[id].date.split("T")[1].slice(0,5),
      image: true,
      description: this.props.events[id].description,
      information: this.props.events[id].information,
      eventId: this.props.events[id].id,
      status,
      max_participants: this.props.events[id].max_participants,
    });
    this.props.fetchEventManage(this.props.events[id].id, this.props.events[id].project, this.project_name);
    
  }

  acceptBeta = e => {
    e.preventDefault();
    this.setState({beta_accept: true})
  }

  handleClearSelect = () => {
    this.setState({
      update: false,
      title: "",
      image: "",
      description: "",
      information: "",
      status: 0,
      update: false,
      status: false,
      max_participants: 0,
      time: "",
      date: "",
      fileUploadState: "",
      eventId: false,
      locationType: false,
      locationValue: false,
      selected: null
    })
    window.scrollTo(0, 0)
  }
  
  handleDelete = () => {
    this.props.deleteEvent(this.props.project, this.project_name, this.state.eventId);
    this.handleClearSelect();
  }

  disabledButton = () => {
    if(this.state.title && this.state.image && this.state.information && this.state.date) return true;
    return false
  }
  
  disableButtonUpdate = () => {
    if(this.props.manageLoading) return false;
    if(this.state.title && this.state.image && this.state.information 
      && !this.props.manageLoading
      && this.state.date && this.state.time && this.getEventLocationType() 
      && this.getEventLocationValue() 
      && this.state.max_participants
      ) return true;
    return false

  }

  handlePublic = () => {
    this.setState({
      status: !this.state.status
    })
  }

  setLocationType = (type) => {
    this.setState({locationType: type, newLocation: true, locationValue: false})
  }

  handleAreaSelect = (area) => {
    this.setState({
      locationValue: area,
      locationType: 1
    })
  }

  getEventLocationType = () => {
    let locationType = this.state.locationType;
    if(this.state.eventId && !this.state.locationType) {
      locationType = this.props.manage[this.state.eventId].current_location.type;
      if(!this.props.manage[this.state.eventId].current_location.type) return 1
    }
    else locationType = this.state.locationType;
    return locationType
  }

  getEventLocationValue = () => {
    let locationValue = this.state.locationValue;
    if(this.state.eventId && !this.state.locationValue && this.props.manage[this.state.eventId].current_location.type == this.getEventLocationType() ) {
      locationValue = this.props.manage[this.state.eventId].current_location.location;
    }
    else if (!this.state.locationValue) locationValue = ""
    return locationValue
  }

  handleLocationChange = e => {
    let locationType = 2;
    if(e.target.name == "address") locationType = 3;
    this.setState({ 
      locationValue: e.target.value,
      locationType: locationType
     });
  }

  handleQuerySelectEvent = () => {
    const selected = this.state.selected;
    if(typeof this.state.selected == "string"){
      this.setState({selected: true})
      this.handleSelect(selected)
      return []
    }
    if(this.state.selected === true) {
      if(getEventIndexFromList(this.props.events, this.state.selectedId) >= 0) 
        return [this.props.events[getEventIndexFromList(this.props.events, this.state.selectedId)]]
      else 
        return[]

    }
    return this.props.events;
  }

  handleShowAll = () => {
    this.setState({selected: false})
  };

  


  render() {
    const {authenticated, loaded, events, about, profile, manage, manageLoading} = this.props;

    if (!authenticated) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    if(!loaded) {
      return ""
    }
    if(!profile.is_staff) {
      return (
        <div className={style.form}>
          <ReactTitle title={about.name+" STAFF ONLY - TreePlantingProjects"} />
          <div className={`row d-flex vh-100`}>
            <div className="col-md-6 py-4 pr-5 pl-5">
            <p onClick={()=>this.props.history.goBack()} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={`${style.mainHeading} mt-0`}>Dieser Bereich wurde für dich noch nicht freigeschalten. Bitte kontaktiere deinen Ansprechpartner.</h2>
            </div>
          </div>
        </div>
      )
    }
    return (
        <div className={style.form}>
          <ReactTitle title={"Aktionen verwalten von "+about.name+"- TreePlantingProjects"} />
          <div className={`row d-flex vh-100`}>
            <div className="col-md-12 py-4 pr-5 pl-5">
            <p onClick={()=>this.props.history.push(`/projects/${about.title_slug}`)} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <div className="row">
              <div className="col-md-3">
                <h2 className={`${style.mainHeading} mt-0`}>{this.state.eventId ? ("Aktion bearbeiten"):("Neue Aktion erstellen")}</h2>
                <div className={style.mainText}>Erstelle neue Aktionen oder wähle existierende aus der Liste.</div>
                
                {this.state.eventId && <button 
                  style={{marginBottom: "1em"}}
                  className={style.formBtn}
                  disabled={false}
                  onClick={this.handleClearSelect}
                >
                  <FontAwesomeIcon icon={faPlus} className="fa-fw" />
                  Neue Aktion
                </button>}
              </div>
                <div className={`col-md-9 ${style.manageHeading} d-flex flex-nowrap`}>
                {this.handleQuerySelectEvent().map((event, key)=> (
                <div onClick={()=>(this.handleSelect(event.id))} style={{marginLeft: "-1em", marginRight: "1em", cursor: "pointer"}}>
                  <EventCard
                    key={event.id}
                    selected={this.state.update === key}
                    event={event}
                    noLink={true}
                  />
                </div>
              ))}
                </div>
                {events.length >= 2 ? (<p className={style.swipeLeft} onClick={this.handleShowAll}>Weitere</p>):("")}
                </div>
                <form className="mt-3" action="" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-5">
                      <div className="row">
                        <div className="col-md-12">
                          <div className={style.formInput}>
                            <input
                              className={style.commonInput}
                              type="text"
                              fluid="left"
                              name="title"
                              placeholder="z.B. Hilf uns bei der Vorbereitung...."
                              required
                              onChange={this.handleChange}
                              value={this.state.title}
                            />
                            <FontAwesomeIcon icon={faPencilRuler} className={style.inputIcon} />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className={style.formInput}>
                                <input
                                  className={style.commonInput}
                                  type="date"
                                  fluid="left"
                                  name="date"
                                  required
                                  onChange={this.handleChange}
                                  value={this.state.date}
                                />
                                <FontAwesomeIcon icon={faCalendarAlt} className={style.inputIcon} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className={style.formInput}>
                                <input
                                  className={style.commonInput}
                                  type="time"
                                  fluid="left"
                                  name="time"
                                  onChange={this.handleChange}
                                  value={this.state.time}
                                />
                                <FontAwesomeIcon icon={faClock} className={style.inputIcon} />
                              </div>
                            </div>
                          </div>
                          <div className={style.formInput}>
                            <input
                              readOnly="readOnly"
                              className={style.commonInput}
                              type="text"
                              required
                              placeholder={this.state.fileUploadState ? (this.state.fileUploadState.replace("C:\\fakepath\\", "")) : ("Bild hochladen")}
                              onClick={this.fileUploadAction}
                            />
                            <input
                              type="file"
                              style={{ display: "none" }}
                              name="image"
                              hidden
                              accept="image/*"
                              data-type='image'
                              ref={this.inputReference}
                              onChange={this.fileUploadInputChange}
                            />
                            <FontAwesomeIcon icon={faImage} className={style.inputIcon} />
                            <p className={style.required}>{!this.state.fileUploadState && !this.state.eventId ? ("Du musst ein Bild hochladen."):("")}</p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className={style.formInput}>
                            <textarea
                              className={style.commonInput}
                              name="information"
                              cols="30"
                              rows="7"
                              required
                              onChange={this.handleChange}
                              value={this.state.information}
                            ></textarea>
                          </div>
                        </div>
                    </div>
                </div>
                  <div className="col-md-6">
                  {manageLoading && 
                  <div>
                    <p>Aktionsdaten werden geladen</p>
                  </div>
                  }
                  {!manageLoading && this.state.eventId && manage[this.state.eventId] !== undefined ? (
                    <div>
                      <div className="row">
                          <div className="col-md-3">
                            <div className={style.formInput}>
                              <input
                                className={style.commonInput}
                                type="number"
                                fluid="left"
                                name="max_participants"
                                required
                                min="1"
                                onChange={this.handleChange}
                                value={this.state.max_participants}
                              />
                              <FontAwesomeIcon icon={faUser} className={style.inputIcon} />
                              <p className={style.required}>Maximale Teilnehmer</p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <ProfileToggle checked={this.state.status} handleChange={this.handlePublic}/>
                            <p className={style.required} style={{marginTop: "0.9em"}}>Aktion ist öffentlich</p>

                        </div>
                      </div>
                      <div className={style.mainText}><strong className="mr-2">Teilnehmerliste</strong> <a href={process.env.REACT_APP_API_ENDPOINT+`/events/${this.state.selectedId}/manage/export?token=${this.props.auth}`} ref={'_blank'}>Exportieren<FontAwesomeIcon icon={faDownload}/></a></div>
                      <DataTable
                        noHeader
                        selectableRowsNoSelectAll
                        columns={this.columns}
                        data={manage[this.state.eventId].participants}
                      />

                      <div className={style.mainText}><strong>Wähle einen Ort für die Aktion</strong></div>
                      <div className={style.chooseLocationBox}>
                      <div className={`row ${listStyle.header} mt-2`}>
                        <button type="button" onClick={()=>this.setLocationType(1)} disabled={this.getEventLocationType() == 1}><FontAwesomeIcon icon={faCube} className="fa-fw"/> Projektfläche</button>
                        <button type="button" onClick={()=>this.setLocationType(2)} disabled={this.getEventLocationType() == 2}><FontAwesomeIcon icon={faLink} className="fa-fw"/> Online</button>
                        <button type="button" onClick={()=>this.setLocationType(3)} disabled={this.getEventLocationType() == 3}><FontAwesomeIcon icon={faHouse} className="fa-fw"/> Vor ort</button>
                      </div>
                      <div>
                        {this.getEventLocationType() === 1 && 
                        <div className="row">
                         {manage[this.state.eventId].areas.map((area => (
                           <div style={{cursor: "pointer"}} onClick={()=>this.handleAreaSelect(area.id)}>
                            <AreaSmallCard key={area.id} area={area} selected={this.getEventLocationValue() === area.id }/>
                           </div>
                         )))}
                        </div>
                        }
                        {this.getEventLocationType() === 2 && 
                        <div className="row">
                          <div className="col-md-12">
                            <div className={style.formInput}>
                              <input
                                className={style.commonInput}
                                type="url"
                                fluid="left"
                                name="online_link"
                                required
                                placeholder="https://meet.google.com"
                                onChange={this.handleLocationChange}
                                value={this.getEventLocationValue()}
                              />
                              <FontAwesomeIcon icon={faLink} className={style.inputIcon} />
                            </div>
                          </div>
                        </div>
                        }
                        {this.getEventLocationType() === 3 && 
                        <div className="row">
                          <div className="col-md-12">
                            <div className={style.formInput}>
                              <input
                                className={style.commonInput}
                                type="text"
                                fluid="left"
                                name="address"
                                required
                                placeholder="Andorf 32, 90599 Dietenhofen"
                                onChange={this.handleLocationChange}
                                value={this.getEventLocationValue()}
                              />
                              <FontAwesomeIcon icon={faHouse} className={style.inputIcon} />
                            </div>
                          </div>
                        </div>
                        }
                        </div>
                      </div>
                      </div>
                    ):("")
                    }
                  </div>
                </div>
                <div>
                  {this.state.update === 0 || this.state.update ? (
                    <div className="row d-flex">
                    <button type="submit" disabled={!this.disableButtonUpdate()} value="update" style={{padding: "1.3em 2em"}} className={style.formBtn}>
                    <FontAwesomeIcon icon={faUpload} className="fa-fw" />
                      Aktualisieren</button>
                    <button type="button" onClick={this.handleDelete} value="delete" style={{marginLeft: "1em", backgroundColor: "#E65D5D", padding: "1.3em 1em"}}className={style.formBtn}>
                      <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                      Löschen</button>
                    </div>
                  ):(
                    <button type="submit" disabled={!this.disabledButton()} value="create" style={{padding: "1.3em 2em"}} className={style.formBtn}>
                      <FontAwesomeIcon icon={faUpload} className="fa-fw" />
                      Veröffentlichen</button>
                  )}
                </div>
              </form>
            </div>
           </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    authenticated: state.auth.token !== null,
    loaded: state.events.loaded,
    profile: state.profile,
    project: id,
    manage: state.events.manage,
    manageLoading: state.events.manageLoading,
    about: state.projects.about[id],
    events: getEventsByProjectId(state.events.list, id),
    auth: state.auth.token
  
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectMemberDetails: (auth, project) => dispatch(fetchProjectMemberDetails(auth, project)),
    fetchProjectEvents: (project, auth) => dispatch(fetchProjectEvents(project, auth)),
    createEvent: (project, projectSlug, event) => dispatch(createEvent(project, projectSlug, event)),
    deleteEvent: (project, projectSlug, event) => dispatch(deleteEvent(project, projectSlug, event)),
    fetchEventManage: (event, project, projectSlug) => dispatch(fetchEventManage(event, project, projectSlug)),
    updateEvent: (event_id, project, projectSlug, event) => dispatch(updateEvent(event_id, project, projectSlug, event))

  };
};


export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsManage));
