import React from "react";
import style from "../style/projectCard.module.css";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faPen, faTreeAlt, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import locationTypes from "../data/locationTypes.json";

export default function TeamCard({ team }) {
  const {
    id,
    team_images,
    name,
    slug,
    members_count,
    trees_to_plant,
    trees_planted
  } = team;

  return (
      <div className={style.BlogCard}>
        <div className={style.cardLogo}>
        {team_images.image && !team_images.logo && <img src={team_images.image} alt="" className={team_images.logo ? (style.logoActive):("")}/>}
        {team_images.logo && <img src={team_images.logo} />}
        </div>
        <div className={style.cardContent}>
        <div className="d-flex align-items-center flex-wrap">
          <div className={style.metaSeparator}>
            <FontAwesomeIcon icon={faTreeAlt} className="fa-fw" /> {trees_planted}/{trees_to_plant}
          </div>
          <div className={style.metaSeparator}>
           {team_images.members.map((image => (
              <img
                className={style.smImage}
                src={image}
                alt=""
                key={image}
              />
            )))}
            {members_count >=  3 && <span className="ml-1">+ {members_count-3}</span>}
            
          </div>

          </div>
          <h1>{name}</h1>
          <p className={style.project}>
            
          </p>
          </div>  
      </div>
  );
}
