import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component {

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      var objControl=document.getElementById("toScroll");
      if(objControl) objControl.scrollTop = objControl.offsetTop;

      

      
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)