import React from "react";
import AreaCard from "../areas/AreaCard";
import { motion } from "framer-motion";
import { Progress } from "antd";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { makeStyles } from '@material-ui/core/styles';


export default function ProjectAreas({areas}) {
  const [ showAllAreas, setShowAllAreas ] = React.useState(false)

  let list = areas
  if(list.length > 2 && !showAllAreas) list = [areas[0], areas[1]]
  return (
    <>
        {list.map((area => (
          <a key={area.id} href={area.location_link} target={"_blank"}>
            <AreaCard key={area.id} data={area} />
            </a>
            )))}
            {
              areas.length > 2 && 
              <p style={{marginLeft: "1em", cursor: "pointer", fontSize: showAllAreas ? ("2em"):("3em")}} onClick={()=>setShowAllAreas(!showAllAreas)}>{showAllAreas ? 
                ("Weniger Flächen anzeigen"):("+ "+(areas.length-2))}
              </p>
              
            }
            </>
  );
}
