import React from "react";
import style from "../style/projectCard.module.css";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCheck, faMapSigns, faPen, faStar, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";
import locationTypes from "../data/locationTypes.json";

export default function EventCard({ event, projectId }) {
  const {
    id,
    header_image_url,
    title,
    date,
    description,
    max_participants,
    current_participants,
    participant_images,
    location_type,
    status,
    dis,
    project,
    participation
  } = event;
  const selected = false;
  let lDate = new Date(date).toLocaleString('de-DE', {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
    hour: "numeric",
    minute: "numeric" 
  })
  if(typeof(date) == 'number') {
    lDate = new Date(date*1000).toLocaleString('de-DE', {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
      hour: "numeric",
      minute: "numeric" 
    })
  }

  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  const short_description =renderHTML(description.substring(0, 75) + ' - <strong>mehr lesen</strong>');
  let style_selected = {}
  if(selected) style_selected = {boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}
  return (
      <div className={style.BlogCard} style={style_selected}>
        <img src={header_image_url} alt="" />
        <div className={style.cardContent}>
        <div className="d-flex align-items-center flex-wrap">
        {project !== projectId && <div><FontAwesomeIcon icon={faStar}/> </div>}
        {location_type && <div>{locationTypes[location_type-1].name}</div>}
        {participant_images.length ? (
          <div className={style.metaSeparator}>
           {participant_images.map((image => (
              <img
                className={style.smImage}
                src={image}
                alt=""
                key={image}
              />
            )))}
            {current_participants >=  3 && <span className="ml-1">+ {current_participants-3}</span>}
          </div>
          ):("")}
          {status > 2 &&  <div>Beendet!</div>}
          </div>
          <h1>{renderHTML(title)}</h1>
          {description ? (
            <p>{short_description}</p>
          ) : ("")}
          <div className={`row ${style.cardFooter}`}>
          {date ? (
            <p className={style.project}>
              <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/> {lDate}
            </p>
          ) : (
            ""
          )}
          <p className={style.project}>
              <FontAwesomeIcon icon={faUsers} className="fa-fw" />{current_participants}/{max_participants}
            </p>
          </div>
          {status === 1 && 
            <p className={style.project}>
              <FontAwesomeIcon icon={faPen} className="fa-fw" /> <strong>Noch nicht veröffentlicht</strong>
           </p>
          }
          <div className="d-flex">
          {dis != undefined ? (
            <p className={`${style.project} mr-1`}>
              <FontAwesomeIcon icon={faMapSigns} className="fa-fw"/> {dis} km entfernt
          </p> 
          ):("")}
          {participation ? (
           <div className={style.projectSuggest}>
              <p><FontAwesomeIcon icon={faCheck} />Du bist dabei </p>
            </div>
          ):("")}
          </div>
        </div>
      </div>
  );
}
