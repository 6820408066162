import React from "react";
import style from "../style/projectDeatil.module.css";
import Header from "../components/events/Header";
import data from "../data/projectDetail.json";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import {
  fetchProjectMemberDetails,
} from "../store/projects";
import ProjectNews from "../components/projects/ProjectNews";
import { getAreasIndexById, getEventFromList, getProjectIdBySlug } from "../store/utility";
import { fetchEventLocation, fetchProjectEvents } from "../store/events";
import EventGoal from "../components/events/EventGoal";
import MobEventGoal from "../components/events/MobEventGoal";
import EventInformation from "../components/events/EventInformation";
import LocationCard from "../components/events/LocationCard";
import { Redirect } from "react-router";
import { fetchArea, fetchTreeSpecies } from "../store/areas";
import AreaCard from "../components/areas/AreaCard";
//import { AutoComplete } from "antd";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import IconButton from '@material-ui/core/IconButton';

class AreaManage extends React.Component {
  constructor(props) {
    super(props);
    this.areaId = this.props.match.params.areaId;
    this.speciesSelect = React.createRef();
    this.state= {
      newAreaSpeciesId: null,
      newAreaSpeciesTreeCount: null,
      species: []
    }
  }

  componentDidMount() {
    if(!this.props.areas[getAreasIndexById(this.props.areas, this.areaId)]) {
      this.props.fetchArea(this.areaId)
    }
    // if(!this.props.treeSpecies) this.props.fetchTreeSpecies();
    this.props.fetchTreeSpecies();

  }

  handleSelectSpecies = (e,value) => {
    if(value) this.setState({newAreaSpeciesId: value.id})
    else this.setState({newAreaSpeciesId: null})
  }

  handleSetTreeCount = (e, value) => {
    this.setState({newAreaSpeciesTreeCount: e.target.value})
  }

  handleAdd = () => {
  }

  render() {
    if (!this.props.authenticated) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    const area = this.props.areas[getAreasIndexById(this.props.areas, this.areaId)]
    if (!area) {
      return "loading data";
    } else {
      return (
        <div className={style.ProjectDetail}>
          <Helmet>
            <title>Flächenverwaltung - TreePlantingProjects</title>
            <meta name="description" content={""} />
            {/* <meta property="og:title" content={"Schau dir "+ event.title+ " auf TreePlantingProjects.com an"}/>
            <meta property="og:image" content={event.header_image_url} /> */}
          </Helmet>
          <div
            className="d-flex align-items-center pt-5"
            style={{ marginBottom: "2.5em" }}
          >
            <h1>Flächenverwaltung</h1>
            
          </div>
          <div className={style.breadCrumbs}>
            <h6>Flächen / {area.name}</h6>
          </div>
          <div className={style.Projects} style={{marginLeft: "-1.3em"}}>
            <AreaCard data={area} />
          </div>


          <div
            className="d-flex align-items-center mt-5"
            style={{ marginBottom: "2.5em" }}
            id={"Unterstützer*innen"}
          >
            <h1>Baumarten und Zukfuntsbäume</h1>
          </div>
          <div className="row ml-2 mb-2">
          <Autocomplete
            onChange={(e, value)=>this.handleSelectSpecies(e, value)}
            options={this.props.treeSpecies}
            disabled
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id }
            style={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Art wählen"  variant="filled"/>}
          />
          <TextField 
            variant="filled" 
            disabled
            label="Anzahl Bäume" 
            onChange={(e, value)=>this.handleSetTreeCount(e, value)}
            type="number"
            />
          <div className="ml-1">
          <Button color="primary" variant="contained" onClick={this.handleChange}>Ändern</Button>
          <Button color="secondary" variant="contained" className="ml-1">Löschen</Button>
          </div>
          </div>
          <div className="row ml-2">
            
          <Autocomplete
            onChange={(e, value)=>this.handleSelectSpecies(e, value)}
            options={this.props.treeSpecies}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id }
            style={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Art wählen"  variant="filled"/>}
          />
          <TextField 
            variant="filled" 
            label="Anzahl Bäume" 
            onChange={(e, value)=>this.handleSetTreeCount(e, value)}
            type="number"
            />
          <div className="ml-1">
          <Button color="primary" variant="contained" onClick={this.handleAdd} disabled={!this.state.newAreaSpeciesId || !this.state.newAreaSpeciesTreeCount}>Hinzufügen</Button>
          </div>
          </div>
          {/*
          {areasLoaded && areas !== undefined ? (
            <div>
              <div
                className="d-flex align-items-center pt-5"
                style={{ marginBottom: "2.5em" }}
                id={"Flächen"}
              >
                <h1>Flächen</h1>
              </div>
              <div className={style.Projects} style={{marginLeft: "-1em"}}>
                <ProjectAreas areas={areas} />
              </div>
            </div>
          ) : (
            ""
          )}

           */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authenticated: state.auth.token !== null,
    areas: state.areas.list,
    areaLoading: state.areas.areaLoading,
    treeSpecies: state.areas.treeSpecies
  };
};

const mapDispatchToProps = (dispatch) => {
  
  return {
    fetchArea: (uuid) => dispatch(fetchArea(uuid)),
    fetchTreeSpecies: () => dispatch(fetchTreeSpecies()),
    fetchAreaSpecies: (id) => dispatch()

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaManage);
