import React from "react";
import style from "../../style/projectCard.module.css";
import { Progress } from "antd";
import Skeleton from '@material-ui/lab/Skeleton';


class LoadingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state
  }

  componentDidMount() {
    // fetch project card info
  }

  render() {
    let variantStyle = {};
    let imageStyle = "11.25em";
    let textStyle = "5";
    if(this.props.variant == "small") {
      variantStyle = {width: "530px"}
      imageStyle = "7.5em",
      textStyle = "10";
    }


    return (
      <div className={style.ProjectCard} style={variantStyle}>
        <Skeleton variant="rect" width={imageStyle} height={imageStyle} style={{borderRadius:"1.5em"}} />
        <div className={style.cardContent}>
          <div className="d-flex align-items-center flex-wrap">
          </div>
          <div className="ml-2">
            <h1><Skeleton height={20} width={textStyle+"em"}/></h1>
            <p><Skeleton height={10} width="80%"/></p>
            <p className={style.project}>
            <Skeleton height={5} width="50%"/>
            </p>
          </div>
          {/* {progress ? <Progress percent={progress} showInfo={false} /> : ""} */}
        </div>
      </div>
    );
  }
}

export default LoadingCard;