import React from "react";
import style from "../../style/goal.module.css";
import { Progress } from "antd";
import { Link } from "react-router-dom";

export default function TeamsGoal({auth}) {
  return (
    <div className={style.goal}>
      <div className={`d-block  d-md-flex justify-content-between`} style={{position: "relative"}}>
        <img src="/images/teams_bg.jpg" alt="" className={style.coverBg} />
        <div className={style.contentSide}>
          <div className={style.contentHeader}>

          </div>
          <h1 className={style.mainHeading}>
            Teams
          </h1>
          <div className={style.mainText}>
          Du möchtest mit deiner Organisation, deinem Verein oder deinem Unternehmen Bäume pflanzen? 
          Lege hier ein <strong>Team</strong> für Euch an.<br /> 
          Als Team könnt ihr euch ein gemeinsames Pflanzziel setzen, euch für Projekte anmelden 
          und euren Fortschritt nachverfolgen.<br /> 
          Du bist ohne Team hier? Macht nichts! Tritt einfach einem <Link to={'/projects'}>Projekt</Link> bei, um Gleichgesinnte in deiner Region zu finden. 
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "2em" }}
          >
            <Link className={`${style.greenBtn} mt-0`} to={"/newTeam"}>
              Neues Team erstellen
            </Link>
            

          </div>
        </div>
        <div className={style.coverImage}>
          <img
            className={""}
            src="/images/teams_bg.jpg"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}
