import React from "react";
import style from "../../style/projectCard.module.css";

class ProjectSettingsProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image
    }

  }

  componentDidMount() {
    // fetch project card info
  }

  render() {
    const {
      location_name,
      name,
      progress,
      short_description
    } = this.props.data;
    const project = '';
    const projectIcon = '';
    let image = ""
    if(typeof(this.props.image) == "object") {
      image = URL.createObjectURL(this.props.image)
    }
    else image = this.props.image;

    return (
      <div className={`${style.ProjectCard} ml-0 mt-2`} style={{width: "100%"}}>
        <img src={image} alt="" />
        <div className={style.cardContent}>
          <div className="d-flex align-items-center">
            <div>{location_name}</div>
          </div>
          <h1>{this.props.settings.project_name}</h1>
          {project ? (
            <p className={style.project}>
              <img src={`/images/${projectIcon}`} alt="" /> {project}
            </p>
          ) : (
            ""
          )}
          <div className={`${style.settingsStats}`}>
            <div className="row">
             <p>Abonnenten:</p><p>{this.props.settings.subscribers}</p>
            </div>
            <div className="row">
            <p>Unterstützer*Innen:</p><p>{this.props.settings.members_count}</p>
            </div>
            <div className="row">
            <p>Geschützte Fläche:</p><p>{this.props.settings.saved_area} Hektar</p>
            </div>
          </div>
          {/* {progress ? <Progress percent={progress} showInfo={false} /> : ""} */}
        </div>
      </div>
    );
  }
}

export default ProjectSettingsProjectCard;