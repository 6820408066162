import axios from "axios";
import { toast } from 'react-toastify';
import { store } from "../../index";
import regeneratorRuntime from "regenerator-runtime";
import { authLogout } from "../auth";
import { maintenanceStart, maintenanceStop } from "../version";
import { fetchProjectsMapping } from "../projects";


const api = ({ dispatch }) => next => async action => {
     if (action.type !== "apiCall") return next(action);
    const token = store.getState().auth.token;
    const maintenance = store.getState().version.maintenance
    const headers = { 'Content-Type': 'application/json' };
    if (action.payload.auth) headers.Authorization = 'JWT ' + token
    const { url, method, data, onSuccess, onError, onLoading, target, onSuccessAction, onSuccessKeyAction } = action.payload
    if (onLoading) dispatch({ type: onLoading, target })
    try {
        const response = await axios.request({
            baseURL: process.env.REACT_APP_API_ENDPOINT,
            headers,
            url,
            data,
            method
        });
        if (!response.data.maintenance && maintenance) dispatch({ type: maintenanceStop.type })
        if (response.data.maintenance) dispatch({ type: maintenanceStart.type, payload: response.data })
        if (response.data.project_count && response.data.project_count !== store.getState().projects.mapping.length) dispatch(fetchProjectsMapping())

        if (response.data.version && store.getState().version.name !== response.data.version && store.getState().version.name !== null) {
            toast.info('🔖 Eine neue Version wurde veröffentlicht.\n Wir melden dich in 5 Sekunden ab.', {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setTimeout(function () {
                dispatch(authLogout())
                localStorage.removeItem('persist:root')
                document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
                location.reload();
            }, 5000);

        }
        dispatch({ type: onSuccess, payload: response.data, target });
        if (onSuccessAction) dispatch(onSuccessAction());

        if (onSuccessKeyAction) dispatch(onSuccessKeyAction.fn(response.data[onSuccessKeyAction["key"]], onSuccessKeyAction.arg))

    } catch (error) {
        if (error.response.status === 401) {
            if(JSON.stringify(error.response.data).includes('Signature has expired')){
                dispatch(authLogout())
            }
        }
        try {
            if (error.response.status === 502) dispatch({ type: maintenanceStart.type, payload: {r:''}})
            else {
                const baseErrorMessage = 'Ein Fehler bei der Verbindung ist aufgetreten.';
                let display_error = "";
                const response = error.response.data;
                if(error.response.statusText) {
                    toast.error('💥 '+error.response.statusText, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    if(response.non_field_errors) {display_error = response.non_field_errors[0]}
                    else {
                        for (const [key, value] of Object.entries(response)) {
                            display_error += value
                        }
                    }
                        toast.error('💥 '+display_error, {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                
                if (onError) {
                     dispatch({ type: onError, payload: error })
                };
            }
        }
        catch (e) {
            if (onError) {
                dispatch({ type: onError, payload: error })
           };
        }

    }
};

export default api;