import React from "react";
import { ListItem } from "semantic-ui-react";
import style from'../../style/longProjectCard.module.css';

class LongProjectCard extends React.Component {


  render() {
    const {
        image,
        location_name,
        name,
        progress,
        short_description,
        project_meta_profiles
    } = this.props.data;
    const meta_images = project_meta_profiles.images;
    const metaMemberCount = project_meta_profiles.count-project_meta_profiles.images.length
    return (
      <div className={style.tile_long}>
        <div className={style.tile_long_left}>
          <div className={style.tile_long_img}>
            <img
              src={image}
              alt=""
            />
          </div>
          <p className={`${style.tile_long_text} ${style.tile_small_margin_left}`}>
           {name}
          </p>
          <div className={`${style.tile_location} ${style.tile_small_margin_left}`}>{location_name}</div>
          <p className={style.short_description}>{short_description}</p>
          </div>
        <div className={style.tile_long_right}>
        <div className={`${style.tile_location}`}>
            {meta_images.map((image => (
              <img
                className={style.smImage}
                src={image}
                alt=""
                key={image}
              />
            )))}
            {metaMemberCount ? (
              <span className="ml-1">+ {metaMemberCount}</span>
            ):("")}
            </div>
          </div>
      </div>
    );
  }
}

export default LongProjectCard;