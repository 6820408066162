import React, { useState } from "react";
import style from "../style/sidebar.module.css";
import { NavLink, Link } from "react-router-dom";
import { authLogout } from "../store/auth";
import { connect } from "react-redux";
import { fetchProfile, fetchNotifications } from "../store/profile";
import "../style/sidebar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faFileAlt, faSearch, faHome, faCube, faBars, faCog, faSignOut, faSignIn, faUsers, faUser, faDigitalTachograph, faChartLine } from "@fortawesome/pro-regular-svg-icons"; 
import { isActive } from "../pages/layouts/DashboardLayout";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.authenticated && !this.props.profile.profile) {
      this.props.fetchProfile();
      this.props.fetchNotifications();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.authenticated &&
      !this.props.profile.user_id &&
      !this.props.profile.isLoading
    )
      {
        this.props.fetchProfile();
        this.props.fetchNotifications();
      }
  }

  toggleSidebar = () => {
    this.props.setSidebarVisibility(!this.props.setSidebarVisibility);
  };

  render() {
    const {
      authenticated,
      setSidebarVisibility,
      sidebarVisibility,
      version,
      profile,
    } = this.props;
    return (
      <>
        <div
          className={style.Sidebar}
          style={{
            transform: sidebarVisibility ? "translate(0)" : "",
          }}
        >
          <div>
            <Link to={"/"} onClick={this.toggleSidebar}>
              <img src={process.env.PUBLIC_URL + '/images/logo.png'} className={`${style.tpplogo} mx-auto d-block`} alt="TPP-logo" />
              <h1>TreePlantingProjects</h1>
            </Link>
            <div className={style.links}>
              <NavLink exact to="/" onClick={this.toggleSidebar}>
              <FontAwesomeIcon className="fa-fw" icon={faHome} />
               Start
               {authenticated && profile.notifications.length ? (
                <span className={style.notificationsIndicator}></span>
               ):("")}
              </NavLink>
              {authenticated && profile.is_staff &&
              <NavLink exact to="/admin" onClick={this.toggleSidebar}>
                <FontAwesomeIcon className="fa-fw" icon={faChartLine} />
                Admin
              </NavLink>
              }
              
              <NavLink exact to="/projects" onClick={this.toggleSidebar} className={isActive("projects", true)}>
                <FontAwesomeIcon className="fa-fw" icon={faCube} />
                Projekte
              </NavLink>
              <NavLink exact to="/teams" onClick={this.toggleSidebar} className={isActive("teams", true)}>
                <FontAwesomeIcon className="fa-fw" icon={faUsers} />
                Teams
            </NavLink>
              <NavLink exact to="/areas" onClick={this.toggleSidebar} className={isActive("areas", true)}>
                <FontAwesomeIcon className="fa-fw" icon={faMap} />
                Flächen
              </NavLink>
              <NavLink exact to="/blog" onClick={this.toggleSidebar}>
                <FontAwesomeIcon className="fa-fw" icon={faFileAlt} />
                Blog
              </NavLink>
            </div>
          </div>
          <>
          <a href="https://treeplantingprojects.com/umfrage" target={"_blank"} key={"navCustomLink"}>
          <div className={style.join_event_box}>
            <div>
              <p>Kennst du Vereine oder Unternehmen?</p>
            </div>
            <svg viewBox="0 0 13.393 13.393">
              <path
                id="Icon_awesome-chevron-circle-right"
                data-name="Icon awesome-chevron-circle-right"
                d="M7.259.563a6.7,6.7,0,1,1-6.7,6.7A6.7,6.7,0,0,1,7.259.563ZM10.334,6.8,6.676,3.141a.645.645,0,0,0-.915,0L5.3,3.6a.645.645,0,0,0,0,.915L8.045,7.259,5.3,10a.645.645,0,0,0,0,.915l.459.459a.645.645,0,0,0,.915,0l3.659-3.659A.649.649,0,0,0,10.334,6.8Z"
                transform="translate(-0.563 -0.563)"
                fill="#fff"
              />
            </svg>
          </div>
          </a>
          </>

          {authenticated ? (
            <>
              <div className={style.logout_profile_box}>
                <img src={profile.image_url} alt="" />
                <NavLink
                  exact
                  to="/settings"
                  onClick={this.toggleSidebar}
                  className={style.settings_button}
                >
                  <FontAwesomeIcon icon={faCog} className="fa-fw" />
                    </NavLink>
                <div
                  className={style.logout_button}
                  onClick={() => {
                    this.props.logout();
                    this.toggleSidebar();
                  }}
                >
                  <FontAwesomeIcon icon={faSignOut} className="fa-fw" /><div className="">Abmelden</div>
                </div>
              </div>
            </>
          ) : (
            <div className={style.logout_profile_box}>
              <Link to={`/login?redirectTo=${window.location.pathname}`} >
                <div
                  className={style.logout_button}
                  onClick={() => this.toggleSidebar()}
                >
                  <FontAwesomeIcon icon={faSignIn} className="fa-fw" /><div className="">Anmelden</div>
                </div>
                </Link>
            </div>
          )}
            <div className={`${style.user_profile} row`}>
              <div className="col-md-6">
              <Link to={{ pathname: "https://treeplantingprojects.com/imprint/" }} target="_blank">
                <p style={{ cursor: "pointer" }} onClick={this.toggleSidebar}>
                  Impressum
                </p>
              </Link>
              </div>
              <div className="col-md-6">
              <Link to={{ pathname: "https://treeplantingprojects.com/privacy/" }} target="_blank">
                <p style={{ cursor: "pointer" }} onClick={this.toggleSidebar}>
                  Datenschutz
                </p>
              </Link>
              
              </div>
            </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.auth.token !== null,
    profile: state.profile,
    setSidebarVisibility1: state.profile.setSidebarVisibility,
    sidebarVisibility1: state.profile.sidebarVisibility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authLogout()),
    fetchProfile: () => dispatch(fetchProfile()),
    fetchNotifications: () => dispatch(fetchNotifications())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
