export const MobGoalDescription = (props) => {
	const { show, description } = props;
	if(show){
	  return (
	    <p>
	    {
		description.split('\r\n').map(function (item, idx) {
		  return (
		    <span key={idx}>
		      {item}
		      <br />
		    </span>
		  )
		})
	      }
	  
	    </p>
	  )
	}
	const short_description = description.substring(0, 310) + '...'
	return (
	  <p>
	  {
	    short_description.split('\r\n').map(function (item, idx) {
	      return (
		<span key={idx}>
		  {item}
		  <br />
		</span>
	      )
	    })
	  }
	
	  </p>
	)
      }