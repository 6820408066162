import React from "react";
import style from "../../style/projectCard.module.css";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross, faLightbulb, faTimes, faTreeAlt } from "@fortawesome/pro-regular-svg-icons";

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state
  }

  componentDidMount() {
    // fetch project card info
  }

  render() {
    const {
      image,
      location_name,
      name,
      slug,
      progress,
      short_description,
      project_meta_profiles,
      trees
    } = this.props.data;
    const project = '';
    const projectIcon = '';
    const meta_images = project_meta_profiles.images;
    const metaMemberCount = project_meta_profiles.count-project_meta_profiles.images.length
    return (
      <div className={style.ProjectCard}>
        <img src={this.props.imageLocal ? ("http://localhost:8000"+image):(image)} alt="" />
        <div className={style.cardContent}>
          <div className="d-flex align-items-center flex-wrap">
            <div >{location_name}</div>
            {this.props.source != "manage" &&
            <div className={style.metaSeparator}>
            {meta_images.map((image => (
              <img
                className={style.smImage}
                src={this.props.imageLocal ? ("http://localhost:8000"+image):(image)}
                alt=""
                key={"meta"+image.id}
              />
            )))}
            {metaMemberCount ? (
              <span className="ml-1">+ {metaMemberCount}</span>
            ):("")}
          </div>}
          </div>
          <h1>{name}</h1>
          <p>{short_description}</p>
          {project ? (
            <p className={style.project}>
              <img src={`/images/${projectIcon}`} alt="" /> {project}
            </p>
          ) : (
            ""
          )}
          {this.props.source != "manage" && trees.tpp ? (
           <p className={style.project}>
              <FontAwesomeIcon icon={faTreeAlt} className="fa-fw" /> {trees.sum}/{trees.ttp}
            </p>):''}
          {/* {progress ? <Progress percent={progress} showInfo={false} /> : ""} */}
          {this.props.source == "manage" && this.props.status != undefined && 
            <div className={style.projectSuggest}>
            {this.props.status == 0 ?(
            <>
            <div className={style.DesktopViewOnly}>
            <p><FontAwesomeIcon icon={faLightbulb} />Vorschlag </p>
            </div>
            <div className={style.projectSuggestAction}>
              <p onClick={()=>this.props.onSuggestAccept(slug)}><FontAwesomeIcon icon={faCheck} />Annehmen</p>
              <p onClick={()=>this.props.onSuggestDecline(slug)} className="ml-1"><FontAwesomeIcon icon={faTimes} />Ablehnen</p>
            </div>
            </>
            ):(
            <div className={style.projectSuggestAction}>
              <p onClick={()=>this.props.onSuggestDecline(slug)}>
                <FontAwesomeIcon icon={faTimes} />Entfernen
              </p>
            </div> 
            )}
          </div>
          }
          {this.props.source == "teamDetail" && this.props.status == 0 &&
            <div className={style.projectSuggest}>
              <p><FontAwesomeIcon icon={faLightbulb} />Vorschlag </p>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ProjectCard;