import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Link, withRouter } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { subscribeProject, unsubscribeProject } from "../../store/projects";
import style from "../../style/MobProjectGoal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash, faBell, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faCog, faMegaphone, faSpinner, faTreeAlt } from "@fortawesome/pro-regular-svg-icons";
import {MobGoalDescription} from "../MobGoalDescription";
const bellSlash = <FontAwesomeIcon icon={faBellSlash} />;
const bell = <FontAwesomeIcon icon={faBell} />;
const userPlus = <FontAwesomeIcon icon={faUserPlus} />;


class MobProjectGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreDescription: false
    }
  }

  setShowMoreDescription = () => {
    this.setState({showMoreDescription: !this.state.showMoreDescription})
  }

  render() {
    const {
      id,
      image,
      description,
      name,
      progress,
      location_name,
      title_slug,
      trees
    } = this.props.data;
    const { authenticated, has_sub } = this.props;
    let goalStyle = {
      height: "500px"
    }
    if(this.props.isManager) goalStyle.height = "530px"
    if(this.state.showMoreDescription) goalStyle.height = "640px"
    if(this.state.showMoreDescription && this.props.isManager) goalStyle.height = "690px"
    return (
      <div className={style.project_page} style={goalStyle}>
        <div className={style.project_page_imgbox}>
          <img
            src={image}
            alt=""
          />
        </div>
        <div className={style.project_page_content}>
          <div className={style.top}>
            <div className={style.top_left}>
              <div className={style.tile_location}>{location_name}</div>
              {trees.ttp ? (
                <div className={style.tile_location} style={{marginLeft: "0.5em"}}>
                <FontAwesomeIcon icon={faTreeAlt} className="fa-fw" />
                {trees.sum}/{trees.ttp}</div>
              ):("")}
            </div>
            <div>
            </div>
          </div>
          <div className={style.middle}>
            
          </div>
          <div className={style.bottom}>
            <div className={style.project_textbox}>
              <h2 className={style.eproject_textbox_heading}>
              {name}
              </h2>
             <MobGoalDescription description={description} show={this.state.showMoreDescription} />
             <p onClick={this.setShowMoreDescription}>{this.state.showMoreDescription ? ("Weniger anzeigen"):("Mehr anzeigen")}</p>
            </div>
            <div className={style.bottomButtons}>
              <div className={style.subscribeBtn}>
                {authenticated ? (
                  this.props.subLoading || this.props.unsubLoading ? (
                    <div><FontAwesomeIcon icon={faSpinner} /></div> ):
                    has_sub ? (
                    <div onClick={() => this.props.handleUnsubscribe(id)}>{bellSlash} Deabonnieren</div>
                  ):(
                    <div onClick={() => this.props.handleSubscribe(id)}>{bell} Abonnieren</div>)
                    ):(
                      <div onClick={() => this.props.history.push(`/login?redirectTo=${window.location.pathname}`)}>{bell} Abonnieren</div>)
                }
                </div>
                {this.props.isManager ? (
                  <div className={`${style.subscribeBtn} ml-2`}>
                    <div onClick={() => this.props.history.push(`/projects/${title_slug}/eventsmanage`)}><FontAwesomeIcon icon={faCalendarAlt} /> Aktionen</div>
                  </div>
                ):( 
                <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={() => this.props.history.push(`/projects/${title_slug}/join`)}>{userPlus} Mitglied werden</div>
                </div>
                )
                }
                {this.props.isReporter && 
                  <div className={`${style.subscribeBtn}`}>
                    <div onClick={() => this.props.history.push(`/projects/${title_slug}/moderate`)}>
                      <FontAwesomeIcon icon={faMegaphone} /> Moderation</div>
                  </div>
                }
                {this.props.isManager && 
                  <div className={`${style.subscribeBtn} ml-2`}>
                    <div onClick={() => this.props.history.push(`/projects/${title_slug}/settings`)}>
                      <FontAwesomeIcon icon={faCog} /> Einstellungen</div>
                  </div>
                }
              </div>
          </div>
          
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    subLoading: state.projects.subLoading,
    unsubLoading: state.projects.unsubLoading
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getSubState: (projectId) => dispatch(fetchProjectSub(projectId)),
    handleSubscribe: (projectId) => dispatch(subscribeProject(projectId)),
    handleUnsubscribe: (projectId) => dispatch(unsubscribeProject(projectId)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobProjectGoal));
