import React from "react";
import style from "../style/projectDeatil.module.css";
import Header from "../components/events/Header";
import data from "../data/projectDetail.json";
import { Helmet } from 'react-helmet-async';;
import { connect } from "react-redux";
import FormStyle from "../style/createArea.module.css";
import { Redirect } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowRight, faCheck, faGlobeEurope, faImage, faSave, faTrash, faTreeAlt } from "@fortawesome/pro-regular-svg-icons";
import TeamCard from "../components/TeamCard";

import { changeProjectSuggestStatus, changeTeam, changeTeamMember, deleteTeam, fetchTeam, fetchTeamMembers, fetchTeamProjects, removeTeamMember } from "../store/teams";
import ProjectCard from "../components/projects/ProjectCard";
import { Link } from "react-router-dom";
import SmallCardL from "../components/SmallCardL";
import TeamMembersCard from "../components/teams/TeamMembersCard";
import Confirm from "../components/Confirm";



class TeamManage extends React.Component {
  constructor(props) {
    super(props);
    this.teamSlug = this.props.match.params.teamSlug;
    this.initalState = {
      textAreaSize: false,
      fileUploadStateimage: "",
      fileUploadStatelogo: "",
      name: "",
      link: "",
      image: null,
      logo: null,
      about: "",
      treesToPlant: 0,
      showConfirm: false,
      confirmMember: {
        show: false,
        profile: null,
        name: null
      }
      
    }
    this.state = this.initalState

    this.inputReference = React.createRef()
    this.logoInputReference = React.createRef()

  }

  componentDidMount() {
 
    if(!this.props.team) {
      this.props.fetchTeam(this.teamSlug)
    }
    this.props.fetchTeamMembers(this.teamSlug)
    this.props.fetchTeamProjects(this.teamSlug)

  }

  fileUploadAction = () => this.inputReference.current.click();
  logoFileUploadAction = () => this.logoInputReference.current.click();

  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ ["fileUploadState"+e.target.name]: e.target.value });
    this.setState({ [e.target.name]: e.target.files[0] });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, about, link, logo, image, treesToPlant } = this.state;
    this.props.changeTeam(this.teamSlug,  {name, about, link, logo, image, treesToPlant});
    // CLEAR FORM ON SUCCESS
    this.setState(this.initalState)
    
  };

  handleProjectSuggest = (project, action) => {
    this.props.changeProjectSuggestStatus(this.teamSlug, project, action);
  };

  changeRole = (profile, role) => {
    this.props.changeTeamMember(this.teamSlug, profile, role);
  };

  removeMember = (confirmed, profile) => {
    if(confirmed){
      this.props.removeTeamMember(this.teamSlug, this.state.confirmMember.profile);
      this.setState({confirmMember: {
        show: false
      }})
    } else {
      this.setState({confirmMember: {
        show: true,
        name: profile.name,
        profile: profile.profile
      }})
    }
  }

  handleDelete = (confirmed) => {
    if(confirmed === true){
      this.props.deleteTeam(this.teamSlug)
      this.setState({showConfirm: false})
      this.props.history.push("/teams")
    }else{
      this.setState({showConfirm: true})
    }
  }

  render() {
    if (!this.props.authenticated) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    const team = this.props.team
    const members = this.props.members
    const projects = this.props.projects

    if (!team || members == undefined || !projects) {
      return "loading data";
    } else {
      const images = team.team_images

      return (
        <div className={style.ProjectDetail}>
          <Helmet>
            <title>Team-Einstellungen - TreePlantingProjects</title>
            <meta name="description" content={""} />
            {/* <meta property="og:title" content={"Schau dir "+ event.title+ " auf TreePlantingProjects.com an"}/>
            <meta property="og:image" content={event.header_image_url} /> */}
          </Helmet>
          {this.state.showConfirm && <Confirm text={"Willst du "+team.name+" wirklich löschen?"} handleConfirm={()=>this.handleDelete(true)} handleBack={()=>this.setState({showConfirm:false})} open2={this.state.showConfirm}/>}
          {this.state.confirmMember.show && <Confirm text={"Willst du "+this.state.confirmMember.name+" aus "+team.name+" wirklich löschen?"} handleConfirm={()=>this.removeMember(true, false)} handleBack={()=>this.setState({confirmMember:{show:false}})} open2={this.state.confirmMember.show}/>}

          <div
            className="d-flex align-items-center pt-5"
            style={{ marginBottom: "2.5em" }}
          >
            <h1>Team-Einstellungen</h1>
            
          </div>
          <div className={`${style.breadCrumbs} ${style.DesktopViewOnly}`}>
            <h6><Link to={"/teams"}>Teams <FontAwesomeIcon icon={faAngleRight}/></Link> <Link to={"/teams/"+team.slug}> {team.name} <FontAwesomeIcon icon={faAngleRight}/></Link> Einstellungen</h6>
          </div>
          <div className={`${style.Project} row`} style={{marginLeft: "-1.3em", marginTop: "2em"}}>
            <div className={`${FormStyle.form} col-md-6`}>
            <form action="" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <h1>Team-Name</h1>
                    <div className={FormStyle.formInput}>
                      <input
                        className={FormStyle.commonInput}
                        type="text"
                        onChange={this.handleChange}
                        defaultValue={team.name}
                        name="name"
                        required
                        placeholder="Name"
                        maxLength={50}
                      />
                      <FontAwesomeIcon icon={faGlobeEurope} className={FormStyle.inputIcon} />
                      </div>
                    </div>
                    <div className="col-md-6">
                    <h1>Pflanzziel</h1>
                    <div className={FormStyle.formInput}>
                      <input
                        className={FormStyle.commonInput}
                        type="number"
                        onChange={this.handleChange}
                        defaultValue={team.trees_to_plant}
                        name="treesToPlant"
                        required
                        placeholder="Baum Vorhaben"
                      />
                      <FontAwesomeIcon icon={faTreeAlt} className={FormStyle.inputIcon} />
                      </div>
                    </div>
                </div>
                <div>
                  <h1>Team-Beschreibung</h1>
                  <div className={FormStyle.formInput}>
                  <textarea
                      className={FormStyle.commonInput}
                      onChange={this.handleChange}
                      defaultValue={team.about}
                      name="about"
                      cols="30"
                      rows={this.state.textAreaSize ? ("10"):("1")}
                      required
                      maxLength={500}
                      onClick={()=>this.setState({textAreaSize: true})}
                      onBlur={()=>this.setState({textAreaSize: false})}
                      placeholder="Erzähl etwas über euch! Wer seid Ihr? Wo kommt ihr her? Wie wollt ihr euch mit TreePlantingProjects engagieren?"
                    ></textarea>
                    {this.state.textAreaSize && <p style={{cursor: "pointer"}}><strong><FontAwesomeIcon icon={faCheck} className="fa-fw"/>Fertig</strong></p>}
                    </div>
                </div>
                <div className="row">

                <div className="col-md-6">
                  <h1>Teambild</h1>
                  <div className={FormStyle.formInput}>
                    <input
                      className={FormStyle.commonInput}
                      type="text"
                      readOnly="readOnly"
                      required
                      placeholder={this.state.fileUploadStateimage ? (this.state.fileUploadStateimage.replace("C:\\fakepath\\", "")) : images.image ? (" Teambild ändern"):(" Teambild hinzufügen")}
                      onClick={this.fileUploadAction}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="image"
                      accept="image/*"
                      data-type='image'
                      hidden
                      ref={this.inputReference}
                      onChange={this.fileUploadInputChange}
                    />
                    <FontAwesomeIcon icon={faImage} className={FormStyle.inputIcon} />
                    </div>
                </div>
                 <div className="col-md-6">
                  <h1>Logo</h1>
                  <div className={FormStyle.formInput}>
                    <input
                      className={FormStyle.commonInput}
                      type="text"
                      readOnly="readOnly"
                      required
                      placeholder={this.state.fileUploadStatelogo ? (this.state.fileUploadStatelogo.replace("C:\\fakepath\\", "")) : images.logo ? (" Logo ändern"):(" Logo hinzufügen")}
                      onClick={this.logoFileUploadAction}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="logo"
                      accept="image/*"
                      data-type='image'
                      hidden
                      ref={this.logoInputReference}
                      onChange={this.fileUploadInputChange}
                    />
                    <FontAwesomeIcon icon={faImage} className={FormStyle.inputIcon} />
                    </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className={`${FormStyle.formBtn} mr-2`}
                    disabled={!this.state.image &&!this.state.logo 
                              &&(!this.state.name || this.state.name == team.name)
                              &&(!this.state.about || this.state.about == team.about)
                              &&(!this.state.treesToPlant || this.state.treesToPlant == team.trees_to_plant)
                              ||this.state.textAreaSize
                              }>
                            <FontAwesomeIcon icon={faSave} className="fa-fw" />
                            Änderungen speichern</button>
                  <button type="button" className={`${FormStyle.formBtn} ${FormStyle.redBtn}`} onClick={()=>this.handleDelete(false)}>
                            <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                            Team löschen</button>
                  </div>
                </div>
              </form>
            </div>
            <div>
              <div className={style.DesktopViewOnly}>
                <TeamCard team={team}/>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5">
              <div
                style={{ marginBottom: "2.5em" }}
                id={"Mitglieder"}
              >
                <h1>Mitglieder ({members.length})</h1>
              </div>
              <div className="mb-2" style={{marginLeft: "-1em"}}>
                <div>
                  {members.map(member=>(
                    <TeamMembersCard member={{...member, isManager:member.is_manager}} onRoleChange={this.changeRole} onRemove={this.removeMember}/>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div
                style={{ marginBottom: "2.5em" }}
                id={"Mitglieder"}
              >
                <h1>Projekte ({projects.length})</h1>
              </div>
              <div className="mb-2" style={{marginLeft: "-1em"}}>
              {projects.map(project=>(
                <ProjectCard 
                  data={project.project_details}
                  status={project.status}
                  source={"manage"}
                  imageLocal={false}
                  onSuggestAccept={e=>this.handleProjectSuggest(e, true)}
                  onSuggestDecline={e=>this.handleProjectSuggest(e, false)}
                />
              ))}
              </div>
              </div>
            </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authenticated: state.auth.token !== null,
    team: state.teams.list[ownProps.match.params.teamSlug] ,
    members: state.teams.members[ownProps.match.params.teamSlug],
    loading: state.teams.loading,
    projectsFetchLoading: state.teams.projectsFetchLoading,
    projects: state.teams.projects[ownProps.match.params.teamSlug]
  };
};

const mapDispatchToProps = (dispatch) => {
  
  return {
    fetchTeam: (slug) => dispatch(fetchTeam(true, slug)),
    fetchTeamMembers: (slug) => dispatch(fetchTeamMembers(slug)),
    fetchTeamProjects: (slug) => dispatch(fetchTeamProjects(true, slug)),
    changeProjectSuggestStatus: (slug, project, action) => dispatch(changeProjectSuggestStatus(slug, project, action)),
    changeTeam: (slug, team) => dispatch(changeTeam(slug, team)),
    changeTeamMember: (slug, member, role) => dispatch(changeTeamMember(slug, member, role)),
    removeTeamMember: (slug, member) => dispatch(removeTeamMember(slug, member)),
    deleteTeam: slug => dispatch(deleteTeam(slug))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamManage);
