import React from "react";

export default function EventCooperationPartner({data}) {
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  return (
    <>
    <div className="row">
      <div className={"col-md-12 mb-3 mb-md-0"}>
        <div
          className="bg-white h-100"
          style={{ padding: "1.5em", borderRadius: "2em" }}
        >
          {renderHTML(data[0].cooperation_partner)}
        </div>
      </div>
    </div>

    </>
  );
}
