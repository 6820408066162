import React from "react";
import style from "../style/projectDeatil.module.css";
import Header from "../components/events/Header";
import data from "../data/projectDetail.json";
import { Helmet } from 'react-helmet-async';;
import { connect } from "react-redux";
import {
  fetchProjectMemberDetails,
} from "../store/projects";
import ProjectNews from "../components/projects/ProjectNews";
import { getEventFromList, getProjectIdBySlug } from "../store/utility";
import { fetchEventCooperationPartner, fetchEventLocation, fetchProjectEvents } from "../store/events";
import EventGoal from "../components/events/EventGoal";
import MobEventGoal from "../components/events/MobEventGoal";
import EventInformation from "../components/events/EventInformation";
import EventCooperationPartner from "../components/events/EventCooperationPartner";

import LocationCard from "../components/events/LocationCard";
import LoadingGoal from "../components/projects/LoadingGoal";
import LoadingHeader from "../components/projects/LoadingHeader";
import EventLocationDescription from "../components/events/EventLocationDescription";


class EventDetail extends React.Component {
  constructor(props) {
    super(props);
    this.project_name = this.props.match.params.projectName;
    this.event_id = this.props.match.params.eventId;
  }

  componentDidMount() {
    this.props.fetchProjectMemberDetails(this.props.authenticated, this.project_name);
    this.props.fetchProjectEvents(this.project_name, this.props.authenticated);
    this.props.fetchEventLocation(this.event_id);
    this.props.fetchEventCooperationPartner(this.event_id);
  }

  render() {
    const partner = () => (
      this.props.cooperationPartner.filter(partner=>partner.id===this.event_id)
    )
    const { loaded, members, event, authenticated, locations, locationLoading} = this.props;
    if (false) {
      return "loading data";
    } else {
      return (
        <div className={style.ProjectDetail}>
          <HtmlMeta event={event} />
          <div className={style.DesktopViewOnly}>
            {event ? (
              <>
              <Header 
              data={data[1]} 
              isManager={event.is_organiser} 
              project={this.project_name} 
              event={this.event_id}
              authenticated
              />
              <EventGoal id={"Übersicht"} data={event} authenticated={authenticated} project={this.project_name} locationData={locations}/>
              </>
            ):(
              <>
              <LoadingHeader />
              <LoadingGoal />
              </>
            )}
           </div>
          <div className={style.MobileViewOnly}>
            {event && 
            <MobEventGoal 
              id={"Übersicht"} 
              data={event} 
              authenticated={authenticated} 
              project={this.project_name} 
              event={this.event_id}
            />}
            {locations[this.event_id] && <LocationCard location={locations[this.event_id]}/>}
          </div>
          {event && locations[this.event_id] &&
            <>
            <div
              className="d-flex align-items-center pt-5"
              style={{ marginBottom: "2.5em", marginTop: "-6em" }}
              
              id={"Anfahrt"}
            >
              <h1>Anfahrt</h1>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <EventLocationDescription data={locations[this.event_id]} />
              </div>            
            </div>
            </>
          }
          {event && partner().length > 0 && partner()[0].cooperation_partner &&
            <>
            <div
              className="d-flex align-items-center pt-5"
              style={{ marginBottom: "2.5em", marginTop: "-2em" }}
              
              id={"Partner"}
            >
              <h1>Partner</h1>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <EventCooperationPartner data={partner()} />
              </div>            
            </div>
            </>
          }
          <div
            className="d-flex align-items-center pt-5"
            style={{ marginBottom: "2.5em", marginTop: "-2em" }}
            id={"Informationen"}
          >
            <h1>Informationen</h1>
          </div>
          <EventInformation data={event} />
          {/* 
          <div
            className="d-flex align-items-center"
            style={{ marginBottom: "2.5em" }}
            id={"Neuigkeiten"}
          >
            <h1>Neuigkeiten</h1>
          </div>
          <div className={style.Projects} style={{marginLeft: "-1em"}}>
            {newsLoaded && news !== undefined ? (<ProjectNews news={projectNews} projectName={this.project_name}/>):("News werden geladen...")}
          </div>
          <div
            className="d-flex align-items-center mt-5"
            style={{ marginBottom: "2.5em" }}
            id={"Unterstützer*innen"}
          >
            <h1>Unterstützer*innen</h1>
            {authenticated ? (
              <Link to="/projects_join">Ich will auch mitmachen</Link>
            ) : (
              <Link to={`/login?redirectTo=${window.location.pathname}`}>Melde dich an um ein Projekt Mitglied zu werden</Link>
            )}
          </div>
          {membersLoaded && members !== undefined? (
            <ProjectMembers members={members} user={this.props.user}/>
            ):("Mitglieder werden geladen...")}
              
          {areasLoaded && areas !== undefined ? (
            <div>
              <div
                className="d-flex align-items-center pt-5"
                style={{ marginBottom: "2.5em" }}
                id={"Flächen"}
              >
                <h1>Flächen</h1>
              </div>
              <div className={style.Projects} style={{marginLeft: "-1em"}}>
                <ProjectAreas areas={areas} />
              </div>
            </div>
          ) : (
            ""
          )}

           */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    authenticated: state.auth.token !== null,
    loaded: state.events.loaded,
    project: id,
    members: state.projects.members[id],
    event: getEventFromList(state.events.list, ownProps.match.params.eventId),
    locations: state.events.locations,
    locationLoading: state.events.locationLoading,
    cooperationPartner: state.events.cooperationPartner
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectMemberDetails: (auth, project) => dispatch(fetchProjectMemberDetails(auth, project)),
    fetchProjectEvents: (project, auth) => dispatch(fetchProjectEvents(project, auth)),
    fetchEventLocation: event => dispatch(fetchEventLocation(event)),
    fetchEventCooperationPartner: event => dispatch(fetchEventCooperationPartner(event))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);

const HtmlMeta = event => {
  if (!event.event) return ""
  return(
  <Helmet>
    <title>{event.event.title} - TreePlantingProjects</title>
    <meta name="description" content={event.event.information.substring(0, 90) + '...'} />
    <meta property="og:title" content={event.event.title}/>
    <meta property="og:image" content={event.event.header_image_url} />
  </Helmet>
  )
}