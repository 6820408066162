import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React from "react";
import { Helmet } from 'react-helmet-async';
function MaintenceApp(props) {
  return (
    <div className="App">
        {/* <BrowserRouter> */}
          {/* <Routes /> */}
          <Helmet
            encodeSpecialCharacters={true}
            titleTemplate="%s - TreePlantingProjects"
            defaultTitle="TreePlantingProjects"
          >
            <meta name="description" content="Wartung - TreePlantingProjects an." />
            <meta name="og" content="TreePlantingProjects" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg"/>
          </Helmet>
          
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
        {/* </BrowserRouter> */}
    </div>
  );
}

export default MaintenceApp;
