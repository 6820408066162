import React from "react";
import style from "../style/projectDeatil.module.css";
import Header from "../components/teams/Header";
import data from "../data/projectDetail.json";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import LoadingGoal from "../components/projects/LoadingGoal";
import LoadingHeader from "../components/projects/LoadingHeader";
import { fetchTeam, fetchTeamEvents, fetchTeamProjects, fetchTeams, leaveTeam } from "../store/teams";
import TeamGoal from "../components/teams/TeamGoal";
import SmallCardLoading from "../components/SmallCard";
import TeamMembers from "../components/teams/TeamMembers";
import MobTeamGoal from "../components/teams/MobTeamGoal";
import ProjectCard from "../components/projects/ProjectCard";
import { Link } from "react-router-dom";
import EventCard from "../components/EventCard";


class TeamDetail extends React.Component {
  constructor(props) {
    super(props);
    this.teamSlug = this.props.match.params.teamSlug;
  }

  componentDidMount() {
    this.props.fetchTeam(this.props.authenticated, this.teamSlug);
    this.props.fetchTeamProjects(this.props.authenticated, this.teamSlug);
    this.props.fetchTeamEvents(this.props.authenticated, this.teamSlug);
  }

  render() {
    const { loading, team, authenticated, projects, events} = this.props;
    if (false) {
      return "loading data";
    } else {
      return (
        <div className={style.ProjectDetail}>
          <HtmlMeta event={team} />
          <div className={style.DesktopViewOnly}>
            {team ? (
              <>
              <Header 
              data={data[1]} 
              isManager={team.is_manager} 
              isMember={team.is_member}
              team={team} 
              authenticated
              handleLeave={this.props.handleLeave}
              />
              <TeamGoal id={"Übersicht"} data={team} authenticated={authenticated} teamSlug={this.teamSlug} />
              </>
            ):(
              <>
              <LoadingHeader />
              <LoadingGoal />
              </>
            )}
           </div>
          <div className={style.MobileViewOnly}>
            {team && 
            <MobTeamGoal 
              id={"Übersicht"} 
              data={team} 
              authenticated={authenticated} 
              team={this.teamSlug} 
            />}
            {/* {locations[this.event_id] && <LocationCard location={locations[this.event_id]}/>} */}
          </div>
         <div
            className="d-flex align-items-center pt-5"
            style={{ marginBottom: "1em", marginTop: "-6em" }}
            id={"Informationen"}
          >
            <h1>Mitglieder</h1>
          </div>
          <div
            style={{
              marginLeft: "-1em",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
          {team ? (
            <TeamMembers members={team.team_images.members} user={false}/>
            ):(
              Array.from(Array(10).keys()).map((key => (
                <SmallCardLoading key={key}/>
              )))
            )}
            </div>
            {typeof events == "object" && Boolean(events.length) &&
            <>
            <div
              className="d-flex align-items-center pt-5"
              style={{ marginBottom: "1em" }}
              id={"Aktionen"}
            >
              <h1>Aktionen</h1>
              {/* {!projects.length && <p className="ml-3">
                Dein Team hat noch keine Projekte unterstützt. Daher zeigen wir  dir Aktionen deines Managers.
                </p>} */}
            </div>
            <div
              style={{
                marginLeft: "-1em",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {events.map(event => (
                <Link to={`/projects/${event.project_slug}/events/${event.id}`} >
                  <EventCard event={event} projectId={event.project}/>
                </Link>
              ))}
    
            </div>
            </>
            }
          {Boolean(projects.length) ? (
              <>
          <div
            className="d-flex align-items-center pt-5"
            style={{ marginBottom: "1em" }}
            id={"Projekte"}
          >
            <h1>Projekte</h1>
          </div>
          <div
            style={{
              marginLeft: "-1em",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {projects ? (

              projects.map(project => (
                <Link to={`/projects/${project.project_details.slug}`} >
                  <ProjectCard data={project.project_details} status={project.status} source={"teamDetail"} imageLocal={false}/>
                </Link>
              ))
            ):(
              Array.from(Array(10).keys()).map((key => (
                <SmallCardLoading key={key}/>
              )))
            )}
          </div>
          </>
          ):("")}

          {/* <EventInformation data={event} /> */}
        </div>
        
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authenticated: state.auth.token !== null,
    loading: state.teams.loading,
    team: state.teams.list[ownProps.match.params.teamSlug],
    teams: state.teams.list,
    projectsFetchLoading: state.teams.projectsFetchLoading,
    projects: state.teams.projects[ownProps.match.params.teamSlug] || [],
    events: state.teams.events[ownProps.match.params.teamSlug] || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTeam: (auth, team) => dispatch(fetchTeam(auth, team)),
    fetchTeamProjects: (auth, team) => dispatch(fetchTeamProjects(auth, team)),
    handleLeave: (teamSlug) => dispatch(leaveTeam(teamSlug)),
    fetchTeamEvents: (auth,team) => dispatch(fetchTeamEvents(auth, team))

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamDetail);

const HtmlMeta = ({team}) => {
  if (!team) return ""
  return(
  <Helmet>
    <title>{team.name} - TreePlantingProjects</title>
    <meta name="description" content={team.about.substring(0, 90) + '...'} />
    <meta property="og:title" content={team.name}/>
    <meta property="og:image" content={team.images.logo} />
  </Helmet>
  )
}