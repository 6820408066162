import { projectsSubLogout, resetProjectDetailsLoad } from "../projects";
import { areasLogout } from "../areas";
import { profileLogout } from "../profile";
import storage from 'redux-persist/lib/storage' 

const cleanup = ({ dispatch }) => next => action => {

    if(action.type !== "auth/authLogout") return next(action);
    next(action)
    // dispatch({type: "apiCall", payload: {url:"api-auth-logout"}})
    dispatch(projectsSubLogout())
    dispatch(areasLogout())
    dispatch(profileLogout())
    //dispatch(blogLogout())
}


export default cleanup;