import React, { useState } from "react";
import style from "../../style/Header.module.css";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faHandHoldingSeedling, faPenAlt, faSignOut } from "@fortawesome/pro-regular-svg-icons";

export default function Header({ data, isManager, isMember, team, authenticated, handleLeave }) {
  const { pageLinks, ActionBtn, mainBtn } = data;
  const [link, setLink] = useState(pageLinks[0]);
  return (
  <div className={style.menu}>
    <div style={{position: "sticky", top: "0", zIndex: "2"}}>
      <div
        className={`${style.Header} justify-content-center justify-content-lg-between`}
      >
        <div className="mb-2 mb-md-0">
          {pageLinks.map((item) => (
            <a key={item}
              href={`#${item}`}
              className={link == item ? style.active : ""}
              onClick={() => setLink(item)}
            >
              {item}
            </a>
          ))}
        </div>
        <div className="">
          {isMember && authenticated ? (
            <>
            {team.can_leave &&
              <a className={`${style.actionBtn} mb-2 mb-sm-0`} onClick={() => handleLeave(team.slug)}>
                <FontAwesomeIcon icon={faSignOut} className="fa-fw mr-1" />
                Team verlassen
              </a>
              }
              <Link to={`/teams/${team.slug}/suggestProject`} className={`${style.actionBtn} mb-2 mb-sm-0`}>
                <FontAwesomeIcon icon={faHandHoldingSeedling} className="fa-fw mr-1" />
                Projekt vorschlagen
              </Link>
            </>
          ):("")}
          {isMember && isManager && authenticated ? (
            <Link to={`/teams/${team.slug}/manage`} className={`${style.actionBtn} mb-2 mb-sm-0`}>
                <FontAwesomeIcon icon={faCog} className="fa-fw mr-1" />
                Team-Einstellungen
              </Link>
          ):("")}
        </div>
      </div>
    </div>
  </div>
  );
}
