import React from "react";
import style from "../../style/createArea.module.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import areas, { reportArea } from "../../store/areas";
import { toast } from "react-toastify";
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCity, faCube, faFlag, faImage, faLink, faMapMarkedAlt, faMapMarkerAlt, faPlus, faSave, faTrash } from "@fortawesome/pro-regular-svg-icons";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const styles = {

};


class ReportArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUploadState: "",
      location: "",
      locations: [
        {
          id: 0,
          mark: "",
          number: ""
        }
      ],
      size: "",
      link: "",
      image: null,
      description: "",
      stepper: 1
    }
    this.inputReference = React.createRef();

   }


  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    this.setState({ image: e.target.files[0] });
  }

  handleChange = e => {
    const locations = this.state.locations;
    if(e.target.name.includes('mark')) {
      const key = parseInt(e.target.name.replace("mark_",""))
      locations[key].mark = e.target.value
      this.setState({ locations });
    }
    else if(e.target.name.includes('number')) {
      const key = parseInt(e.target.name.replace("number_",""))
      locations[key].number = e.target.value
      this.setState({ locations });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    
  };

  handleAddLocationSet = () => {
    const locations = this.state.locations;
    this.setState({locations: [...locations, {id: locations.length, mark: "", number: ""}]})
  }

  handleRemoveLocationSet = key => {
    const locations = this.state.locations;
    
    this.setState({locations: [...locations.filter(location=>location.id !== key)]})
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setStepper(6)
    const { location, size, link, image, description, locations } = this.state;
    this.props.report(location, size, link, image, description, locations);
    if(this.state.fileUploadState) {
      this.props.history.push('/areas');
    }
    
  };

  setStepper = e => {
    this.setState({stepper: e})
  }

  resetStepper = () => {
    this.setState({stepper: 1})
  }

  isValidLocationSet = () => {
    const valid = this.state.locations.filter(location=>(
      !location.mark || !location.number
    )
    )
    if(valid.length > 0 ){
      return false
    }
    return true

  }

  render() {
    const { classes, children, className, ...other } = this.props;
    const { location, size, link, image, description } = this.state;
    if (!this.props.authenticated) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Fläche melden - TreePlantingProjects" /> 
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className="col-md-6">
            <img className={"img-fluid"} src="images/report_area_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <Link to="/areas" className={style.backLink}>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </Link>
            <h2 style={{marginTop: "0.8em"}}className={style.mainHeading}>Du möchtest eine Fläche melden?</h2>
            <div className={style.mainText}>Wir nehmen deine Fläche in unsere Datenbank auf und stellen sie lokalen Projektgruppen zur Verfügung.
              </div>
              <div className="mt-2">
                <LinearProgress variant="determinate" color="primary" className={classes.bar} value={(16.6*(this.state.stepper-1))} />
                
              </div>
            <form style={{marginTop: "2em"}} action="" onSubmit={this.handleSubmit}>
              {this.state.stepper === 1 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>1. Unter welchem Namen ist die Fläche bekannt?</h1>
                  <div className={style.formInput}>
                      <input
                        className={style.commonInput}
                        type="text"
                        onChange={this.handleChange}
                        value={location}
                        name="location"
                        required
                        placeholder="Name"
                      />
                      <FontAwesomeIcon icon={faMapMarkerAlt} className={style.inputIcon} />
                    </div>
                    <div className={"mt-3"}>
                   <button type="button" disabled={!location} onClick={()=>this.setStepper(2)} className={style.formBtn}>
                    Weiter
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                  </div>
                  </div>
                </div>}
                {this.state.stepper === 2 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>2. Wo ist die Fläche zu finden?</h1>
                  {this.state.locations.map(location=>(
                    <div className="row">
                    <div className="col-md-5">
                      <div className={style.formInput}>
                        <input
                          className={style.commonInput}
                          type="search"
                          onChange={this.handleChange}
                          name={"mark_"+location.id}
                          value={location.mark}
                          required
                          placeholder="Gemarkung"
                        />
                        <FontAwesomeIcon icon={faFlag} className={style.inputIcon} />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className={style.formInput}>
                        <input
                          className={style.commonInput}
                          type="search"
                          onChange={this.handleChange}
                          name={"number_"+location.id}
                          value={location.number}
                          required
                          placeholder="Flurnummer"
                        />
                        <FontAwesomeIcon icon={faMapMarkerAlt} className={style.inputIcon} />
                      </div>
                    </div>
                    <div className="col-md-2">
                      {location.id !== 0 && <FontAwesomeIcon icon={faTrash} className="mt-3" style={{cursor: "pointer"}} onClick={()=>this.handleRemoveLocationSet(location.id)}/>}
                    </div>
                  </div>
                  ))}
                  
                  <p onClick={this.handleAddLocationSet} style={{cursor: "pointer"}}><FontAwesomeIcon icon={faPlus} className="fa=fw"/> Weitere Gemarkung & Flurnummer</p>
                  <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(1)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button" disabled={!this.isValidLocationSet()} onClick={()=>this.setStepper(3)} className={`${style.formBtn} ml-1`}>
                    Weiter
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                  </div>
                  </div>
                </div>}
                {this.state.stepper === 3 && <div className={"row"}>
                <div className="col-md-12">
                <h1>3. Wie Groß ist deine Fläche (in Hektar)?</h1>
                  <div className={style.formInput}>
                      <input
                        className={style.commonInput}
                        type="text"
                        onChange={this.handleChange}
                        value={size}
                        required
                        name="size"
                        placeholder="Größe in Hektar"
                      />
                      <FontAwesomeIcon icon={faCube} className={style.inputIcon} />
                    </div>
                    <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(2)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button" disabled={!size || !parseFloat(size.replace(',','.'))} onClick={()=>this.setStepper(4)} className={`${style.formBtn} ml-1`}>
                    Weiter
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                    </div>
                  </div>
                </div>}
                {this.state.stepper === 4 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>5. Hast du ein Bild parat? (Optional)</h1>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      readOnly="readOnly"
                      required
                      placeholder={this.state.fileUploadState ? (this.state.fileUploadState.replace("C:\\fakepath\\", "")) : ("Bild hochladen")}
                      onClick={this.fileUploadAction}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="image"
                      accept="image/*"
                      data-type='image'
                      hidden
                      ref={this.inputReference}
                      onChange={this.fileUploadInputChange}
                    />
                    <FontAwesomeIcon icon={faImage} className={style.inputIcon} />
                    <p className={style.required}>{!this.state.fileUploadState ? ("Das Bild kannst du auch später noch hochladen oder ändern."):("")}</p>
                    </div>
                    <div className={"mt-3"}>
                    <button type="button" onClick={()=>this.setStepper(3)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button"onClick={()=>this.setStepper(5)} className={`${style.formBtn} ml-1`}>
                    {!this.state.fileUploadState ? ("Ohne Bild"):("Weiter")}
                    <FontAwesomeIcon icon={faArrowRight} className="fa-fw" /></button>
                    </div>
                  </div>
                </div>}
                {this.state.stepper === 5 && <div className={"row"}>
                <div className="col-md-12">
                  <h1>5. Erzähl uns, was Du über Deine Fläche weißt! </h1>
                  <p>Wachsen dort schon Bäume?
                  <br />
                  Wenn ja: welche? Wurde dort in den vergangenen Jahren schon mal gepflanzt? Und wie ist die Lage?
                  Vielleicht hast Du ja sogar schon eine Vorstellung davon, wie ein TPP-Projekt dort aussehen könnte.
                  <br />
                  Schreib hier einfach alles rein, was Du für wichtig hältst!</p>
                  <textarea
                    className={style.commonInput}
                    onChange={this.handleChange}
                    value={description}
                    name="description"
                    cols="30"
                    rows="10"
                    required
                    placeholder="Hier ist genug Platz"
                  ></textarea>
                  {description.length > 1500 ?("Der Text ist zu lang. "+description.length+"/1500 Zeichen"):("")}
                  <div className={"mt-3"}>
                  <button type="button" onClick={()=>this.setStepper(4)} className={style.formBtn}>
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                    Zurück
                    </button>
                    <button type="button" disabled={!description || description.length > 1500} onClick={this.handleSubmit} className={`${style.formBtn} ml-1`}>
                    <FontAwesomeIcon icon={faSave} className="fa-fw" />
                    Fläche speichern</button>
                  </div>
                  </div>
                </div>}
            </form>
            {this.state.stepper === 6 && <div>
                {this.props.reportLoading && <p>Fläche wird angelegt <CircularProgress size={10} /></p>}
                {!this.props.reportLoading && !this.props.reportError && <Redirect to={"/areas"}/>}
                {this.props.reportError && <div><p>Ein Fehler ist aufgetreten!</p><br/><p>{this.props.reportError.data}</p><br/><br/><p onClick={this.resetStepper}><strong>Nochmal probieren</strong></p></div>}
              </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    user: state.profile.user_id,
    authenticated: state.auth.token !== null,
    reportLoading: state.areas.reportLoading,
    reportError: state.areas.reportError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    report: (location, size, link, image, description, owner, mark, number) => {
      dispatch(reportArea(location, size, link, image, description, owner, mark, number))
    }
  };
};



export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportArea));
