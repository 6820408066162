import React from "react";
import style from "../../style/createArea.module.css";
import listStyle from "../../style/Admin.module.css";
import { Input } from "antd";
import {Redirect} from "react-router-dom";
import DataTable from 'react-data-table-component';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { fetchProjectSettings, updateMembers, removeMembers, updateProject, updateProjectImages, fetchProjectDetails } from "../../store/projects"
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheckCircle, faImage, faSearch, faTimesCircle, faTreeAlt, faUsers } from "@fortawesome/pro-regular-svg-icons"; 
import ProjectSettingsProjectCard from "./ProjectSettingsProjectCard";
import ProjectRoleDropdown from "./ProjectRoleDropdown";
import ProjectImage from "./ProjectImage";
import { getProjectIdBySlug } from "../../store/utility";


class ProjectSettings extends React.Component {

  constructor(props) {
    super(props);
    this.inputReference = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      selectedMembers: [],
      
      filterText: "",
      processImages: []
    }
    this.project_name = this.props.match.params.projectName;

    this.columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        width: "190px",
        cell: row => <div className={style.profileImage}><img src={row.image} alt="profile" /><span>{row.name}</span></div>
      },
      {
        name: 'Rolle',
        selector: 'role',
        sortable: true,
        width: "10em",
        cell: row => <ProjectRoleDropdown role={row} />
      },
      {
        name: 'Letze Anmeldung',
        selector: 'last_login',
        sortable: true,
        cell: row => new Date(row.last_login).toLocaleString('de-DE', {
          day: "2-digit",
          year: "numeric",
          month: "2-digit",
          hour: "numeric",
          minute: "numeric" 
        })
      },
      {
        name: 'Bestätigt',
        selector: 'verified',
        sortable: true,
        cell: row => <div>{row.verified ? (<FontAwesomeIcon icon={faCheckCircle}/>):(<FontAwesomeIcon icon={faTimesCircle}/>)}</div>
      }
    ];

  }

  handleRowChange = (state) => {
    this.setState({selectedMembers: state.selectedRows});
  };

  handleVerify = () => {
    this.props.updateMembers(this.props.project, this.state.selectedMembers);
  }

  handleRemoveMembers = () => {
    this.props.removeMembers(this.props.project, this.state.selectedMembers);
  }

  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    this.setState({ image: e.target.files[0] });
  }

  handleChange = e => {
    let k = e.target.value
    if(e.target.checked) k = e.target.checked
    this.setState({ [e.target.name]: k });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.updateProject(this.props.project, this.state);
    const images = this.state.processImages;
    if(images.length) this.props.updateProjectImages(this.props.project, images);
    this.setState({processImages: []})
  };

  componentDidMount() {
    this.props.getProjectDetails(this.project_name, this.props.token, false)
    this.props.fetchProjectSettings(this.props.project) 
  }

  handleClear = () => {
    const { resetPaginationToggle, filterText } = this.state;

    if (this.state.filterText) {
      this.setState({
        resetPaginationToggle: !resetPaginationToggle,
        filterText: ""
      });
    }
  };

  getSubHeaderComponent = () => {
    const disabledButtons = this.state.selectedMembers.length;
    return (
      <div
      className={`${listStyle.header} d-flex align-items-center justify-content-between`}
    >
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = this.props.settings.members.filter(
            (item) =>
              item.name &&
              item.name.toLowerCase().includes(newFilterText.toLowerCase())
          );
          this.setState({ filterText: newFilterText });
        }}
        filterText={this.state.filterText}
      />
      <div>
        <button 
          disabled={!disabledButtons} 
          onClick={this.handleVerify}
        >
          Bestätigen
        </button>
        <button 
          disabled={!disabledButtons}
          onClick={this.handleRemoveMembers}
        >
          Zurücknehmen
        </button>
      </div>
      </div>
    );
  };

  handleImageChange = (id=0, image='', action='') => {
    let images = this.state.processImages;
    images.push({id, image, action})
    this.setState({processImages: images})
  };
  

  render() {
    const {token, loaded, about, images, settings} = this.props;
    if (!token) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    if(!loaded) return ""
    this.filteredItems = this.props.settings.members.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(this.state.filterText.toLowerCase())
    );
    const project = this.props.settings;
    return (
        <div className={style.form}>
          <ReactTitle title={project.project_name+" Projekteinstellungen - TreePlantingProjects"} />
          <div className={`row d-flex vh-100`}>
            <div className="col-md-6 py-4 pr-5 pl-5">
            <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={`${style.mainHeading} mt-0`}>Projekteinstellungen</h2>
              <div className={style.mainText}>Einstellungen für <strong>{settings.project_name}</strong></div>
              <ProjectSettingsProjectCard data={about} settings={settings} image={project.project_image}/>
                <form className="mt-3" action="" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={style.formInput}>
                        <input
                          className={style.commonInput}
                          type="text"
                          fluid="left"
                          name="name"
                          required
                          defaultValue={project.project_name}
                          onChange={this.handleChange}
                        />
                        <FontAwesomeIcon icon={faUsers} className={style.inputIcon} style={{fontSize: "2em", marginTop: "0.1em"}}/>
                      </div>
                      <div className={style.formInput}>
                      <input
                          readOnly="readOnly"
                          className={style.commonInput}
                          type="text"
                          required
                          placeholder={this.state.fileUploadState ? (this.state.fileUploadState.replace("C:\\fakepath\\", "")) : ("Bild hochladen")}
                          onClick={this.fileUploadAction}
                        />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          name="image"
                          hidden
                          accept="image/*"
                          data-type='image'
                          ref={this.inputReference}
                          onChange={this.fileUploadInputChange}
                        />
                        <FontAwesomeIcon icon={faImage} className={style.inputIcon} />
                      </div>
                      <div className={style.formInput}>
                        <input
                          className={style.commonInput}
                          type="text"
                          name="short_description"
                          fluid="left"
                          required
                          defaultValue={project.project_short_description}
                          onChange={this.handleChange}
                        />
                        <FontAwesomeIcon icon={faTreeAlt} className={style.inputIcon} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={style.formInput}>
                        <textarea
                          className={style.commonInput}
                          name="description"
                          cols="30"
                          rows="7"
                          required
                          defaultValue={project.project_description}
                          onChange={this.handleChange}
                        ></textarea>
                      </div>
                    </div>
                </div>
                <div className={style.formInput}>
                        <textarea
                          className={style.commonInput}
                          name="information"
                          cols="30"
                          rows="5"
                          required
                          defaultValue={project.project_information}
                          onChange={this.handleChange}
                        ></textarea>
                      </div>
                <div className="row">
                    <ProjectImage image={images[0]} onChange={this.handleImageChange}/>
                    <ProjectImage image={images[1]} onChange={this.handleImageChange}/>
                    <ProjectImage image={images[2]} onChange={this.handleImageChange}/>
                    <ProjectImage image={images[3]} onChange={this.handleImageChange}/>
                    {/* <ProjectImage image={images[4]} onChange={this.handleImageChange}/> */}
                </div>
                <div>
                  <button className={style.formBtn} disabled={this.props.updateLoading}>Projekt aktualisieren</button>
                </div>
              </form>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <h2 className={`${style.mainHeading} mt-5`}>Unterstützer*innen</h2>
              <div className={style.mainText}>Hier kannst du die Unterstützer*innen verwalten</div>
              <div className={`${listStyle.AdminTable} mt-2`}>
                <DataTable
                  subHeader
                  subHeaderComponent={this.getSubHeaderComponent()}
                  noHeader
                  columns={this.columns}
                  data={this.filteredItems}
                  pagination
                  selectableRows
                  Clicked
                  onSelectedRowsChange={this.handleRowChange}
                  selectableRowsComponent={Checkbox}
                  persistTableHead
                />
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    loaded: state.projects.settingsLoaded,
    detailsLoaded: state.projects.detailsLoaded,
    profile: state.profile,
    project: id,
    about: state.projects.about[id],
    images: state.projects.images[id] || [],
    settings: state.projects.settings,
    updateLoading: state.projects.projectUpdateLoading,
    error: state.auth.error,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProjectSettings: id => dispatch(fetchProjectSettings(id)),
    updateMembers: (id, members) => dispatch(updateMembers(id, members)),
    removeMembers: (id, members) => dispatch(removeMembers(id, members)),
    updateProject: (id, data) => dispatch(updateProject(id, data)),
    updateProjectImages: (id, data) => dispatch(updateProjectImages(id, data)),
    getProjectDetails: (projectId, auth, fetched) =>
      dispatch(fetchProjectDetails(projectId, auth, fetched))
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectSettings));


const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <label className={listStyle.containerCheckbox}  >
      <input 
        type="checkbox"
        ref={ref}
        {...rest} 
      />
      <span className={listStyle.checkmark} onClick={onClick}></span>
  </label>
  
));


const FilterComponent = ({ filterText, onFilter }) => (
  <Input
    placeholder="Suche nach Namen"
    className={listStyle.search}
    prefix={
      <FontAwesomeIcon icon={faSearch} className="fa-fw" />
    }
    id="search"
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
  />
);