import React from "react";
import projects, { prepareRemoveMember } from "../../store/projects";
import style from "../../style/smallCard.module.css";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { getProjectIdBySlug } from "../../store/utility";


class ProjectMembersCard extends React.Component {
  constructor(props) {
    super(props)
  }

  handleRemove = () => {
    const { user, project, member } = this.props
    this.props.prepareRemoveMember(user, member.role, project.id)
    this.props.history.push("/projects/"+project.title_slug+"/leave")

    //this.props.fetchMembers(project)
  }

  render() {
    const { name, image, role, verified} = this.props.member
    const {user, project} = this.props
    let role_data = {}
    if(role == 1) role_data = {name: "Helfer*in", image: "memberHelper.svg"}
    if(role == 2) role_data = {name: "Reporter*in", image: "memberReporter.svg"}
    if(role == 3) role_data = {name: "Organisator*in", image: "smCardImage.svg"}
    if(role == 3 || role == 2 || user === this.props.member.user) {
      return (
        <div className={style.SmallCard}>
          <img src={`/images/${role_data.image}`} alt="" />
          <div className={style.tooltip}>{role_data.name}</div>
          <img src={image} alt="" />
          <div>
            <h6>{name}</h6>
            <p>{role_data.name} {verified === false && this.props.member.user ? ("- Ausstehend"):("")}</p>
          </div>
          {this.props.member.user == user ? (
            <>
          <img onClick={()=>{this.handleRemove()}} 
            className={style.remove} 
            src="/images/smCardImageRemove.svg" 
            alt="" />
            <div className={style.removeTooltip} onClick={()=>{this.handleRemove()}}  >Projekt verlassen</div>
            </>
          ):("")}
        </div>
      );
    }
    else {
      return (
        <div className={style.SmallCard_m}>
          <img src={`/images/${role_data.image}`} alt="" />
          <div className={style.tooltip_m}>{role_data.name}</div>
          <img src={image} alt="" />
          {user === this.props.member.user ? (
            <>
          <img onClick={()=>{this.handleRemove()}} 
            className={style.remove_m} 
            src="/images/smCardImageRemove.svg" 
            alt="" />
            <div className={style.removeTooltip_m}>Projekt verlassen</div>
            </>
          ):("")}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    project: state.projects.about[id]
  };
};


const mapDispatchToProps = dispatch => {
  return {
    prepareRemoveMember: (id, role, project) => dispatch({type: prepareRemoveMember.type, payload: {user:id, role, project}}),
    // fetchMembers: (project) => dispatch(fetchProjectMemberDetails(true, project))
  };
};

export default withRouter(
  connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectMembersCard));
