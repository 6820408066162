import React from "react";
import style from "../style/smallCard.module.css";

export default function SmallCardL({image, text, handleClick}) {
  return (
    <div className={!text ? (style.SmallCard_m):(`${style.SmallCard_m} ${style.SmallCard_m_text}`)} onClick={handleClick} style={handleClick ? ({cursor: "pointer"}):({})}>
          <img src={""} alt="" style={{display: "none"}}/>
          <div className={style.tooltip}></div>
          <img src={image} alt="" />
          {text && <p>{text}</p>}
    </div>
  );
}
