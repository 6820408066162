import React from "react";
import style from "../../style/createArea.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

export default function CreateArea() {
  return (
    <div className={style.form}>
      <div className={"row d-flex"}>
        <div className={"col-md-6"}>
          <img className={"img-fluid h-100"} src={"/images/form-img.png"} alt="" />
        </div>
        <div className={"col-md-6 py-4 pr-5"} >
          <Link to="/area" className={style.backLink} href="#">
            <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
            back to area overview
          </Link>
          <h2 className={style.mainHeading}>Create a new area</h2>
          <div className={style.mainText}>This is a superfancy subtitle.</div>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <form className={"mt-5"} action="">
                <div className={style.formInput}>
                  <input
                    className={style.commonInput}
                    type="text"
                    name=""
                    placeholder="Name"
                  />
                  <img
                    src="/images/searchIcon.svg"
                    className={style.inputIcon}
                    alt=""
                  />
                </div>
                <div className={style.formInput}>
                  <input
                    className={style.commonInput}
                    type="text"
                    name=""
                    placeholder="Click to upload image"
                  />
                  <img
                    className={style.inputIcon}
                    src="/images/image-Icon.svg"
                    alt=""
                  />
                </div>
                <div className={style.formInput}>
                  <input
                    className={style.commonInput}
                    type="text"
                    name=""
                    placeholder="Add and confirm location"
                  />
                  <img
                    className={style.inputIcon}
                    src="/images/addressIcon.svg"
                    alt=""
                  />
                </div>
              </form>
              <img className={style.switch} src="/images/switch.png" alt="" />
              <span className={`${style.mainText} ml-3`}>
                Publish immediately
              </span>
              <div className={"mt-5 pt-5"}>
                <span className={style.subHeading}>Add members directly</span>
                <a className={style.backLink} href="#">
                  view all friends
                </a>
              </div>
              <div className={`${style.formInput} mt-4`}>
                <input
                  className={style.commonInput}
                  type="text"
                  name=""
                  placeholder="Search"
                />
                <img
                  className={style.inputIcon}
                  src="/images/searchIcon.svg"
                  alt=""
                />
              </div>
              <div className={style.userInfo}>
                <div className={style.imageSide}>
                  <div className={style.darkOverlay}></div>
                  <img src="/images/Avatar-2.png" alt="" />
                  <img src="/images/Sergei_Filatov.svg" alt="" />
                </div>
                <div>
                  <div className={style.subHeading}>Sergei Filatov</div>
                  <div className={style.mainText}>seit 15.02.20</div>
                </div>
              </div>
            </div>
            <div className={"col-md-6 mt-5"}>
              <textarea
                className={style.commonInput}
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Description"
              ></textarea>
            </div>
          </div>
          <div className={"text-right mt-5"}>
            <button className={style.formBtn}>Save area</button>
          </div>
        </div>
      </div>
    </div>
  );
}
