import React from "react";
import style from "../style/smallCard.module.css";
import Skeleton from '@material-ui/lab/Skeleton';

export default function SmallCardLoading() {
  return (
    <div className={style.SmallCard} style={{width: "69px", height: "69px"}}>
      <Skeleton variant="rect" width={"50px"} height={"50px"} style={{borderRadius:".625em", marginLeft: "4px"}} />
    </div>
  );
}
