import React from "react";
import style from "../../style/createArea.module.css";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faInfo } from "@fortawesome/pro-regular-svg-icons";
import { fetchNotifications, updateNotificationSeen } from "../../store/profile";
import NotificationCard from "./NotificationCard";

const userMinus = <FontAwesomeIcon icon={faUserMinus} />;


class NotificationsFull extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchNotifications()
  }
  
  render() {
    const {token, profile} = this.props;
    if (!token) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    return (
        <div className={style.form}>
          <ReactTitle title={"Benachrichtigungen - TreePlantingProjects"} />
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className="col-md-6">
              <img className={"img-fluid"} src="/images/join_project_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={style.mainHeading}>Du hast {profile.notifications.length} Benachrichtigungen</h2>
              <div className={style.mainText}><FontAwesomeIcon icon={faInfo} classname="fa-fw" style={{marginRight: "0.5em"}}/>E-Mails kannst du in deinem Profil austellen.</div>
              <div className={"row pt-3"}>
              <div className={"col-md-12"}>
                <div className={style.notificationWindow}>

                {profile.notifications.map((notification => (
                  <NotificationCard class={style.notification} key={notification.id} data={notification} handleSeen={()=>this.props.updateNotification(notification.id)}/>
                )))}
                </div>
                
                  
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchNotifications: () => dispatch(fetchNotifications()),
    updateNotification: (id) => dispatch(updateNotificationSeen(id))
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsFull));
