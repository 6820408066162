import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { browserHistory } from 'react-router'
import { fetchEventsNearUser } from "./events";



const initialState = {
    first_name: "",
    last_name: "",
    image: "",
    image_url: "",
    profile: null,
    username: "",
    user_id: null,
    email: '',
    location: 0,
    profile_id: null,
    is_staff: false,
    error: null,
    isLoading: false,
    about_you: "",
    phone: "",
    notifications: [],
    send_mails: false,
    tmpPlz: false
};

const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profileFetchLoading: (profile) => {
            profile.isLoading = true
        },
        profileFetchSuccess: (profile, action) => {
            const p = action.payload['results'][0]
            profile.first_name = p.first_name,
                profile.last_name = p.last_name,
                profile.username = p.username,
                profile.image = p.image,
                profile.image_url = p.image_url,
                profile.email = p.email,
                profile.location = p.current_position,
                profile.profile_id = p.id,
                profile.is_staff = p.is_staff,
                profile.user_id = p.user,
                profile.isLoading = false,
                profile.public_profile = p.public_profile
                profile.phone = p.phone
                profile.about_you = p.about_you,
                profile.send_mails = p.send_mails

        },
        profileFetchFail: (profile, action) => {
            profile.isLoading = false
            profile.error = action.payload.error
        },
        profileUpdateSuccess: (profile, action) => {
            const p = action.payload
            profile.first_name = p.first_name,
                profile.last_name = p.last_name,
                profile.username = p.username,
                profile.image = p.image,
                profile.image_url = p.image_url,
                profile.email = p.email,
                profile.location = p.current_position,
                profile.profile_id = p.id,
                profile.is_staff = p.is_staff,
                profile.user_id = p.user,
                profile.public_profile = p.public_profile,
                profile.phone = p.phone
                profile.about_you = p.about_you
                profile.send_mails = p.send_mails
                toast.success('👍 Dein Profil wurde aktualisiert.');
        },
        profileLogout: state => initialState,
        profilePublicUpdateSuccess: (profile) => {
            profile.public_profile = true
            toast.success('👍 Dein Profil wurde aktualisiert.')
        },
        notificationFetchSuccess: (profile, action) => {
            profile.notifications = action.payload
        },
        profileTmpPlz: (profile, action) => {
            profile.tmpPlz = action.payload;
            toast.success("👍 PLZ aktualisiert");
        }
    }
});

export const { profileFetchLoading, profileFetchSuccess, profileFetchFail, 
    profileUpdateSuccess, profileLogout, profilePublicUpdateSuccess,
    notificationFetchSuccess, profileTmpPlz 
} = slice.actions
export default slice.reducer;

export const fetchProfile = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/profiles",
                auth: true,
                onSuccess: profileFetchSuccess.type,
                onLoading: profileFetchLoading.type
            }
        })

    };
};

export const updateProfile = (profile, id, current_plz) => {
    const { email, first_name, last_name, location, image, public_profile, phone, about_you, send_mails } = profile
    let checkbox_checked = false;
    if(public_profile === true) checkbox_checked = true;
    if(public_profile === undefined) checkbox_checked = undefined;
    let send_mail_check = false;
    if(send_mails === true) send_mail_check = true;
    if(send_mails === undefined) send_mail_check = undefined;

    var use_fetch_plz = current_plz;
    if(location) use_fetch_plz = location
    
    return dispatch => {
        const fd = new FormData();
        fd.append('email', email);
        fd.append('first_name', first_name);
        fd.append('last_name', last_name);
        fd.append('public_profile', checkbox_checked);
        fd.append('send_mails', send_mail_check);
        if(email !== undefined) fd.append('current_position', location);
        if (typeof image !== 'string' && image !== undefined) fd.append('image', image);
        fd.append('phone', phone);
        fd.append('about_you', about_you)
        dispatch({
            type: "apiCall", payload: {
                url: "/profiles/" + id,
                method: "PATCH",
                auth: true,
                data: fd,
                onSuccess: profileUpdateSuccess.type,
                onSuccessAction: ()=>fetchEventsNearUser(true, use_fetch_plz)
            }
        })
    };
};

export const updatePublicProfile = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/public_profile",
                method: "POST",
                auth: true,
                onSuccess: profilePublicUpdateSuccess.type,
            }
        })
    }
}

export const fetchNotifications = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/notifications",
                method: "GET",
                auth: true,
                onSuccess: notificationFetchSuccess.type,
            }
        })
    }
}

export const updateNotificationSeen = (id) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/notifications",
                method: "POST",
                auth: true,
                data: {id},
                onSuccess: notificationFetchSuccess.type,
                target: id,
            }
        })
    }
}

export const setTmpPlz = (plz) => {
    return dispatch => {
        dispatch({
            type: profileTmpPlz.type, payload:plz
        })
    }
}

