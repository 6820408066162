import React from "react";
import ProjectCard from "../components/projects/ProjectCard";
import LongProjectCard from "../components/projects/LongProjectCard";
import style from "../style/suche.module.css";
import projectsStyle from "../style/projects.module.css";
//import algoliasearch from 'algoliasearch/lite';
import {BarsOutlined,AppstoreOutlined} from "@ant-design/icons";
import algoliasearch from 'algoliasearch';

import Geocode from "react-geocode";

import { connect } from "react-redux";
import ProjectsGoal from "../components/projects/ProjectsGoal";
import { fetchSubProjects } from "../store/projects";


import { Input } from "antd";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import LoadingCard from "../components/projects/LoadingCard";


class Search extends React.Component {
  constructor(props) {
    super(props)

    this.client = algoliasearch(
      '5WACQWVB9Q',
      '64e692955e069fb6c318665fe93c4552'
    );
    this.index = this.client.initIndex(
      process.env.REACT_APP_STAGE+'_Project'
      //'staging_Project'
      );
    this.state= {
      tileview: true,
      address: '',
      cords: null,
      projects: [],
      projects: []
    }
    this.index.setSettings({
      searchableAttributes: [
        'name',  
      ],
    });
    this.timeout =  0;
  }

  handleChange = address => {
    this.setState({ address: address.target.value, cords: null });
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let a = []
      Geocode.fromAddress(address.target.value).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          const search_cords = {
            aroundLatLng: lat+', '+lng,
            aroundRadius: 30000 // 30km
          }
          this.index.search(address.target.value, { hitsPerPage: 90}).then(({ hits }) => {
            hits.map(hit => {
              if(a.some(project => project.id === hit.id)){
              } else{
                a.push(hit)
                this.setState({projects: a})
              }
            })
          }); 
          this.index.search('', search_cords).then(({ hits }) => {
            hits.map(hit => {
              if(a.some(project => project.id === hit.id)){
              } else{
                a.push(hit)
                this.setState({projects: a})
              }
              
            });
          });
        },
        (error) => {
          this.index.search(address.target.value, { hitsPerPage: 90}).then(({ hits }) => {
            hits.map(hit => {
              a.push(hit)
              this.setState({projects: a})
            })
          })
        }
      );
        this.setState({projects: a})
    }, 300);

      
  };

  componentDidMount() {
    if (this.props.authenticated) this.props.getSubProjects();
    this.index.search('', { hitsPerPage: 90}).then(({ hits }) => {
      this.setState({projects: hits})
    });
  }
  render() {
    const { authenticated, subProjects, isLoading, error } = this.props
        if (error) {
            return "";
        }
    return (
        <div className={style.Suche}>
            <MetaTags>
              <title>Suche - TreePlantingProjects</title>
              <meta name="description" content="Aktuellen Projekt" />
              <meta property="og:title" content="Suche Projekte auf TreePlantingProjects" />
              <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
            </MetaTags>
            <div className={projectsStyle.Projects}>
                    {authenticated &&  Object.keys(subProjects).length !== 0 ? (
                        <div className={projectsStyle.yourProject} style={{marginBottom: "1em"}}>
                            <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-between mb-3">
                                <h1 className="mr-3 mb-3 mb-sm-0">Deine Projekte</h1>
                                {/* <Link to={"/search"}>
                                    <button className="primaryBtn mr-2" style={{ padding: "1.2em 3.1em" }}>
                                    <img src="/images/searchIcon.svg" className="mr-2" />
                              Projekte 
                            </button>

                                </Link> */}
                            </div>
                            <div
                                className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"                                
                                style={{ marginLeft: "-1em" }}
                                >
                                {Object.keys(subProjects).map((id => (
                                    <Link
                                        key={"sub"+id} to={`/projects/${subProjects[id].title_slug}`}
                                    >
                                        <ProjectCard key={"pCard"+id} data={subProjects[id]} />
                                    </Link>
                                )))
                                }
                            </div>
                        </div>
                    ) : (
                        <ProjectsGoal authenticated={authenticated} />
                    )}
                </div>
          <div
            className={`${style.header} `}
          >
            <div style={{display:'flex',flexDirection:"column",width:"300px"}}>
              <form noValidate action="" role="search" onSubmit={onSubmit} style={{ display: "contents" }}>
                <Input
                  placeholder="Suche nach Projekten"
                  style={{ backgroundColor: "#ffffff" }}
                  className={`mb-2 mb-md-0`}
                  onChange={this.handleChange}
                  prefix={
                    <img
                      src="/images/searchIconGrey.svg"
                      alt=""
                      style={{ width: "1.275625em" }}
                    />
                  }
                />
              </form>
              </div>
              <div className={style.switch_buttons}>
                <BarsOutlined onClick={()=>this.setState({tileview:false})} style={this.state.tileview ===true ?{color:"#C1C3C2"}: {color:"#000000"}} />
                <AppstoreOutlined onClick={()=>this.setState({tileview:true})}  style={this.state.tileview ===true ?{color:"#000000"}: {color:"#C1C3C2"}} />
              </div>
          </div>
          <h1>Projekte</h1>
          {this.state.tileview && <ProjectCards hits={this.state.projects}/>}
          {!this.state.tileview &&  <LongProjectCards hits={this.state.projects}/>}
        </div>
    )
  }
}

const onSubmit = (event) => {
  event.preventDefault();

}

const ProjectCards = ({ hits }) => (
  <div
    className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"
    style={{ marginLeft: "-1em" }}
  >
    {!hits && Array.from(Array(10).keys()).map((key => (
      <LoadingCard key={key}/>
    )))}
    {hits.map(hit => (
      <div key={"l4"+hit.id} >
        <Link to={`/projects/${hit.title_slug}`} key={"link5"+hit.id} >
          <ProjectCard key={hit.id} data={{ ...hit, image: hit.image_url.split("?AWS")[0] }} />
        </Link>
      </div>
    ))}
  </div>
);

const LongProjectCards = ({ hits }) => (
  <div
    style={{ marginLeft: "-1em" }}
  >
    { hits.map(hit => (
      <Link to={`/projects/${hit.title_slug}`} key={hit.id} >
        <LongProjectCard key={"long"+hit.objectID} data={{ ...hit, image: hit.image_url.split("?AWS")[0] }}/>
      </Link>
    ))}
  </div>
);
const mapStateToProps = state => {
  return {
      authenticated: state.auth.token !== null,
      subProjects: state.projects.subscribed,
      subIsLoaded: state.projects.subIsLoaded,
      isLoading: state.projects.isLoading,
      error: state.projects.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
      getSubProjects: () => dispatch(fetchSubProjects())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
