import React from "react";
import ProjectMembersCard from "../projects/ProjectMembersCard";
import { motion } from "framer-motion";
import SmallCardL from "../SmallCardL";

export default function TeamMembers({members, user}) {
  return (
    members.map((member => (
      <SmallCardL image={member} />
    )))
  );
}
