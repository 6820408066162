import { createSlice } from "@reduxjs/toolkit";
import {authFail, authStart, authSuccess} from "./auth";
import { toast } from 'react-toastify';
import { getAreasIndexById } from "./utility";


const slice = createSlice({
    name: 'areas',
    initialState: {
        isLoaded: false,
        error: null,
        list: [],
        mapMarkers: [],
        areasMarkerLoading: false,
        areaLoading: false,
        treeSpecies: [],
        treeSpeciesLoading: false,
        reportLoading: false,
        reportError: null
    },
    reducers: {
        areasFetchLoading: (areas, action) => {
            areas.isLoaded = false;
            areas.error = null;
        },
        areasFetchSuccess: (areas, action) => {
            areas.list = action.payload.results
            areas.isLoaded = true
        },
        areasFetchFail: (areas, action) => {
            areas.isLoaded = true;
            areas.error = action.payload;
        },
        areasLogout: (areas) => {
            areas.list = [];
            areas.reportError = null;
        },
        reportAreaSuccess: (areas, action) => {
            // areas.list.push(action.payload)
            areas.reportLoading = false;
            toast.success("👍 Fläche gespeichert.")
        },
        reportAreaLoading: (areas) => {
            areas.reportLoading = true;
            areas.reportError = null;
        },
        reportAreaFail: (areas, action) => {
            areas.reportError = action.payload;
            areas.reportLoading = false;
        },
        editAreaSuccess: (areas, action) => {
            // areas.list[getAreasIndexById(areas.list, action.payload.id)] = action.payload
            toast.success("👍 Fläche aktualisiert.")
        },
        areasMarkerFetchLoading: (areas) => {
            areas.mapMarkersLoading = true;
        },
        areasMarkerFetchSuccess: (areas, action) => {
            areas.mapMarkers = action.payload.results;
            areas.mapMarkersLoading = false;
        },
        areasMarkerFetchFail: (areas) => {
            areas.mapMarkersLoading = false;
        },
        areaFetchLoading: (areas) => {
            areas.areaLoading = true;
        },
        areaFetchSuccess: (areas, action) => {
            areas.list.push(action.payload)
            areas.areaLoading = false;
        },
        areaFetchFail: (areas) => {
            areas.areaLoading = false;
        },
        treeSpeciesFetchLoading: (areas) => {
            areas.treeSpeciesLoading = true;
        },
        treeSpeciesFetchSuccess: (areas, action) => {
            areas.treeSpecies = action.payload.results;
            areas.treeSpeciesLoading = false;
        },
        treeSpeciesFetchFail: (areas) => {
            areas.treeSpeciesLoading = false;
        }
    }
});

export const { areasFetchLoading, areasFetchSuccess, areasFetchFail, 
    areasLogout, reportAreaSuccess, editAreaSuccess,reportAreaLoading, reportAreaFail,
    areasMarkerFetchLoading, areasMarkerFetchSuccess, areasMarkerFetchFail,
    treeSpeciesFetchLoading, treeSpeciesFetchSuccess, treeSpeciesFetchFail,
    areaFetchLoading, areaFetchSuccess, areaFetchFail
    } = slice.actions
export default slice.reducer;


// Actions
export const fetchAreas = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/areas",
                method: "GET",
                auth: true,
                onLoading: areasFetchLoading.type,
                onSuccess: areasFetchSuccess.type,
                onError: areasFetchFail.type
            }
        })
    };
};

export const uploadAreaImage = (area, image) => {
    if(!image) {
        return
    }
    const fd = new FormData()
    fd.append("image", image)
    return dispatch => {
        dispatch({
            type: "apiCall",
            payload: {
                url: "/area/"+area.toString()+'/image',
                method: "POST",
                auth: true,
                data: fd,
                onSuccessAction: fetchAreas,
            }
          }
        );
    }
}

export const reportArea = (location, size, link, image, description, locations) => {
  return dispatch => {
      let area = {
            name: location,
            description,
            locations: [],
            size: parseFloat(size.replace(',','.')),
            location_name: location
        }
        locations.map(location=>area["locations"].push([location["mark"], location["number"]]))
      dispatch({
          type: "apiCall",
          payload: {
              url: "/report-area",
              method: "POST",
              auth: true,
              data: area,
              onSuccess: reportAreaSuccess.type,
              onLoading: reportAreaLoading.type,
              onError: reportAreaFail.type,
              onSuccessAction: fetchAreas,
              onSuccessKeyAction: {key: 'id', fn: uploadAreaImage, arg: image}
          }
        }
      );
  };
};

export const editArea = (id, location, size, link, image, description, owner) => {
    return dispatch => {
        const fd = new FormData();
          fd.append('name', location);
          fd.append('description', description);
          fd.append('location_link', link);
          fd.append('location_name', location);
          fd.append('owner', parseInt(owner));
          fd.append('size', size);
          //fd.append('uuid', id);
        dispatch({
            type: "apiCall",
            payload: {
                url: "/areas/"+id,
                method: "PATCH",
                auth: true,
                data: fd,
                onSuccess: editAreaSuccess.type,
                onSuccessAction: fetchAreas,
                onSuccessKeyAction: {key: 'id', fn: uploadAreaImage, arg: image}
            }
          }
        );
    };
  };


export const fetchMapMarkers = () => {
    return dispatch => {
        dispatch({
            type: "apiCall",
            payload: {
                url: "/areas-marker",
                method: "GET",
                onLoading: areasMarkerFetchLoading.type,
                onSuccess: areasMarkerFetchSuccess.type,
                onError: areasMarkerFetchFail.type
            }
        })
    }
}


export const fetchArea = uuid => {
    return dispatch => {
        dispatch({
            type: "apiCall",
            payload: {
                url: "/areas/"+uuid,
                method: "GET",
                onLoading: areaFetchLoading.type,
                onSuccess: areaFetchSuccess.type,
                onError: areaFetchFail.type
            }
        })
    }
}

export const fetchTreeSpecies = () => {
    return dispatch => {
        dispatch({
            type: "apiCall",
            payload: {
                url: "/tree-species",
                method: "GET",
                onLoading: treeSpeciesFetchLoading.type,
                onSuccess: treeSpeciesFetchSuccess.type,
                onError: treeSpeciesFetchFail.type
            }
        })
    }
}