import React from "react";
import style from "../../style/goal.module.css";
import { toast } from 'react-toastify';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { manageParticipationAdd, manageParticipationDelete, updateParticipants } from "../../store/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCube, faHandsHelping, faMinus, faPlus, faSpinner, faTimesCircle, faUsers } from "@fortawesome/pro-regular-svg-icons";
import LocationCard from "./LocationCard";
import { getEventFromList } from "../../store/utility";

class ProjectGoal extends React.Component {
  constructor(props) {
    super(props);
  };


  handleWhatsapp = () => {
    window.open('whatsapp://send?text=Kennst du schon ' + this.props.data.about.name + '? \n\nSchau mal hier: ' + window.location.href, "_blank");
  }

  handleFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, '_blank')
  }

  render() {
    const { id, header_image_url, description, title, current_participants, date, max_participants, participation, project_name, location, participant_images, additional_participants } = this.props.data;
    const { authenticated, project, locationData } = this.props;
    let lDate = new Date(date).toLocaleString('de-DE', {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
      hour: "numeric",
      minute: "numeric" 
    })
    return (
      
      <div className={style.goal}>
        <div className={`d-block  d-md-flex justify-content-between`} style={{ position: "relative" }}>
          <img src={header_image_url} alt="" className={style.coverBg} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          <div>
            <div className={style.contentSide}>
              <div className={style.contentHeader}>
                <div>
                <Link to={"/projects/"+project} >
                    <p className={style.grayBorder}><FontAwesomeIcon icon={faCube} className="fa-fw" style={{marginRight: "0.4em"}}/>
                    {project_name}
                    </p>
                  </Link>
                  <p className={style.grayBorder}>
                    <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" style={{marginRight: "0.4em"}}/>
                    {lDate} Uhr
                  </p>
                  <p className={style.grayBorder}>
                    <FontAwesomeIcon icon={faUsers} className="fa-fw" style={{marginRight: "0.4em"}}/>
                    {current_participants}/{max_participants}
                  </p>
                  {participant_images.length ? (
                    <p className={style.grayBorder}>
                    {participant_images.map((image => (
                      <img
                      className={style.smImage}
                      src={image}
                      alt=""
                      key={image}
                    />
                    )))}
                    {current_participants >= 3 ? (
                      <span className="ml-1">+ {current_participants-3}</span>
                    ):("")}
                  </p>
                  ):("")}
                 
                </div>
              </div>
              
              <h1 className={style.mainHeading}>
                {title}
              </h1>
              <div className={style.mainText}>
                {
                  description.split('\r\n').map(function (item, idx) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    )
                  })
                }
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "2em" }}
              >
                {authenticated ? (
                  this.props.participationLoading ? (
                    <button disabled className={`${style.grayBtn} mt-0`}>
                      <FontAwesomeIcon icon={faSpinner} className="fa-fw" />
                    </button> 
                  ) : (
                  participation ? (
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} >
                      <button className={`${style.grayBtn} mt-0`} onClick={() => this.props.handleCancellation(id, project)}>
                        <FontAwesomeIcon icon={faTimesCircle} className="fa-fw" />
                        Abmelden
                      </button>
                    </motion.div>
                  ) : (
                    current_participants == max_participants ? (
                      <button className={`${style.grayBtn} mt-0`} disabled>
                        <FontAwesomeIcon icon={faTimesCircle} className="fa-fw" />
                        Alle Plätze belegt
                      </button>
                    ):(
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }}>
                      <button className={`${style.greenBtn} mt-0`} onClick={() => this.props.handleParticipation(id, project)}>
                        <FontAwesomeIcon icon={faHandsHelping} className="fa-fw" />
                        Zur Aktion anmelden
                      </button>
                    </motion.div>
                  )))
                ) : (
                  <div>
                    <Link to={`/login?redirectTo=${window.location.pathname}`} className={`${style.greenBtn} mt-0`}>
                    <FontAwesomeIcon icon={faHandsHelping} className="fa-fw" />
                      Zur Aktion anmelden
                    </Link>
                  </div>
                )
                }
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center mr-3">

                  </div>
                  <div className="d-flex align-items-center">
                    <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                      <CopyToClipboard text={window.location.href}>
                        <button className={style.externalLink} onClick={()=>{toast.success('👍 Link in die Zwischenablage kopiert.')}}>
                          <img
                            src="/images/link.svg"
                            alt=""
                            className="img-fluid"
                          />
                        </button>
                      </CopyToClipboard>
                    </motion.div>
                    <button className={style.externalLink} onClick={this.handleWhatsapp}>
                      <img
                        src="/images/whatsapp-brands.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                    <button className={style.externalLink} onClick={this.handleFacebook}>
                      <img
                        src="/images/facebook-f-brands.svg"
                        alt=""
                        className="img-fluid"
                      />
                    </button>
                  </div>

                </div>
                {/*<Progress*/}
                {/*  percent={progress}*/}
                {/*  style={{*/}
                {/*    width: "50%",*/}
                {/*    marginLeft: "1.5em",*/}
                {/*    display: "flex",*/}
                {/*    alignItems: "center",*/}
                {/*    showInfo: false*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
              {participation ? (
                <>
                  {additional_participants == 0 && (
                    <div className="">
                      <p className="mr-2">Aktuell nur du. Kommt noch wer mit?</p>
                    </div>
                )}
                {additional_participants > 0 && (
                  <div className="">
                      <p className="mr-2">Aktuell Du  <strong>+{additional_participants}</strong> weitere Teilnehmer</p>
                    </div>
                )}
                    <div className="d-flex">
                      {!additional_participants == 0 && (
                      <p className="mr-2 cursor-pointer" onClick={()=>this.props.updateParticipants(id, additional_participants-1, project)}><FontAwesomeIcon icon={faMinus}/> Person entfernen</p>
                      )}
                      {current_participants <= max_participants && (
                        <p className="mr-2 cursor-pointer" onClick={()=>this.props.updateParticipants(id, additional_participants+1, project)}><FontAwesomeIcon icon={faPlus}/> Person Hinzufügen</p>
                      )}
                    </div>

                  </>
                )
              :''}
            </div>
          </div>
          <div className={style.coverImage}>
            <img
              className=""
              src={header_image_url}
              alt=""
              style={{ width: "50em", height: "30em", objectFit: "cover" }}
            />
          </div>
          {locationData && <LocationCard location={locationData[id]} />}
          
        </div >
        
      </div >
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    participationLoading: getEventFromList(state.events.list, ownProps.match.params.eventId).participationLoading,
    updateAdditionalParticipantsLoading: state.events.updateAdditionalParticipantsLoading

  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleParticipation: (eventId, project) => dispatch(manageParticipationAdd(eventId, project)),
    handleCancellation: (eventId, project) => dispatch(manageParticipationDelete(eventId, project)),
    updateParticipants: (eventId, total, project)=>dispatch(updateParticipants(eventId, total, project))
  };
};

export default withRouter(
  connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectGoal));
