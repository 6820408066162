import React from "react";
import style from "../../style/createArea.module.css";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { fetchProjectSettings, fetchProjectDetails, addProjectPost, updateProjectPost, deleteProjectPost } from "../../store/projects"
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faImage, faPencilRuler, faPlus, faSave, faTrash, faUpload } from "@fortawesome/pro-regular-svg-icons"; 
import NewsCard from "../NewsCard";
import ButtonCard from "../ButtonCard";
import { faFileUpload } from "@fortawesome/pro-duotone-svg-icons";
import { getProjectIdBySlug } from "../../store/utility";



class ProjectModerate extends React.Component {

  constructor(props) {
    super(props);
    this.inputReference = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
        title: "",
        image: "",
        body: "",
    }
    this.project_name = this.props.match.params.projectName;

  }


  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    this.setState({ image: e.target.files[0] });
  }

  handleChange = e => {
    let k = e.target.value
    if(e.target.checked) k = e.target.checked
    this.setState({ [e.target.name]: k });
  };

  handleSubmit = e => {
    e.preventDefault();
    if(this.state.update === 0 || this.state.update) {
      this.props.updateProjectPost(this.props.project, this.state)
    }
    else {
      this.props.addProjectPost(this.props.project, this.state)
    }
    this.handleClearSelect();
  };

  componentDidMount() {
    this.props.getProjectDetails(this.project_name, this.props.token, false)
    
  }

  handleSelectPost = id => {
    this.setState({
      update: id,
      title: this.props.news[id].title,
      image: "",
      body: this.props.news[id].body,
      id: this.props.news[id].id
    })
    
  }

  handleClearSelect = () => {
    this.setState({
      update: false,
      title: "",
      image: "",
      body: "",
      fileUploadState: ""
    })
    window.scrollTo(0, 0)
  }
  
  handleDelete = () => {
    this.props.deleteProjectPost(this.props.project, this.state);
    this.handleClearSelect();
  }

  disabledButton = () => {
    if(this.state.title && this.state.image && this.state.body && !this.props.postLoading) return true;
    return false
  }

  render() {
    const {token, loaded, newsLoaded, projectDetails, news, about} = this.props;
    if (!token) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    if(!loaded || !newsLoaded) {
      return ""
    }
    const projectName = about.name;
    return (
        <div className={style.form}>
          <ReactTitle title={projectName+" Projekteinstellungen - TreePlantingProjects"} />
          <div className={`row d-flex vh-100`}>
            <div className="col-md-6 py-4 pr-5 pl-5">
            <p onClick={()=>this.props.history.push(`/projects/${about.title_slug}`)} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={`${style.mainHeading} mt-0`}>{this.state.update === 0 || this.state.update ? ("Post bearbeiten"):("Neuen Post erstellen")}</h2>
              <div className={style.mainText}>oder wähle einen aus der Liste rechts.</div>
              <form className="mt-3" action="" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={style.formInput}>
                        <input
                          className={style.commonInput}
                          type="text"
                          fluid="left"
                          name="title"
                          placeholder="z.B. 1.000 neue Bäume gepflanzt"
                          required
                          onChange={this.handleChange}
                          value={this.state.title}
                        />
                        <FontAwesomeIcon icon={faPencilRuler} className={style.inputIcon} />
                      </div>
                      <div className={style.formInput}>
                      <input
                          readOnly="readOnly"
                          className={style.commonInput}
                          type="text"
                          required
                          placeholder={this.state.fileUploadState ? (this.state.fileUploadState.replace("C:\\fakepath\\", "")) : ("Bild hochladen")}
                          onClick={this.fileUploadAction}
                        />
                        <input
                          type="file"
                          style={{ display: "none" }}
                          name="image"
                          hidden
                          accept="image/*"
                          data-type='image'
                          ref={this.inputReference}
                          onChange={this.fileUploadInputChange}
                        />
                        <FontAwesomeIcon icon={faImage} className={style.inputIcon} />
                        <p className={style.required}>{!this.state.fileUploadState ? ("Du musst ein Bild hochladen."):("")}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className={style.formInput}>
                        <textarea
                          className={style.commonInput}
                          name="body"
                          cols="30"
                          rows="7"
                          required
                          onChange={this.handleChange}
                          value={this.state.body}
                        ></textarea>
                      </div>
                    </div>
                </div>
                <div>
                  {this.state.update === 0 || this.state.update ? (
                    <div className="row">
                    <button type="submit" value="update" style={{padding: "1.3em 2em"}} className={style.formBtn}>
                    <FontAwesomeIcon icon={faUpload} className="fa-fw" />
                    Post aktualisieren</button>
                    <button type="button" onClick={this.handleDelete} value="delete" style={{marginLeft: "1em", backgroundColor: "#E65D5D", padding: "1.3em 1em"}}className={style.formBtn}>
                      <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                      Post löschen</button>
                    </div>
                  ):(
                    <button type="submit" disabled={!this.disabledButton()} value="create" onclick="this.form.submitted=this.value;" style={{padding: "1.3em 2em"}} className={style.formBtn}>
                      <FontAwesomeIcon icon={faUpload} className="fa-fw" />
                      Post veröffentlichen</button>
                  )}
                  {/* <button className={style.formBtn}>Post löschen</button> */}
                </div>
              </form>
        
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <h2 className={`${style.mainHeading} mt-5`}>Post auswählen</h2>
              <div className={style.mainText}></div>
              {this.state.update === 0 || this.state.update ? (
                <button 
                  style={{marginBottom: "1em"}}
                  className={style.formBtn}
                  onClick={this.handleClearSelect}
                >
                  <FontAwesomeIcon icon={faPlus} className="fa-fw" />
                  Neuer post
                </button>
                ):("")}
              {news.map((newa, key)=> (
                <div onClick={()=>(this.handleSelectPost(key))} style={{marginLeft: "-1em", cursor: "pointer"}}>
                  <NewsCard
                    key={newa.id}
                    selected={this.state.update === key}
                    news={{ 
                      title: newa.title, 
                      description: newa.body, 
                      date: newa.date, 
                      image: newa.image,
                      messages: newa.messages
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    loaded: state.projects.detailsLoaded,
    newsLoaded: state.projects.newsLoaded,
    project: id,
    projectDetails: state.projects.details,
    about: state.projects.about[id],
    postLoading: state.projects.newsActionLoading,
    news: state.projects.news[id] || [],
    error: state.auth.error,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProjectSettings: id => dispatch(fetchProjectSettings(id)),
    addProjectPost: (id, post) => dispatch(addProjectPost(id, post)),
    updateProjectPost: (id, post) => dispatch(updateProjectPost(id, post)),
    getProjectDetails: (projectId, auth, fetched) =>
      dispatch(fetchProjectDetails(projectId, auth, fetched)),
    deleteProjectPost: (id, post) => dispatch(deleteProjectPost(id, post))
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectModerate));
