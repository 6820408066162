import { createSlice } from "@reduxjs/toolkit";


const slice = createSlice({
    name: 'blog',
    initialState: {
        loaded: false,
        error: null,
        list: []
    },
    reducers: {
        blogFetchLoading: (blog, action) => {
            blog.loaded = false;
            blog.error = null;
        },
        blogFetchSuccess: (blog, action) => {
            blog.list = action.payload.results
            blog.loaded = true;
        },
        blogFetchFail: (blog, action) => {
            blog.loaded = true;
            blog.error = action.payload;
        }
    }
});

export const { blogFetchLoading, blogFetchSuccess, blogFetchFail } = slice.actions
export default slice.reducer;


// Actions
export const fetchBlog = (limit) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/blog/?limit=" + limit,
                method: "GET",
                onLoading: blogFetchLoading.type,
                onSuccess: blogFetchSuccess.type,
                onError: blogFetchFail.type
            }
        })
    };
};

export const fetchBlogMore = (limit) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/blog/?limit=" + limit,
                method: "GET",
                onSuccess: blogFetchSuccess.type,
                onLoading: blogFetchLoading.type,
                onError: blogFetchFail.type
            }
        })
    };
};
