import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { store } from "../index";

const slice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    error: null,
    loading: false,
    expirationDate: null,
    started: false,
    resetPasswordStarted: false
  },
  reducers: {
    authStart: (auth) => {
      auth.loading = true;
    },
    authSuccess: (auth, action) => {
      auth.token = action.payload.token;
      auth.error = null;
      auth.loading = false;
      auth.expirationDate = new Date(new Date().getTime() + 3600 * 1000);
      toast.success("👍 Erfolgreich angemeldet.")
    },
    authVerifySuccess: (auth, action) => {
      auth.started = false
      toast.success("👍 Erfolgreich verifiziert.")
    },
    authFail: (auth, action) => {
      auth.error = action.payload.error;
      auth.loading = false;
      // toast.error("💥 Ein Fehler ist aufgetreten. " + action.payload.error)
    },
    authLogout: (auth) => {
      auth.token = null;
      toast.success("👋 Erfolgreich abgemeldet. Bis bald.")
    },
    authSignupSuccess: (auth) => {
      auth.started = true
      toast.warning("Bitte bestätige deine E-Mail.")
    },
    authSocialSuccess: (auth, action) => {
      auth.token = action.payload.token;
      auth.error = null;
      auth.loading = false;
      auth.expirationDate = new Date(new Date().getTime() + 3600 * 1000);
      toast.success("👍 Erfolgreich angemeldet.")
    },
    authResetPasswordStart: (auth) => {
      auth.resetPasswordStarted = true
      toast.warning("Bitte überprüfe deine E-Mails.")
    },
    authResetPasswordConfirm: (auth) => {
      auth.resetPasswordStarted = false
      toast.success("👍 Neues Passwort gesetzt.")
    },
  }
});

export const { authStart, authSuccess, authFail, authLogout,
  authSignupSuccess, authVerifySuccess, authSocialSuccess,
  authResetPasswordStart, authResetPasswordConfirm } = slice.actions
export default slice.reducer;

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(authLogout());
    }, expirationTime * 1000);
  };
};

export const authLogin = (username, password) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/api-token-auth/",
        method: "POST",
        data: {
          username: username,
          password: password
        },
        onLoading: authStart.type,
        onSuccess: authSuccess.type,
        onError: authFail.type
      }
    }
    );
  };
};

export const googleSocialLogin = (access_token) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/rest-auth/google/",
        method: "POST",
        data: {
          access_token
        },
        onLoading: authStart.type,
        onSuccess: authSocialSuccess.type,
        onError: authFail.type
      }
    }
    );
  };
};

export const facebookSocialLogin = (access_token) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/rest-auth/facebook/",
        method: "POST",
        data: {
          access_token
        },
        onLoading: authStart.type,
        onSuccess: authSocialSuccess.type,
        onError: authFail.type
      }
    }
    );
  };
};

export const emailVerify = (uid, token) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/auth/users/activation/",
        method: "POST",
        data: {
          uid,
          token
        },
        onSuccess: authVerifySuccess.type,
      }
    }
    );
  };
};

export const authSignup = (email, password) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/auth/users/",
        method: "POST",
        data: {
          username: email,
          email: email,
          password: password,
          re_password: password
        },
        onLoading: authStart.type,
        onSuccess: authSignupSuccess.type,
        onError: authFail.type
      }
    }
    );
  };
};

export const resetPassword = (email) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/auth/users/reset_password/",
        method: "POST",
        data: {
          email
        },
        onSuccess: authResetPasswordStart.type,
      }
    }
    );
  };
};

export const resetPasswordConfirm = (id, token, new_password) => {
  return dispatch => {
    dispatch({
      type: "apiCall",
      payload: {
        url: "/auth/users/reset_password_confirm/",
        method: "POST",
        data: {
          new_password,
          uid: id,
          token: token
        },
        onSuccess: authResetPasswordConfirm.type,
      }
    }
    );
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = store.getState().auth.token
    if (token === undefined) {
      dispatch(auth.logout.type);
    } else {
      const expirationDate = new Date(store.getState().auth.expirationDate);
      if (expirationDate <= new Date()) {
        dispatch(authlogout.type);
      } else {
        dispatch(authSuccess({ auth_token: token }));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
