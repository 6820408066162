import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import MaintenanceApp from './MaintenanceApp';
import './style/fonts/BerlinSansFBRegular2.ttf';
import './style/fonts/BerlinSansFBDemiBold.ttf';
import reportWebVitals from './reportWebVitals';
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import api from "./store/middleware/api";
import cleanup from "./store/middleware/cleanup";

import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage' 
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import crossBrowserListener from './utils/cross-browser-middleware'
import { hotjar } from 'react-hotjar';

import versionReducer from "./store/version";
import authReducer from "./store/auth";
import profileReducer from "./store/profile";
import projectsReducer, { fetchProjectsMapping } from "./store/projects";
import areasReducer from "./store/areas";
import eventsReducer from "./store/events";
import adminReducer from "./store/admin";
import teamsReducer from "./store/teams";

import { fetchVersion, versionCheckState } from "./store/version";
import blogReducer from "./store/blog";
import { checkAuthTimeout } from "./store/auth";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from 'react-ga';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { WindMillLoading } from 'react-loadingg';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyDLDisKeHL0ukmo5tYLq-LYcPKhMzcTmWY");
Geocode.setLanguage("de");

Geocode.setRegion("de");

Sentry.init({
  dsn: "https://b30dd8fc1a8b4697a21354947d3c453d@o341275.ingest.sentry.io/5716582",
  integrations: [new Integrations.BrowserTracing()],
});
const hjid = "2341798"

const persistConfig = {
  key: 'tppv1_3_1lmvkl_',
  storage,
}


const rootReducer = combineReducers({
  version: versionReducer,
  auth: authReducer,
  profile: profileReducer,
  projects: projectsReducer,
  areas: areasReducer,
  blog: blogReducer,
  events: eventsReducer,
  admin: adminReducer,
  teams: teamsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [api, cleanup, thunk]
});

window.addEventListener('storage', crossBrowserListener(store, persistConfig));
const verionSubscriber = store.subscribe(() => {
  if(store.getState().version.name) {
    store.dispatch(versionCheckState())
    verionSubscriber();
  }
});

store.dispatch(fetchVersion())
store.dispatch(fetchProjectsMapping())

const authSubscriber = store.subscribe(() => {
  if(store.getState().auth.token) {
    store.dispatch(checkAuthTimeout(3600));
    authSubscriber();
  }
});


if(process.env.REACT_APP_STAGE != "development") hotjar.initialize(hjid);
if(process.env.REACT_APP_STAGE == "production") {
  ReactGA.initialize('UA-143829144-2');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const mappingSubscriber = store.subscribe(() => {
  if(store.getState().projects.mapping.length || !store.getState().version.error) {
    mappingSubscriber()
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <HelmetProvider>
          <PersistGate loading={null} persistor={persistStore((store))}>
            <App />
          </PersistGate>
          </HelmetProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );  
  }
  else {
    ReactDOM.render(
      <WindMillLoading />,
      document.getElementById('root')
    );  
  }
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
