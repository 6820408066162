import events from "./events";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};


export const getProjectIdBySlug = (projectSlug, projectsMapping) => {
  return projectsMapping.filter(
    (project) => {
        return project.title_slug === projectSlug}
    )
}

export const getEventFromList = (events, eventId) => {
  return events.filter(
    (event) => {
      return event.id === eventId
    }
  )[0]
}

export const getEventIndexFromList = (events, eventId) => {
  return events.findIndex(x => x.id === eventId);
}

export const getProjectSlugById = (projectId, projectsMapping) => {
  return projectsMapping.filter(x => x.id === projectId)[0].title_slug;
}

export const getEventsByProjectId = (events, projectId) => {
  return events.filter(
    (event) => {
      return event.project === projectId
    }
  )
}

export const getAreasIndexById = (areas, id) => {
  return areas.findIndex(x => x.id === id);
}

export const teamBySlug = (teams, slug) => {
  return teams.findIndex(x => x.slug === slug); 
}

export const getProjectIndexFromTeamList = (teamProjects, projectId) => {
  return events.findIndex(x => x.id === projectId);
}

export const convertDateTimeToDateAndTime = date => {
  return new Date(date).toLocaleString('de-DE', {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
    hour: "numeric",
    minute: "numeric" 
  })
}

export const validURL = e => {
  if(e == "") return true
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(e);
}