import React from "react";
import style from "../../style/goal.module.css";
import { Progress } from "antd";
import { toast } from 'react-toastify';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { manageParticipationAdd, manageParticipationDelete } from "../../store/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faCube, faHandsHelping, faPlus, faTimesCircle, faTreeAlt, faUserPlus, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { leaveTeam } from "../../store/teams";


class TeamGoal extends React.Component {
  constructor(props) {
    super(props);
  };


  handleWhatsapp = () => {
    window.open('whatsapp://send?text=Kennst du schon ' + this.props.data.about.name + '? \n\nSchau mal hier: ' + window.location.href, "_blank");
  }

  handleFacebook = () => {
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, '_blank')
  }

  render() {
    const { id, about, name, location, members_count, team_images, is_member, invite_token, slug, trees_to_plant, trees_planted, is_manager} = this.props.data;
    const { authenticated, project, locationData, organization } = this.props;
    const { image, logo} = team_images;

    return (
      
      <div className={style.goal}>
        <div className={`d-block  d-md-flex justify-content-between`} style={{ position: "relative" }}>
          {image && <img src={image} alt="" className={style.coverBg} style={{ width: "100%", height: "100%", objectFit: "cover" }} />}
          <div>
            <div className={style.contentSide}>
              <div className={style.contentHeader}>
                <div>
                {/* <Link to={"/projects/"+project} >
                    <p className={style.grayBorder}><FontAwesomeIcon icon={faCube} className="fa-fw" style={{marginRight: "0.4em"}}/>
                    {name}
                    </p>
                  </Link> */}
                  <p className={style.grayBorder}>
                    <FontAwesomeIcon icon={faUsers} className="fa-fw" style={{marginRight: "0.4em"}}/>
                    {members_count}
                  </p>
                  <p className={style.grayBorder}>
                    <FontAwesomeIcon icon={faTreeAlt} className="fa-fw" style={{marginRight: "0.4em"}}/>
                    {trees_planted+"/"+trees_to_plant}
                  </p>
                  {/* {participant_images.length ? (
                    <p className={style.grayBorder}>
                    {participant_images.map((image => (
                      <img
                      className={style.smImage}
                      src={"http://localhost:8000"+image}
                      alt=""
                      key={image}
                    />
                    )))}
                    {current_participants >= 3 ? (
                      <span className="ml-1">+ {current_participants-3}</span>
                    ):("")}
                  </p>
                  ):("")} */}
                 
                </div>
              </div>
              <div className="row">
              {logo && 
                <div className="col-md-3">
                  <img src={logo} alt="" className={style.teamLogo}/>
                </div>
                }
                <div className="col-md-9">
                  <h1 className={style.mainHeading}>
                    {name}
                  </h1>
                  <div className={style.mainText}>
                    {
                      about.split('\r\n').map(function (item, idx) {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              {authenticated && is_member &&

              <div
                className="d-flex align-items-center"
                style={{ marginTop: "2em" }}
              >
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} >
                    <button className={`${style.greenBtn} mt-0`} onClick={()=>{this.props.history.push('/share?type=teamInvite&id='+slug)}}>
                      <FontAwesomeIcon icon={faUserPlus} className="fa-fw" />
                      Mitglieder einladen 
                    </button>
                </motion.div>

                {is_manager && !logo &&
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.9 }} className="ml-2" >
                  <button className={`${style.greenBtn} mt-0`} onClick={()=>{this.props.history.push("/teams/"+slug+"/manage")}}>
                    <FontAwesomeIcon icon={faPlus} className="fa-fw" />
                    Logo hinzufügen
                  </button>
                </motion.div>
                }
                
              </div>
              }

            </div>
          </div>
          <div className={style.coverImage}>
          {image &&
            <img
              className=""
              src={image}
              alt=""
              style={{ width: "50em", height: "100%", maxHeight: "40em", objectFit: "cover" }}
            />
            }
          </div>
          {/* <OrgaCard organization={organization} /> */}
          
        </div >
        
      </div >
    );
  }
}



const mapDispatchToProps = dispatch => {
  return {
    handleLeave: (teamSlug) => dispatch(leaveTeam(teamSlug)),
  };
};

export default withRouter(
  connect(
  null,
  mapDispatchToProps
)(TeamGoal));

let event = {
  title: 'Sample Event',
  description: 'This is the sample event provided as an example only',
  location: 'Portland, OR',
  startTime: '2016-09-16T20:15:00-04:00',
  endTime: '2016-09-16T21:45:00-04:00'
}
