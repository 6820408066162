import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Link, withRouter } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import style from "../../style/MobProjectGoal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash, faBell, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faCube, faHandsHelping, faPenAlt, faShareAll, faSpinner, faTimesCircle, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { manageParticipationAdd, manageParticipationDelete } from "../../store/events";
import { getEventFromList, getProjectIdBySlug } from "../../store/utility";


class MobEventGoal extends React.Component {
  constructor(props) {
    super(props);
  }

  handleShare(event) {
    if (navigator.share) {
      navigator.share({
        title: event.title,
        text: event.information,
        url: window.location.href
      })
      .then(() => console.log('Successful share'))
      .catch(error => console.log('Error sharing:', error));
    }
    else {
      this.props.history.push("/share?type=event&id="+event.id)
    }
  }

  render() {
    const { id, header_image_url, description, title, current_participants, date, max_participants, participation, is_organiser, project_name } = this.props.data;
    const { authenticated, project, event, showFullText } = this.props;
    let lDate = new Date(date).toLocaleDateString('de-DE', {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
      hour: "numeric",
      minute: "numeric" 
    })

    return (
      <div className={style.project_page} style={{height: "420px"}}>
        <div className={style.project_page_imgbox}>
          <img
            src={header_image_url}
            alt=""
          />
        </div>
        <div className={style.project_page_content}>
          <div className={style.top}>
            <div className={style.top_left}>
              <Link to={`/projects/${project}`}><div className={style.tile_location}><FontAwesomeIcon icon={faCube} className="fa-fw" style={{marginRight: "0.3em"}}/>{project_name}</div></Link>
              <div className={style.tile_location} style={{marginLeft: "0.5em"}}><FontAwesomeIcon icon={faCalendarAlt} className="fa-fw" style={{marginLeft: "0.3em"}}/>{lDate}</div>
              <div className={style.tile_location} style={{marginLeft: "0.5em"}}><FontAwesomeIcon icon={faUsers} className="fa-fw" style={{marginRight: "0.3em"}}/>{current_participants}/{max_participants}</div>
            </div>
          
          </div>
          <div className={style.middle}>
          </div>
          <div className={style.bottom}>
            <div className={style.project_textbox}>
              <h2 className={style.eproject_textbox_heading}>
              {title}
              </h2>
              {current_participants == max_participants ? (
                  <div><FontAwesomeIcon icon={faTimesCircle} /> Alle Plätze belegt</div>):("")
              }
              <p>
              {
                  description.split('\r\n').map(function (item, idx) {
                    return (
                      <span key={idx}>
                        {item}
                        <br />
                      </span>
                    )
                  })
                }
            
              </p>
            </div>
          </div>
          <div className={style.bottomButtons}>
              {authenticated ? (
                this.props.participationLoading ? (
                  <div className={style.subscribeBtn}>
                    <div><FontAwesomeIcon icon={faSpinner} /></div>
                  </div> 
                ) : (
                participation ? (
                  <div className={style.subscribeBtn}>
                    <div onClick={() => this.props.handleCancellation(id)}><FontAwesomeIcon icon={faTimesCircle} /> Abmelden</div>
                  </div>
                ):(
                  max_participants > current_participants && <div className={style.subscribeBtn}><div onClick={() => this.props.handleParticipation(id)}><FontAwesomeIcon icon={faHandsHelping} /> Zur Aktion anmelden</div></div>
                  ))
                ):(
                  <div className={style.subscribeBtn}>
                    <div onClick={() => this.props.history.push(`/login?redirectTo=${window.location.pathname}`)}><FontAwesomeIcon icon={faHandsHelping} /> Zur Aktion anmelden</div>
                  </div>
                )}
            <div className={`${style.subscribeBtn} ml-2`}>
                <div onClick={()=>this.handleShare(this.props.data)}><FontAwesomeIcon icon={faShareAll} /> Teilen</div>
              </div>
            {is_organiser ? (
              <div className={`${style.subscribeBtn}`}>
                <div onClick={() => this.props.history.push(`/projects/${project}/eventsmanage?selected=${id}`)}><FontAwesomeIcon icon={faPenAlt} /> Bearbeiten</div>
              </div>
            ):("")}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    participationLoading: getEventFromList(state.events.list, ownProps.match.params.eventId).participationLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleParticipation: (eventId) => dispatch(manageParticipationAdd(eventId)),
    handleCancellation: (eventId) => dispatch(manageParticipationDelete(eventId))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobEventGoal));
