import { createSlice } from "@reduxjs/toolkit";
import { version } from "react";
import { toast } from "react-toastify";


const slice = createSlice({
    name: 'version',
    initialState: {
        name: null,
        maintenance: false,
        r: '',
        u: '',
        progress: 0,
        error: false
    },
    reducers: {
        versionFetchSuccess: (version, action) => {
            version.name = action.payload.version
            version.error = false;
        },
        versionFetchFail: (version) => {
            version.error = true;
        },
        maintenanceStart: (version, action) => {
            version.maintenance = true
            version.r = action.payload.r
            version.u = action.payload.until
            version.progress = action.payload.progress
        },
        maintenanceStop: (version) => {
            version.maintenance = false
            version.r = false
            version.u = false
            version.progress = 0
        }
    }
});

export const { versionFetchSuccess, maintenanceStart, maintenanceStop, versionFetchFail } = slice.actions
export default slice.reducer;

// Actions
export const fetchVersion = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/version",
                method: "GET",
                onSuccess: versionFetchSuccess.type,
                onError: versionFetchFail.type
            }
        })
    };
};

export const checkVersionTimeout = versionExpirationTime => {

    return dispatch => {
        setTimeout(() => {
            dispatch(versionCheckState());
        }, versionExpirationTime * 1000);
    };
};

export const versionCheckState = () => {
    return dispatch => {
        const expirationDate = new Date(localStorage.getItem("versionExpirationDate"));
        if (expirationDate <= new Date()) {
            dispatch(fetchVersion());
            const versionExpirationDate = new Date(new Date().getTime() + 10 * 1000);
            localStorage.setItem("versionExpirationDate", versionExpirationDate);
            dispatch(
                checkVersionTimeout(
                    (expirationDate.getTime() - new Date().getTime()) / 1000
                )
            );
        } else {
            dispatch(
                checkVersionTimeout(
                    (expirationDate.getTime() - new Date().getTime()) / 1000
                )
            );
        }
    };
};

