import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import { AiOutlineClose } from "react-icons/ai";
import "../../App.css";
import style from "../../style/sidebar.module.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faCube, faBars, faUser, faBell, faArrowLeft } from "@fortawesome/pro-regular-svg-icons"; 
import {connect} from "react-redux";


function DashboardLayout({ children, authenticated, profile }) {
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  return (
    <div
      className="d-flex"
      style={{
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <Sidebar
        sidebarVisibility={sidebarVisibility}
        setSidebarVisibility={(item) => setSidebarVisibility(item)}
      />
      <div className="px-4 py-3 content" id="toScroll">
        {sidebarVisibility ? (
          <div
            style={{
              backgroundColor: "#262626",
              position: "fixed",
              inset: "0px",
              zIndex: "4",
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={() => setSidebarVisibility(false)}
              style={{
                backgroundColor: "#ffffff",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                marginRight: "2em",
                border: "none",
              }}
            >
              <AiOutlineClose />
            </button>
          </div>
        ) : (
          ""
        )}

        {children}
        <MobileMenu 
          sidebar={() => setSidebarVisibility(!sidebarVisibility)}
          authenticated={authenticated} 
          notifications={profile.notifications.length}
          />
      </div>
    </div>
  );
}

export const isActive = (item, isIncluded) => {
  if(isIncluded) {
    if(window.location.pathname.split('/')[1] == item) return "active"
    else return ""
  }
  if(window.location.pathname == item) {
    return "active"
  }
  else return ""
}

export const MobileMenu = ({layout, sidebar, authenticated, notifications, handleBack}) => (
  <div className={style.mobileMainMenu} style={{marginLeft: layout ? (""):("-21px")}}>
          <Link to="/" className={isActive("/")}>
            <FontAwesomeIcon icon={faHome} className="fa-fw" />
          </Link>
          {authenticated && 
          <>
          <Link to="/notifications" className={isActive("/notifications")}>
             {notifications ? (
                <span className={style.notificationsIndicatorMob}></span>
              ):("")}
            <FontAwesomeIcon icon={faBell} className="fa-fw" />
          </Link>
          
          <Link to="/settings" className={isActive("/settings")}>
            <FontAwesomeIcon icon={faUser} className="fa-fw" />
          </Link>
          </>
          }
          {!layout &&
          <a 
            onClick={sidebar}
          >
          <FontAwesomeIcon icon={faBars} className="fa-fw" />
        </a>
        }
        {layout && 
          <a
            onClick={handleBack}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
          </a>
        }
        </div>
)

const mapStateToProps = state => {
  return {
    profile: state.profile,
    authenticated: state.auth.token !== null
  };
};


export default connect(
    mapStateToProps,
    null
  )(DashboardLayout);
