import React, { useState } from "react";
import AreasGoal from "../components/areas/AreasGoal";
import style from "../style/projects.module.css";
import goalStyle from "../style/goal.module.css";
import { connect } from "react-redux";
import { fetchProjects, fetchSubProjects } from "../store/projects";
import { fetchAreas, fetchMapMarkers } from "../store/areas";
import { Link } from "react-router-dom";
import AreaCard from "../components/areas/AreaCard";
import MetaTags from 'react-meta-tags';
import gmapStyle from "../data/gmapStyle.json"
import { InfoWindow, withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

class Area extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markers: []
        }
    }

    componentDidMount() {
        if (this.props.authenticated) this.props.getAreas();
        this.props.fetchMapMarkers();
    }

    handleMarkerClick = targetMarker => {
        this.setState({
        markers: this.props.mapMarkers.map(marker => {
            if (marker === targetMarker) {
                return {
                    ...marker,
                    showInfo: true
                }
            }
            return marker
        })
    })
  }

    handleMarkerClose = targetMarker => {
        this.setState({
        markers: this.props.mapMarkers.map(marker => {
        if (marker === targetMarker) {
            return {
                ...marker,
                showInfo: false
            }
        }
        return marker
      })
    })
  }
    render() {
        const { authenticated, areas, isLoaded, mapMarkers, mapMarkersLoading } = this.props;
        if(!isLoaded && authenticated) return ""
        let markers = mapMarkers;
        if(this.state.markers.length) markers = this.state.markers;
        return (
            <div className={style.Projects}>
                <MetaTags>
                    <title>Flächen - TreePlantingProjects</title>
                    <meta name="description" content="Du besitzt eine Fläche, die Du für ein Klimawald-Projekt gerne zur Verfügung stellen möchtest? Hier kannst Du Deine Flächen ganz einfach registrieren und der lokalen Community für Pflanzprojekte anbieten." />
                    <meta property="og:title" content="Flächen bei TreePlantingProjects" />
                    <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
                </MetaTags>
                {authenticated && isLoaded  && areas.length ? (                    
                    <div className={style.yourProject}>
                        <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-between mb-3">
                            <h1 className="mr-3 mb-3 mb-sm-0">Deine Flächen</h1>
                            <Link to={"/report_area"}>
                                <button className="primaryBtn mr-2">
                                    <img src={`images/plus-square-Icon.svg`} alt="" className="mr-2" />{" "}
                              Fläche melden
                            </button>

                            </Link>
                        </div>
                        <div
                            className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"
                            style={{ marginLeft: "-1em" }}
                        >
                            {Object.keys(areas).map((id => (
                                
                                <div key={id}>
                                    {areas[id] ? (
                                     <Link
                                     key={id} to={`/areas/${id}`}
                                        >
                                            <AreaCard key={id} data={areas[id]} show_buttons={true}/>
                                        </Link>
                                    ):("")}
                                </div>
                            )))
                            }
                        </div>
                    </div>
                ) : (
                    <AreasGoal auth={authenticated}/>
                )}
                {!mapMarkersLoading && 
                 <AreaMap isMarkerShown={true}
                    markers={markers}
                    onMarkerClick={this.handleMarkerClick}
                    onMarkerClose={this.handleMarkerClose}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }}
                     />}
                />
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.auth.token !== null,
        areas: state.areas.list,
        mapMarkers: state.areas.mapMarkers,
        mapMarkersLoading: state.areas.mapMarkersLoading,
        isLoaded: state.areas.isLoaded,
        error: state.projects.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAreas: () => dispatch(fetchAreas()),
        fetchMapMarkers: () => dispatch(fetchMapMarkers())
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Area);


const AreaMap = withScriptjs(withGoogleMap(props =>
{
    let iconMarker = new window.google.maps.MarkerImage(
        "https://app.treeplantingprojects.com/images/logo.png",
        null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new window.google.maps.Size(32, 32)
    );
    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: 48.790447, lng: 11.497889 }}
            defaultOptions={{
                //styles: gmapStyle,
            }}
        >
            {props.markers.map((marker => (
                <Marker icon={iconMarker} key={marker.id} position={marker.coordinates} 
                    onClick={() => props.onMarkerClick(marker)}>
                        {marker.showInfo &&
                        <InfoWindow>
                            <div style={{'color':'black'}}>
                                {marker.name} - {marker.size} Hektar
                            </div>
                        </InfoWindow>}
                </Marker>
                )))}
        </GoogleMap>
    )
}))
