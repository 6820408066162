import React from "react";
import style from "../../style/createArea.module.css";
import {connect} from "react-redux";
import { withRouter, Redirect } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCopy, faHandsHelping, faInfo, faLink, faStop, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import QRCode from "qrcode.react"
import { fetchTeam, fetchTeamProjects, joinTeam, suggestProject } from "../../store/teams";
import { fetchProjects, fetchSubProjects } from "../../store/projects";
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch';
import PropTypes from 'prop-types';
import { Input } from "@material-ui/core";
import SmallCardL from "../SmallCardL";

class SuggestProject extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      suggestSuccess: false
    }

    this.teamSlug = this.props.match.params.teamSlug;
  }

  componentDidMount() {
     this.props.fetchTeam(this.props.authenticated, this.teamSlug);
     this.props.fetchProjects();
     this.props.fetchTeamProjects(this.teamSlug)
  }

  handleSuggest(project) {
    this.props.suggestProject(this.teamSlug, project);
    this.setState({suggestSuccess: true})
  }

  getFilteredProjects(subProjects, teamProjects) {
    var filteredProjects = {}
    Object.keys(subProjects).map(p=>{
      if(!teamProjects.filter(project=>{return project.project === subProjects[p].id})[0]){
        filteredProjects[subProjects[p].id] = subProjects[p]
      }
    })
    return filteredProjects
  }



  render() {
    const {error, loading, authenticated, team, suggestLoading, suggestProjectSuccess, subProjects, teamProjects} = this.props;
    if(!team) return ("loading")

    if(suggestProjectSuccess && this.state.suggestSuccess && team.is_manager) {
      return(
      <Redirect to={"/teams/"+this.teamSlug+"/manage"} />
      )}
    if(suggestProjectSuccess && this.state.suggestSuccess) {
      return (
        <Redirect to={"/teams/"+this.teamSlug} />
      )}

    return (
        <div className={style.form}>
          <ReactTitle title="Projekt vorschlagen - TreePlantingProjects" /> 
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className={"col-md-6"}>
              <img className={"img-fluid"} src="/images/login_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              {!team ? (<h2 className={style.mainHeading}>Team wird geladen....</h2>):(
                <>
              <h2 className={style.mainHeading}>Möchtest du {team.name} ein Projekt vorschlagen?</h2>
              <div className={"row"}>
                <div className={"col-md-12"}>
                {/* display team card here */}
                {suggestLoading ? (<p>Einen moment bitte</p>):(
                    <div className="text-left">
                      {true? (
                        <>
                        <p>Wähle ein Projekt aus der Liste:</p>
                        <p className={style.required} style={{marginLeft: "0.1em", marginTop: "-2em"}}>
                          <FontAwesomeIcon icon={faInfo} className="fa-fw" />
                          Du kannst nur abonnierte Projekte vorschlagen.
                          </p>
                        {Object.keys(this.getFilteredProjects(subProjects, teamProjects)).map(a=>(
                          <SmallCardL key={a} 
                            text={subProjects[a].name} 
                            image={subProjects[a].image} 
                            handleClick={()=>this.handleSuggest(subProjects[a].id)}
                          />
                        ))}
                        </>
                        
                        ):(
                          ""
                        )}
                        {/* <button type="button" className={`${style.formBtn} ${style.greyBtn} ml-1`}
                          onClick={()=>this.props.history.push(`/teams/${team.slug}`)}>
                        <FontAwesomeIcon icon={faStop} className="fa-fw" />
                        Zurück</button> */}
                    </div>
                  )}
                </div>
              </div>
              </>
              )}
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authenticated: state.auth.token !== null,
    teams: state.teams.list,
    team: state.teams.list[ownProps.match.params.teamSlug],
    suggestProjectLoading: state.teams.suggestProjectLoading,
    suggestProjectSuccess: state.teams.suggestProjectSuccess,
    subProjects: state.projects.subscribed,
    teamProjects: state.teams.projects[ownProps.match.params.teamSlug],
    teamProjectsLoading: state.teams.projectsFetchLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTeam: (auth, slug) => dispatch(fetchTeam(auth, slug)),
    fetchProjects: () => dispatch(fetchSubProjects()),
    suggestProject: (team, project) => dispatch(suggestProject(team, project)),
    fetchTeamProjects: team => dispatch(fetchTeamProjects(true, team))
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestProject));
