import React from "react";
import style from "../../style/projectCard.module.css";

class ProjectImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: ''
    }
    this.inputReference = React.createRef();
  }

  handleRemove = () => {
    this.props.onChange(id=this.props.image.id, action='remove')
  }

  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    const image = e.target.files[0]
    this.setState({image: URL.createObjectURL(image)})
    if(this.props.image === undefined) this.props.onChange(0, image, 'add');
    else this.props.onChange(this.props.image.id, image, 'update');
  }

  componentDidMount() {
    if(this.props.image == undefined) this.setState({image:"/images/reportAreaAction.png"})
    else this.setState({image: this.props.image.image})
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.image !== prevProps.image) {
      this.setState({image: this.props.image.image})
    }
  }


  render() {
    return (
      <div className={style.ImageCard} onClick={()=>{this.fileUploadAction()}}>
        <img src={this.state.image} alt="" />
        {this.props.image ? (
          <img 
            className={style.remove} 
            src="/images/removeImage.svg" 
            alt="" />):(
          <img 
            className={style.remove} 
            src="/images/addImage.svg" 
            alt="" />
            )}
            <input
            type="file"
            style={{ display: "none" }}
            hidden
            accept="image/*"
            data-type='image'
            ref={this.inputReference}
            onChange={this.fileUploadInputChange}
          />
        </div>
    );
  }
}

export default ProjectImage;