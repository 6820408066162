import React from "react";
import style from "../../style/goal.module.css";
import { Progress } from "antd";
import { Link } from "react-router-dom";

export default function AreasGoal({auth}) {
  return (
    <div className={style.goal}>
      <div className={`d-block  d-md-flex justify-content-between`} style={{position: "relative"}}>
        <img src="/images/area-info.jpg" alt="" className={style.coverBg} />
        <div className={style.contentSide}>
          <div className={style.contentHeader}>

          </div>
          <h1 className={style.mainHeading}>
            Flächen
          </h1>
          <div className={style.mainText}>
            Du besitzt eine Fläche, die Du für ein Klimawald-Projekt gerne zur Verfügung stellen möchtest? <br />
            Hier kannst Du Deine Flächen ganz einfach registrieren und der lokalen Community für Pflanzprojekte anbieten.
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "2em" }}
          >
            {auth ? (
              <Link className={`${style.greenBtn} mt-0`} to={"/report_area"}>
              Neue Fläche anlegen
            </Link>
            ):(
              <Link className={`${style.greenBtn} mt-0`} to={`/login?redirectTo=${window.location.pathname}`}>
              Jetzt bei TPP anmelden
            </Link>
            )}
            

          </div>
        </div>
        <div className={style.coverImage}>
          <img
            className={""}
            src="/images/area-info.jpg"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}
