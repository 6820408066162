import React from "react";
import style from "../style/createArea.module.css";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEnvelope, faLink, faTimes, faUser, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import EventCard from "./EventCard";
import { toast } from "react-toastify";
import { getEventIndexFromList, getProjectSlugById } from "../store/utility";
import QRCode from "qrcode.react"
import { inviteTeam } from "../store/teams";
import CircularProgress from '@material-ui/core/CircularProgress';

class Share extends React.Component {

  constructor(props){
    super(props)
    this.selectedType = false;
    this.selectedItem = false;
    let queryString = this.props.location.search.split("?");
    if (queryString.length > 1) this.selectedType = queryString[1].split("&")[0].replace("type=", "");
    if(queryString.length > 1) this.selectedItem = queryString[1].split("&")[1].replace("id=", "");

    this.state = {
      emailFields: []
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    var copyText = document.querySelector("#link");
    copyText.select();
    document.execCommand("copy");
    toast.success('👍 Link in die Zwischenablage kopiert.')
  };

  addEmailField = () => {
    let current = this.state.emailFields;
    current.push(this.state.emailFields.length+1);
    this.setState({emailFields:current});
  };

  removeEmailItem = (idx) => {
    let current = this.state.emailFields;
    current.pop()
    this.setState({emailFields:current});
    this.setState({["email"+idx]: undefined})
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  inviteEmail = e => {
    e.preventDefault()
    let emails = []
    Object.keys(this.state).map(stateIdx=>{
      if(stateIdx !== "emailFields" && this.state[stateIdx]) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(this.state[stateIdx]).toLowerCase())) emails.push(this.state[stateIdx])
      }
    })
    this.props.inviteEmail(this.selectedItem, emails)
  }

  componentDidMount() {

  }

  handleLinkPlaceholder(){
    if(this.selectedType === "event") {
      let event = this.props.events[getEventIndexFromList(this.props.events, this.selectedItem)]
      let projectSlug = getProjectSlugById(event.project, this.props.projectsMapping)
      return window.location.origin+"/projects/"+projectSlug+"/events/"+this.selectedItem
    }
    if(this.selectedType === "teamInvite") {
      let team = this.props.teams[this.selectedItem]
      return window.location.origin+"/teams/"+this.selectedItem+"/join/"+team.invite_token
    }
  }

  allMailValid = () => {
    let isValid = true;
    Object.keys(this.state).map(stateIdx=>{
      if(stateIdx !== "emailFields" && this.state[stateIdx]) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(isValid && !re.test(String(this.state[stateIdx]).toLowerCase())) isValid = false;
      }
    })
    return isValid;
  }

  render() {
    const {error, loading, authenticated, events, teams, teamInviteEmailLoading, teamInviteEmailSuccess} = this.props;
    return (
        <div className={style.form}>
          <ReactTitle title="Teilen - TreePlantingProjects" /> 
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className={"col-md-6"}>
              <img className={"img-fluid"} src="/images/login_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={style.mainHeading}>{this.selectedType == "teamInvite" ? ("Lade neue Teammitglieder ein!"):("Lass uns vernetzen")}</h2>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  {this.selectedType === "event" &&
                    <EventCard event={events[getEventIndexFromList(events, this.selectedItem)]} />
                  }
                  {this.selectedType === "teamInvite" &&
                  <p>Teile einfach diesen Link:</p>
                  }
                  <form className={"mt-5"} action="">
                    <div className="text-left">
                      <div className="row mb-3">
                        <div className={style.qrcode}>
                          <QRCode value={this.handleLinkPlaceholder()} />
                        </div>
                        <div className={style.formInput} onClick={this.handleSubmit} style={{marginTop: "3em", marginLeft: "1em"}}>
                          <input
                            className={style.commonInput}
                            style={{cursor: "pointer", width: "340px"}}
                            type="text"
                            name="link"
                            id="link"
                            fluid="left"
                            required
                            value={this.handleLinkPlaceholder()}
                            readOnly
                          />
                          <FontAwesomeIcon icon={faLink} className={style.inputIcon} />
                          <p>Zum Kopieren klicken.</p>
                        </div>
                      </div>
                    </div>
                  </form>
                  {this.selectedType == "teamInvite" && !teamInviteEmailLoading &&
                    <form className={"mt-5"} action="" onSubmit={this.inviteEmail}>
                      <p>Oder schicke eine Einladung per E-Mail:</p>
                      <div className="row"> 
                        <EmailField idx={1} last={false} handleChange={this.handleChange}/>
                      </div>
                        {this.state.emailFields.map(field=>(
                          <div className="row">
                            <EmailField idx={field+1} last={this.state.emailFields.length == field} handleRemoveItem={this.removeEmailItem} handleChange={this.handleChange}/>
                          </div>

                        ))}
                        <p 
                          style={{marginTop: "7px", marginLeft: "1em", cursor: "pointer"}}
                          onClick={this.addEmailField}
                        >
                          <FontAwesomeIcon icon={faUserPlus} className="fa-fw"/> Weitere hinzufügen
                        </p>
                         <button type="submit" className={style.formBtn} disabled={!this.allMailValid() || (Object.keys(this.state).length < 2)}>
                          <FontAwesomeIcon icon={faEnvelope} className="fa-fw" />
                          Einladen</button>
                    </form>
                }
                {teamInviteEmailLoading && <p>Einladungen werden gesendet <CircularProgress size={10}/></p>}
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    events: state.events.list,
    projectsMapping: state.projects.mapping,
    teams: state.teams.list,
    teamInviteEmailLoading: state.teams.inviteEmailLoading,
    teamInviteEmailSuccess: state.teams.inviteEmailSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    inviteEmail: (team, emails) => dispatch(inviteTeam(team, emails))
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(Share));

const EmailField = ({idx, last, handleRemoveItem, handleChange}) => (
  <div className={style.formInput} style={{ marginLeft: "1em"}}>
    <input
      className={style.commonInput}
      style={{width: "340px"}}
      type="email"
      name={"email"+idx}
      placeholder={"name@email.de"}
      onChange={handleChange}
      id="link"
      fluid="left"
      required
    />
    <FontAwesomeIcon icon={faUser} className={style.inputIcon} />
    {last &&
      <p 
        style={{marginTop: "7px", marginLeft: "1em", cursor: "pointer"}}
        onClick={()=>handleRemoveItem(idx)}
      >
        <FontAwesomeIcon icon={faTimes} className="fa-fw"/> Entfernen
      </p>
    }
</div>
);