import Skeleton from "@material-ui/lab/Skeleton";
import React, { useState } from "react";
import style from "../../style/Header.module.css";


export default function LoadingHeader() {
  return (
  <div className={style.menu}>
    <div style={{position: "sticky", top: "0", zIndex: "2"}}>
      <div
        className={`${style.Header} justify-content-center justify-content-lg-between`}
      >
        <div className="mb-2 mb-md-0 row">

          <Skeleton height={20} width={"4em"} style={{marginLeft: "1em"}}/>
          <Skeleton height={20} width={"3em"}/>
          <Skeleton height={20} width={"4em"}/>
          <Skeleton height={20} width={"3em"}/>
        </div>
        <div className="row">
              <Skeleton height={20} width={"3em"}/> 
              <Skeleton height={20} width={"3em"}/> 
        </div>
      </div>
    </div>
  </div>
  );
}
