import React from "react";
import LongProjectCard from "../components/projects/LongProjectCard";
import style from "../style/suche.module.css";
import projectsStyle from "../style/projects.module.css";
import algoliasearch from 'algoliasearch';
import { connect } from "react-redux";

import { Input } from "antd";
import { Link } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import LoadingCard from "../components/projects/LoadingCard";
import TeamCard from "../components/TeamCard";
import TeamsGoal from "../components/teams/TeamsGoal";
import {fetchTeams} from "../store/teams";


class Teams extends React.Component {
  constructor(props) {
    super(props)

    this.client = algoliasearch(
      '5WACQWVB9Q',
      '64e692955e069fb6c318665fe93c4552'
    );
    this.index = this.client.initIndex(
      process.env.REACT_APP_STAGE+'_Team'
      //'staging_Project'
      );
    this.state= {
      tileview: true,
      address: '',
      cords: null,
      teams: [],
    }
    this.index.setSettings({
      searchableAttributes: [
        'name',  
      ],
    });
    this.timeout =  0;
  }

  handleChange = search => {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.index.search(search.target.value, {hitsPerPage: 90}).then(({hits}) => {
        this.setState({teams: hits})
      });
    }, 300);

  };

  componentDidMount() {
    if (this.props.authenticated) this.props.fetchTeams();
    this.index.search('', {hitsPerPage: 90}).then(({hits}) => {
      this.setState({teams: hits})
    });
  }

    render() {
    const { authenticated, teams, loading } = this.props
      return (
        <div className={style.Suche}>
            <MetaTags>
              <title>Teams - TreePlantingProjects</title>
              <meta name="description" content="Unsere Teams" />
              <meta property="og:title" content="Suche Projekte auf TreePlantingProjects" />
              <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
            </MetaTags>
            <div className={projectsStyle.Projects}>
                   {authenticated && teams.length ? (
                    <div className={projectsStyle.yourProject} style={{marginBottom: "1em"}}>
                        <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-between mb-3">
                            <h1 className="mr-3 mb-3 mb-sm-0">Deine Teams</h1>
                            <Link to={"/newTeam"}>
                                <button className="primaryBtn mr-2">
                                    <img src={`images/plus-square-Icon.svg`} alt="" className="mr-2" />{" "}
                              Team anlegen
                            </button>

                            </Link>
                        </div>
                        <div
                            className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"
                            style={{ marginLeft: "-1em" }}
                        >
                            {Object.keys(teams).map((id => (

                                <div key={id}>
                                    {teams[id] ? (
                                     <Link
                                     key={id} to={`/teams/${teams[id]["slug"]}`}
                                        >
                                            <TeamCard team={teams[id]} />
                                        </Link>
                                    ):("")}
                                </div>
                            )))
                            }
                        </div>
                    </div>
                ) : (
                    <TeamsGoal auth={authenticated}/>
                )}
                </div>
          <div
            className={`${style.header} `}
          >
            <div style={{display:'flex',flexDirection:"column",width:"300px"}}>
              <form noValidate action="" role="search" onSubmit={onSubmit} style={{ display: "contents" }}>
                <Input
                  placeholder="Suche nach Teams"
                  style={{ backgroundColor: "#ffffff" }}
                  className={`mb-2 mb-md-0`}
                  onChange={this.handleChange}
                  prefix={
                    <img
                      src="/images/searchIconGrey.svg"
                      alt=""
                      style={{ width: "1.275625em" }}
                    />
                  }
                />
              </form>
              </div>
              {/*<div className={style.switch_buttons}>*/}
              {/*  <BarsOutlined onClick={()=>this.setState({tileview:false})} style={this.state.tileview ===true ?{color:"#C1C3C2"}: {color:"#000000"}} />*/}
              {/*  <AppstoreOutlined onClick={()=>this.setState({tileview:true})}  style={this.state.tileview ===true ?{color:"#000000"}: {color:"#C1C3C2"}} />*/}
              {/*</div>*/}
          </div>
          <h1>Teams</h1>
          {this.state.tileview && <TeamCards hits={this.state.teams}/>}
          {/* {!this.state.tileview &&  <LongProjectCards hits={this.state.teams}/>} */}
        </div>
    )
    }
  }


const onSubmit = (event) => {
  event.preventDefault();

}

const TeamCards = ({ hits }) => (
  <div
    className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"
    style={{ marginLeft: "-1em" }}
  >
    {!hits && Array.from(Array(10).keys()).map((key => (
      <LoadingCard key={key}/>)))}

    {hits.map(hit => (
      <div key={hit.id} >
        <Link to={`/teams/${hit.slug}`} key={hit.id} >
        <TeamCard team={hit} />
        </Link>
      </div>
    ))}
  </div>
);

const LongProjectCards = ({ hits }) => (
  <div
    style={{ marginLeft: "-1em" }}
  >
    { hits.map(hit => (
      <Link to={`/projects/${hit.title_slug}`} key={hit.id} >
        <LongProjectCard  key={hit.objectID} data={{ ...hit, image: hit.image_url.split("?AWS")[0] }}/>
      </Link>
    ))}
  </div>
);
const mapStateToProps = state => {
  return {
    authenticated: state.auth.token !== null,
    teams: state.teams.list,
    loading: state.teams.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
        fetchTeams: () => dispatch(fetchTeams(true)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Teams);
