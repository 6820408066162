import React, { useState } from "react";
import style from "../style/projects.module.css";
import ProjectsGoal from "../components/projects/ProjectsGoal";
import ProjectCard from "../components/projects/ProjectCard";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchProjects, fetchSubProjects } from "../store/projects";
import MetaTags from 'react-meta-tags';


class Projects extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getProjects(this.projectsToLoad);
        if (this.props.authenticated) this.props.getSubProjects();
    }


    render() {
        const { authenticated, projects, subProjects, isLoading, subIsLoaded, error } = this.props
        if (error || isLoading) {
            return "";
        }
        return (
            <div>
                <MetaTags>
                    <title>Projekte - TreePlantingProjects</title>
                    <meta name="description" content="Hier siehst Du eine Übersicht der aktuellen Projekte. Wenn Du Projekte in deiner Nähe finden willst, benutze einfach die Suchfunktion. Projekte, die Dich interessieren, kannst Du natürlich abonnieren, damit Du nichts mehr verpasst. Oder Du trägst Dich direkt als Helfer, Reporter oder Organisator für ein Projekt ein. " />
                    <meta property="og:title" content="Projekte auf TreePlantingProjects" />
                    <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
                </MetaTags>
                <div className={style.Projects}>
                    {authenticated && subIsLoaded && Object.keys(subProjects).length !== 0 ? (
                        <div className={style.yourProject}>
                            <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-between mb-3">
                                <h1 className="mr-3 mb-3 mb-sm-0">Deine Projekte</h1>
                                <Link to={"/search"}>
                                    <button className="primaryBtn mr-2" style={{ padding: "1.2em 3.1em" }}>
                                    <img src="/images/searchIcon.svg" className="mr-2" />
                              Projekte suchen
                            </button>

                                </Link>
                            </div>
                            <div
                                className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"                                
                                style={{ marginLeft: "-1em" }}
                                >
                                {Object.keys(subProjects).map((id => (
                                    <Link
                                        key={id} to={`/projects/${subProjects[id].title_slug}`}
                                    >
                                        <ProjectCard key={id} data={subProjects[id]} />
                                    </Link>
                                )))
                                }
                            </div>
                        </div>
                    ) : (
                        <ProjectsGoal authenticated={authenticated} />
                    )}
                    <div>
                        <h1 className="my-5">Letzte Projektaktivität</h1>
                        <div
                            className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"
                            style={{ padding: "0em 1.5em", marginLeft: "-1em" }}
                        >
                            {projects.map((project => (
                                <div key={project.id} variants={this.item}>
                                    <Link
                                        to={`/projects/${project.title_slug}`} key={project.id}
                                    >
                                        <ProjectCard key={project.id} data={project} />
                                    </Link>
                                </div>
                            )))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.auth.token !== null,
        projects: state.projects.list,
        subProjects: state.projects.subscribed,
        subIsLoaded: state.projects.subIsLoaded,
        isLoading: state.projects.isLoading,
        error: state.projects.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProjects: () => dispatch(fetchProjects()),
        getSubProjects: () => dispatch(fetchSubProjects())
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Projects);
