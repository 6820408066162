import React, { useState } from "react";
import AreasGoal from "../components/areas/AreasGoal";
import style from "../style/projects.module.css";
import goalStyle from "../style/goal.module.css";
import { connect } from "react-redux";
import { fetchProjects, fetchSubProjects } from "../store/projects";
import { fetchAreas, fetchMapMarkers } from "../store/areas";
import { Link } from "react-router-dom";
import AreaCard from "../components/areas/AreaCard";
import MetaTags from 'react-meta-tags';
import gmapStyle from "../data/gmapStyle.json"
import { fetchTeams } from "../store/teams";
import TeamGoal from "../components/teams/TeamGoal";
import TeamsGoal from "../components/teams/TeamsGoal";
import SmallCardL from "../components/SmallCardL";
import TeamCard from "../components/TeamCard";

class Teams extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.authenticated) this.props.fetchTeams();
    }

    render() {
        const { authenticated, loading, teams } = this.props;
        return (
            <div className={style.Projects}>
                <MetaTags>
                    <title>Teams - TreePlantingProjects</title>
                    <meta name="description" content="Gemeinsam mit deinen Freunden oder Kollegen gegen den Klimawandel." />
                    <meta property="og:title" content="Teams bei TreePlantingProjects" />
                    <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
                </MetaTags>
                {authenticated && teams.length ? (                    
                    <div className={style.yourProject}>
                        <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-between mb-3">
                            <h1 className="mr-3 mb-3 mb-sm-0">Deine Teams</h1>
                            <Link to={"/newTeam"}>
                                <button className="primaryBtn mr-2">
                                    <img src={`images/plus-square-Icon.svg`} alt="" className="mr-2" />{" "}
                              Team anlegen
                            </button>

                            </Link>
                        </div>
                        <div
                            className="d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start"
                            style={{ marginLeft: "-1em" }}
                        >
                            {Object.keys(teams).map((id => (
                                
                                <div key={id}>
                                    {teams[id] ? (
                                     <Link
                                     key={id} to={`/teams/${teams[id]["slug"]}`}
                                        >
                                            <TeamCard team={teams[id]} />
                                        </Link>
                                    ):("")}
                                </div>
                            )))
                            }
                        </div>
                    </div>
                ) : (
                    <TeamsGoal auth={authenticated}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.auth.token !== null,
        teams: state.teams.list,
        loading: state.teams.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchTeams: () => dispatch(fetchTeams(true)),
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Teams);

