import React from "react";
import style from "../style/goal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";

export default function BlogGoal({ post }) {
  //const img = 'https:/' + post.image.split('%3A')[1];
  const description = post.description.replace('<p>', '').replace('</p>', '');
  let lDate = new Date(post.date).toLocaleDateString('de-DE', {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
  })
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div className={style.goal}>
      <div
        className={`d-block d-md-flex`}
        style={{ position: "relative" }}
      >
        <img src={post.image} alt="" className={style.coverBg} />
        <div className={style.coverImage}>
          <img
            src={post.image}
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className={style.contentSide}
          style={{ padding: "3em 1em 3em 2em" }}
        >
          <div>
            <div className="d-flex align-items-center mb-2">
              <div className={`${style.event} mt-0 ml-2`}>
                <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/>
                <span>{lDate}</span>
              </div>
            </div>
            <div>
              <a className={style.grayBorder} href="#">
                Neuster Blogartikel
              </a>
            </div>
          </div>
          <h1 className={style.mainHeading}>
            {renderHTML(post.title)}
          </h1>
          <div className={style.mainText}>
            {renderHTML(description)}
          </div>
          <a className={style.greenBtn} href={post.link} target={"_blank"}>
            Mehr lesen
          </a>
        </div>
      </div>
    </div>
  );
}
