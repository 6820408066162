import React from "react";
import { motion } from "framer-motion";
import { Progress } from "antd";
import BlogCard from "../BlogCard";
import NewsCard from "../NewsCard";

export default function ProjectNews({ news, projectName }) {
  const [ showAllNews, setShowAlNews ] = React.useState(false)
  let list = news
  if(news.length > 2 && !showAllNews) list = [list[0], list[1]]
  return (
        <>
          {list.map((newa => (
            <NewsCard key={newa.id} news={{ title: newa.title, description: newa.body, date: newa.date, image: newa.image, link: '/projects/'+projectName+'/posts/'+newa.id, messages: newa.messages}} />
          )))}
          {
            news.length > 2 && 
            <p style={{marginLeft: "1em", cursor: "pointer", style: showAllNews ? ("2em"):("3em")}} onClick={()=>setShowAlNews(!showAllNews)}>{showAllNews ? 
              ("Weniger Neuigkeiten anzeigen"):("+ "+(news.length-2)+" weitere Neuigkeiten anzeigen")}
            </p>
          }
        </>
  );
}
