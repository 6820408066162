import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from 'reselect'
import { toast } from 'react-toastify';
import { fetchProjectEvents } from "./events";


const slice = createSlice({
    name: 'projects',
    initialState: {
        mapping: [],
        subscribed: {},
        list: [],
        isLoading: false,
        detailsIsLoading: false,
        detailsLoaded: false,
        imagesLoaded: false,
        areasLoaded: false,
        subscribersLoaded: false,
        mebersLoaded: false,
        newsLoaded: false,
        details: {
            about: {
                id: null,
                name: null,
                description: null,
                title_slug: null,
                progress: null,
                location_name: null,
                image: null,
                location_zip: null,
                information: null,
                trees: null,
                project_meta_profiles: null
            },
            images: [],
            areas: [],
            news: [],
            members: [],
            subscribers: [],
            settings: {},
            messages: []
        },
        selected: false,
        leave: {
            user: "",
            project: "",
            profile: "",
        },
        settingsLoaded: false,
        memberUpdateLoading: false,
        projectUpdateLoading: false,
        newsActionLoading: false,
        messagesLoading: true,
        newDetails: {},
        about: {},
        images: {},
        areas: {},
        news: {},
        subscribers: {},
        members: {},
        messages: {},
        teams: {},
        teamsLoading: false,
        subLoading: false,
        unsubLoading: false

    },
    reducers: {
        projectsFetchLoading: (projects) => {
            projects.isLoading = true
        },
        projectsFetchSuccess: (projects, action) => {
            projects.list = action.payload
            projects.isLoading = false
        },
        projectsFetchFail: (projects) => {
            projects.isLoading = false
        },
        subProjectsFetchLoading: (projects) => {
            projects.subIsLoaded = false
        },
        subProjectsFetchSuccess: (projects, action) => {
            action.payload.results.map(project => {
                projects.subscribed[project.id] = project
            })
            projects.subIsLoaded = true
        },
        subProjectsFetchFail: (projects) => {
            projects.subIsLoading = false
        },
        projectDetailsSelected: (projects, action) => {
            projects.newDetails[action.payload] = {};
            
        },
        resetProjectDetailsLoad: (projects) => {
            projects.detailsLoaded = false
            projects.detailsIsLoading = false
            projects.imagesLoaded = false
            projects.newsLoaded = false
            projects.areasLoaded = false
            projects.subscribersLoaded = false
        },
        projectDetailsFetchLoading: (projects) => {
            projects.detailsLoaded = false
        },
        projectDetailsFetchSuccess: (projects, action) => {
            projects.details.about = action.payload
            projects.selected = action.payload.id
            projects.detailsLoaded = true
            projects.newDetails[action.payload.id] = projects.newDetails[action.payload.id] || {}
            projects.newDetails[action.payload.id].about = action.payload
            projects.about[action.payload.id] = action.payload
        },
        projectDetailsFetchFail: (projects) => {
            projects.detailsIsLoading = false
        },
        projectImagesFetchLoading: (projects) => {
            projects.imagesLoaded = false

        },
        projectImagesFetchSuccess: (projects, action) => {
            if(action.payload.count) {
                projects.images[action.payload.results[0]["project"]] = action.payload.results
            }
            projects.details.images = action.payload.results
            projects.imagesLoaded = true;

        },
        projectAreasFetchLoading: (projects, action) => {
            projects.areasLoaded = false;

        },
        projectAreasFetchSuccess: (projects, action) => {
            if(action.payload.length) {
                projects.newDetails[action.payload[0]["project"]] = projects.newDetails[action.payload[0]["project"]] || {}
                projects.newDetails[action.payload[0]["project"]].areas = action.payload
                projects.areas[action.payload[0]["project"]] = action.payload
            }
            projects.details.areas = action.payload
            projects.areasLoaded = true;
        },
        projectNewsFetchLoading: (projects, action) => {
            projects.newsLoaded = false;

        },
        projectNewsFetchSuccess: (projects, action) => {
            if(action.payload.count) {
                projects.newDetails[action.payload.results[0]["project"]] = projects.newDetails[action.payload.results[0]["project"]] || {}
                projects.newDetails[action.payload.results[0]["project"]].news = action.payload.results
                projects.news[action.payload.results[0]["project"]] = action.payload.results
            }
            projects.details.news = action.payload.results
            projects.newsLoaded = true;
        },
        projectSubscribersFetchLoading: (projects, action) => {
            projects.subscribersLoaded = false
        },
        projectSubscribersFetchSuccess: (projects, action) => {
            //projects.details.subscribers = acttion.payload.results
            projects.subscribersLoaded = true;
            if(action.payload.count) {
                projects.subscribers[action.payload.results[0]["project"]] = action.payload.results
            }

        },
        projectSub: (projects, action) => {
            projects.subscribed[projects.selected] = projects.details.about;
            projects.subLoading = false;
        },
        projectUnsub: (projects, action) => {
            delete projects.subscribed[projects.selected];
            projects.unsubLoading = false;
        },
        projectsSubLogout: (projects) => {
            projects.subscribed = {}
        },
        projectMembersFetchLoading: (projects) => {
            projects.membersLoaded = false;  
        },
        projectMembersFetchSuccess: (projects, action) => {
            if(action.payload.length) {
                projects.newDetails[action.payload[0]["project"]] = projects.newDetails[action.payload[0]["project"]] || {}
                projects.newDetails[action.payload[0]["project"]].members = action.payload
                projects.members[action.payload[0]["project"]] = action.payload
            }
            projects.details.members = action.payload;
            projects.membersLoaded = true;
        },
        projectMembersFetchFail: (projects) => {
            projects.membersLoaded = true;
        },
        projectMemberJoinSuccess: (projects, action) => {
            if(action.payload.length) {
                projects.newDetails[action.payload[0]["project"]] = projects.newDetails[action.payload[0]["project"]] || {}
                projects.newDetails[action.payload[0]["project"]].members = action.payload
            }
            projects.subscribed[projects.selected] = projects.details.about
            projects.details.members = action.payload
            toast.success("👋 Projekt beigetreten.")
        },
        projectMemberRemove: (projects, action) => {
            projects.details.members = projects.details.members.filter(
                (pro) => {
                    return pro.id !== action.payload.profile && pro.role !== action.payload.role}

                )

            projects.newDetails[projects.selected].members = projects.details.members.filter(
                (pro) => {
                    return pro.id !== action.payload.profile && pro.role !== action.payload.role}

                )
            const p = action.target.project
            projects.members[p] = projects.members[p].filter(
                (pro) => {
                    return pro.id !== action.target.user && pro.role !== action.target.role}

                ) 
            toast.success("👋 Projekt verlassen.");

        },
        prepareRemoveMember: (projects, action) => {
            const {user, role, project} = action.payload;
            projects.leave = {user, role, project };
        },
        projectSettingsFetchLoading: (projects) => {
            projects.settingsLoaded = false;
        },
        projectSettingsFetchSucess: (projects, action) => {
            projects.settings = action.payload;
            projects.settingsLoaded = true;

        },
        projectSettingsFetchFail: (projects, action) => {
            
        },
        projectMemberUpdateLoading:(projects, ) => {
            projects.memberUpdateLoading = true;
        },
        projectMemberUpdateSuccess:(projects, action) => {
            projects.settings = action.payload;
            toast.success("👍 Unterstützer*innen aktualisiert")
        },
        projectUpdateLoading:(projects, ) => {
            projects.projectUpdateLoading = true;
        },
        projectUpdateSuccess:(projects, action) => {
            projects.settings = action.payload;
            projects.projectUpdateLoading = false;
            toast.success("👍 Projekt aktualisiert")
        },
        projectImagesManageLoading: (projects) => {
            projects.projectUpdateLoading = true;
        },
        projectImagesManageSuccess: (projects, action) => {
            projects.images[action.payload[0]["project"]] = action.payload;
            projects.projectUpdateLoading = false;
            toast.success("👍 Bilder aktualisiert")
        },
        projectPostAddLoading: (projects) => {
            projects.newsActionLoading = true;
        },
        projectPostAddSuccess: (projects, action) => {
            projects.news[action.payload[0]["project"]] = action.payload
            projects.newsActionLoading = false;
            toast.success("👍 Post veröffentlicht")
        },
        projectPostAddFail: (projects) => {
            projects.newsActionLoading = false;
        },
        projectNewsUpdateLoading: (projects) => {
            projects.newsActionLoading = true;
        },
        projectNewsUpdateSuccess: (projects, action) => {
            projects.news[action.payload[0]["project"]] = action.payload;
            projects.newsActionLoading = false;
            toast.success("👍 Post aktualisiert")
        },
        projectNewsUpdateFail: (projects) => {
            projects.newsActionLoading = false;
        },
        projectsMappingFetchSuccess: (projects, action) => {
            projects.mapping = action.payload;
        },
        projectPostMessagesFetchLoading: (projects) => {
            projects.messagesLoading = true;
        },
        projectPostMessageFetchSuccess: (projects, action) => {
            if(action.payload.count) {
                projects.messages[action.payload.results[0]["post"]] = action.payload.results
            }
            projects.messagesLoading = false;
        },
        projectPostMessageSendSuccess: (projects, action) => {
            if(projects.messages[action.payload.post])
                projects.messages[action.payload.post].unshift(action.payload);
            else{
                projects.messages[action.payload.post] = [action.payload]
            }
            toast.success("👍 Kommentar gespeichert")
        },
        projectPostMessageDeleteSuccess: (projects, action) => {
            projects.messages[action.payload.post] = projects.messages[action.payload.post].filter(
                (pro) => {
                    return pro.id !== action.payload.message_id}

                )
                toast.success("👍 Kommentar gelöscht")
        },
        projectFetchTeamsLoading: projects => {
            projects.teamsLoading = true;
        },
        projectFetchTeamsSuccess: (projects, action) => {
            projects.teamsLoading = false;
            projects.teams[action.target] = action.payload.results
        },
        projectFetchTeamsFail: projects => {
            projects.teamsLoading = false;
        },
        projectSubLoading: projects => {
            projects.subLoading = true;
        },
        projectSubFail: projects => {
            projects.subLoading = false;
        },
        projectUnsubLoading: projects => {
            projects.unsubLoading = true;
        },
        projectUnsubFail: projects => {
            projects.unsubLoading = false;
        }
    }
});

export const {
    projectsFetchLoading, projectsFetchSuccess, projectsFetchFail,
    subProjectsFetchLoading, subProjectsFetchSuccess, subProjectsFetchFail,
    projectDetailsSelected, resetProjectDetailsLoad, projectsSubLogout,
    projectDetailsFetchLoading, projectDetailsFetchSuccess, projectDetailsFetchFail,
    projectImagesFetchSuccess, projectAreasFetchSuccess, projectSubscribersFetchSuccess, projectMembersFetchLoading,
    projectImagesFetchLoading, projectAreasFetchLoading, projectSubscribersFetchLoading, projectMembersFetchSuccess,
    projectNewsFetchLoading, projectNewsFetchSuccess, prepareRemoveMember,
    projectSub, projectUnsub, projectMemberJoinSuccess, projectMemberRemove,
    projectSettingsFetchLoading, projectSettingsFetchSucess, projectSettingsFetchFail,
    projectMemberUpdateLoading, projectMemberUpdateSuccess, projectUpdateLoading, projectUpdateSuccess,
    projectImagesManageLoading, projectImagesManageSuccess,
    projectNewsUpdateLoading, projectNewsUpdateSuccess, projectNewsUpdateFail,
    projectPostAddLoading, projectPostAddSuccess, projectPostAddFail, projectsMappingFetchSuccess,
    projectPostMessagesFetchLoading, projectPostMessageFetchSuccess, projectPostMessageSendSuccess,
    projectPostMessageDeleteSuccess, projectMembersFetchFail,
    projectFetchTeamsLoading, projectFetchTeamsSuccess, projectFetchTeamsFail,
    projectSubLoading, projectSubFail, projectUnsubLoading, projectUnsubFail
} = slice.actions
export default slice.reducer;


// Projects actions
export const fetchProjects = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/latest_projects",
                method: "GET",
                onLoading: projectsFetchLoading.type,
                onSuccess: projectsFetchSuccess.type,
                onError: projectsFetchFail.type
            }
        })
    };
};


export const fetchSubProjects = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects?subscribed=true",
                method: "GET",
                auth: true,
                onLoading: subProjectsFetchLoading.type,
                onSuccess: subProjectsFetchSuccess.type,
                onError: subProjectsFetchFail.type
            }
        })
    };
};


// Project details actions
export const fetchProjectDetails = (id, auth, fetched, id_) => {
    return dispatch => {
        dispatch({
            type: projectDetailsSelected.type, payload: id_
        })
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id,
                method: "GET",
                onLoading: projectDetailsFetchLoading.type,
                onSuccess: projectDetailsFetchSuccess.type,
                onError: projectDetailsFetchFail.type
            }
        })
        dispatch(fetchProjectDetailsData(id, auth, fetched))
    }
}

export const fetchProjectDetailsData = (id, auth, fetched) => {
    fetched = false;
    
    return dispatch => {
        if(!fetched) {
            dispatch({
                type: "apiCall", payload: {
                    url: "/projects/" + id + "/images/",
                    method: "GET",
                    onSuccess: projectImagesFetchSuccess.type,
                    onLoading: projectImagesFetchLoading.type
                }
            })
        }
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/areas/",
                method: "GET",
                onSuccess: projectAreasFetchSuccess.type,
                onLoading: projectAreasFetchLoading.type
            }
        })
        dispatch(fetchProjectMemberDetails(auth, id))
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/news/",
                method: "GET",
                auth,
                onSuccess: projectNewsFetchSuccess.type,
                onLoading: projectNewsFetchLoading.type
            }
        })
        dispatch(fetchProjectEvents(id, auth))
        dispatch(fetchProjectTeams(id))
}
}


// Subscribe actions
export const subscribeProject = (id) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/subscribers/manage/",
                auth: true,
                method: "POST",
                onSuccess: projectSub.type,
                onLoading: projectSubLoading.type,
                onError: projectSubFail.type
            }
        })
    }
}

export const unsubscribeProject = (id) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/subscribers/manage/",
                method: "DELETE",
                auth: true,
                onSuccess: projectUnsub.type,
                onLoading: projectUnsubLoading.type,
                onError: projectUnsubFail.type
            }
        })
    }
}

export const fetchProjectMemberDetails = (auth, project) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/project_mebers_details/" + project,
                method: "GET",
                auth,
                onSuccess: projectMembersFetchSuccess.type,
                onLoading: projectMembersFetchLoading.type
            }
        })
    }
}

export const removeMember = (member) => {
    return dispatch => {
        const {user, project, role } = member;
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + project + "/members/manage",
                method: "DELETE",
                data: {role},
                auth: true,
                onSuccess: projectMemberRemove.type,
                target: {user, project, role}
            }
        })
    }
}

export const joinProject = (id, role, profile) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/members/manage",
                method: "POST",
                data: { "role": role, "profile": profile, "project": id },
                auth: true,
                onSuccess: projectMemberJoinSuccess.type,
            }
        })
    }
}

export const checkIfLoaded = (projectName, projectDetails) => {
    const keys = Object.keys(projectDetails);
    let got_it = false
    keys.forEach((key) => {
        try{
            if (projectDetails[key].about.title_slug == projectName) {
                got_it = true;
            }
        } catch (e) {
            if(e.instanceOf === TypeError) {
            }
        }
    });
    return got_it
}

export const fetchProjectSettings = (id) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/settings",
                method: "GET",
                auth: true,
                onSuccess: projectSettingsFetchSucess.type,
            }
        })
    }
}

export const updateMembers = (id, members) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/update_member",
                method: "POST",
                auth: true,
                data: members,
                onLoading: projectMemberUpdateLoading.type,
                onSuccess: projectMemberUpdateSuccess.type
            }
        })
    }
}

export const removeMembers = (id, members) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/update_member",
                method: "DELETE",
                auth: true,
                data: members,
                onLoading: projectMemberUpdateLoading.type,
                onSuccess: projectMemberUpdateSuccess.type
            }
        })
    }
}

export const updateProject = (id, data) => {
    return dispatch => {
        var information = data.information;
        if(information && !information.includes("\r\n")) information = information.replaceAll("\n", "\r\n")
        const fd = new FormData();
        fd.append('name', data.name);
        fd.append('image', data.image);
        fd.append('location', data.location);
        fd.append('short_description', data.short_description);
        fd.append('description', data.description);
        fd.append('information', information);
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/update",
                method: "PATCH",
                auth: true,
                data: fd,
                onLoading: projectUpdateLoading.type,
                onSuccess: projectUpdateSuccess.type
            }
        })
    }
}

export const updateProjectImages = (id, data) => {
    return dispatch => {
        data.map(image => {
            const fd = new FormData();
            fd.append('image', image.image);
            fd.append('action', image.action);
            fd.append('id', image.id);
            dispatch({
                type: "apiCall", payload: {
                    url: "/projects/" + id + "/update_image",
                    method: "POST",
                    auth: true,
                    data: fd,
                    onLoading: projectImagesManageLoading.type,
                    onSuccess: projectImagesManageSuccess.type
                }
            })
        });
    }
}

export const addProjectPost = (id, post) => {
    return dispatch => {
        const fd = new FormData();
        fd.append("title", post.title)
        fd.append("image", post.image)
        fd.append("body", post.body)
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/update_news",
                method: "PUT",
                auth: true,
                data: fd,
                onLoading: projectPostAddLoading.type,
                onSuccess: projectPostAddSuccess.type,
                onError: projectPostAddFail.type
            }
        })
    }
}

export const updateProjectPost = (id, post) => {
    return dispatch => {
        const fd = new FormData();
        fd.append("id", post.id)
        fd.append("title", post.title)
        fd.append("image", post.image)
        fd.append("body", post.body)
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/update_news",
                method: "POST",
                auth: true,
                data: fd,
                onLoading: projectNewsUpdateLoading.type,
                onSuccess: projectNewsUpdateSuccess.type,
                onError: projectNewsUpdateFail.type
            }
        })
    }
}

export const deleteProjectPost = (id, post) => {
    return dispatch => {
        const fd = new FormData();
        fd.append("id", post.id)
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + id + "/update_news",
                method: "DELETE",
                auth: true,
                data: fd,
                onLoading: projectNewsUpdateLoading.type,
                onSuccess: projectNewsUpdateSuccess.type,
                onError: projectNewsUpdateFail.type
            }
        })
    }
}

export const fetchProjectsMapping = () => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects_mapping",
                method: "GET",
                onSuccess: projectsMappingFetchSuccess.type,
            }
        })
    }
}

export const fetchProjectPostMessages = (id) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/posts/"+id,
                method: "GET",
                auth: true,
                onLoading: projectPostMessagesFetchLoading.type,
                onSuccess: projectPostMessageFetchSuccess.type,
            }
        })
    }
}

export const sendProjectPostMessage = (id, text) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/posts/"+id+"/",
                method: "POST",
                auth: true,
                data: {text : text, post: parseInt(id)},
                onSuccess: projectPostMessageSendSuccess.type,
            }
        })
    }
}

export const removeProjectPostMessage = ( message_id) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/posts_manage/"+message_id,
                method: "DELETE",
                auth: true,
                onSuccess: projectPostMessageDeleteSuccess.type,
            }
        })
    }
}

export const resetProjectsLoad = ( fetched) => {
    return dispatch => {
        dispatch({
            type: resetProjectDetailsLoad.type
        })
    }
}

export const fetchProjectTeams = (project) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/"+project+"/teams",
                method: "GET",
                auth: false,
                onLoading: projectFetchTeamsLoading.type,
                onSuccess: projectFetchTeamsSuccess.type,
                onError: projectFetchTeamsFail.type,
                target: project
            }
        })
    }
}