import React from "react";
import style from "../style/createArea.module.css";
import { Link, Redirect, withRouter } from "react-router-dom";
import PasswordStrengthBar from 'react-password-strength-bar';

import { connect } from "react-redux";
import { authSignup } from "../store/auth";
import GoogleLogin from "./GoogleLogin";
import FacebookLogin from "./FacebookLogin";
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faLockAlt, faSignInAlt, faUser } from "@fortawesome/pro-regular-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


class SignUp extends React.Component {
  state = {
    email: "",
    password: "",
    passwordScore: false,
    passwordShown: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, password, passwordScore } = this.state;
    if (password && passwordScore) this.props.signup(email, password);

  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePasswordScore = e => {
    this.setState({ passwordScore: e })
  }

  togglePasswordVisiblity = () => {
    this.setState({passwordShown: !this.state.passwordShown});
  };


  render() {
    const { email, password } = this.state;
    const { error, loading, token, started } = this.props;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Registrieren - TreePlantingProjects" />
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className="col-md-6">
            <img className={"img-fluid"} src="images/signup_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <p onClick={this.props.history.goBack} className={style.backLink}>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
            </p>

            <div className={` d-flex justify-content-center`}>

              <div className={`${style.contentBox}`}>
                <h2 className={style.mainHeading} style={{marginTop: "0.2em"}}>Registrieren</h2>

                <div className={`mt-5 ${style.socialLogin}`}>
                  <GoogleLogin />
                  <FacebookLogin />
                </div>

                <div className={`mt-4 d-flex justify-content-center align-middle`}>
                  <p>- oder -</p>
                </div>

                {!started ? (
                  <form className="mt-4" action="" onSubmit={this.handleSubmit}>
                    <div className={style.formInput}>
                      <input
                        className={style.commonInput}
                        type="email"
                        onChange={this.handleChange}
                        value={email}
                        name="email"
                        required
                        placeholder="E-Mail-Adresse"
                      />
                      <FontAwesomeIcon icon={faUser} className={style.inputIcon} />
                    </div>
                    <div className={`${style.formInput} mb-1`}>
                      <input
                        className={style.commonInput}
                        type={this.state.passwordShown ? "text" : "password"}
                        onChange={this.handleChange}
                        value={password}
                        name="password"
                        placeholder="Passwort"
                        minLength="8"
                        required
                      />
                      <FontAwesomeIcon icon={faLockAlt} className={style.inputIcon} />
                      <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                      <PasswordStrengthBar password={password}
                        scoreWords={["schwaches Passwort", "schwaches Passwort", "da geht noch was", "gutes Passwort", "starkes Passwort"]}
                        shortScoreWord="zu kurzes Passwort"
                        minLength="8"
                        onChangeScore={this.handlePasswordScore}
                      />
                    </div>
                    <button className={`${style.formBtn} mt-4`}>
                      <FontAwesomeIcon icon={faSignInAlt} className="fa-fw" />
                      Los geht's
                    </button>

                  </form>
                ) : (
                  <p>Bitte bestätige deine E-Mail.</p>
                )}
                <div className={`${style.mainText}`}>
                  <Link to={`/login`}>
                    <p>Bereits registriert?</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    started: state.auth.started
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signup: (email, password) =>
      dispatch(authSignup(email, password))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUp));
