import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login';
import { toast } from "react-toastify";
import { facebookSocialLogin } from "../store/auth";
import { connect } from "react-redux";

class FacebookSocialAuth extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const onSuccess = (response) => {
      // this.props.facebookSocialLogin(response.accessToken)
    }
    const onFailure = (response) => {
      console.log(response)
    }
    const fbResponse = (response) => {
      this.props.facebookSocialLogin(response.accessToken)
    }
    return (
      <FacebookLogin
          buttonText="Login"
          appId= "139943003995309"
          fields="name,email,picture"
          cssClass="btnFacebook"
          icon="fa-facebook-official"
          textButton="Facebook"
          onSuccess={onSuccess}
          onFailure={onFailure}
          callback={fbResponse}
        />

    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    facebookSocialLogin: (access_token) => dispatch(facebookSocialLogin(access_token)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(FacebookSocialAuth);
