import React, { useState } from "react";
import style from "../style/blog.module.css";
import BlogGoal from "../components/BlogGoal";
import ProjectCard from "../components/projects/ProjectCard";
import { connect } from "react-redux";
import { fetchBlog, fetchBlogMore } from "../store/blog";
import BlogCard from "../components/BlogCard";
import MetaTags from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";
import LoadingCard from "../components/projects/LoadingCard";
import LoadingGoal from "../components/projects/LoadingGoal";

class Blog extends React.Component {
    constructor(props) {
        super(props);
        this.postsToLoad = 7;
    }

    componentDidMount() {
        this.props.getBlog(this.postsToLoad)
    }

    loadMorePosts = () => {
        this.postsToLoad += 6
        this.props.fetchBlogMore(this.postsToLoad);
    }

    render() {
        const { posts, loaded, error } = this.props;
        if (error) {
            return "";
        } else {
            const mainPost = posts[0]
            const cardPosts = posts.slice(1)
            return (
                <div>
                    <MetaTags>
                        <title>Blog - TreePlantingProjects</title>
                        <meta name="description" content="Schön, dass Du dabei bist." />
                        <meta property="og:title" content="Willkommen bei TreePlantingProjects" />
                        <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
                    </MetaTags>
                    <div className="row">
                        <div className="col-md-12">
                            {mainPost ? (
                                <div className={style.Goal}>
                                    <BlogGoal post={mainPost} />
                                </div>
                            ):(<LoadingGoal />)}
                        </div>
                    </div>
                    <div className={style.Blog}>
                        <div
                            className={`d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start ${style.cards}`}
                        >{!cardPosts.length && Array.from(Array(3).keys()).map((key => (
                            <LoadingCard key={key} variant="small"/>
                        )))}
                            {cardPosts.map((post => (
                                <a
                                    href={post.link} key={post.title} target="_blank"
                                >
                                    <BlogCard post={post} />
                                </a>
                            )))}
                            {!loaded && Array.from(Array(3).keys()).map((key => (
                            <LoadingCard key={key} variant="small"/>
                        )))}
                        </div>
                        <div>
                            <button className="primaryBtn mr-2 ml-3" style={{ padding: "1.2em 3.1em" }} onClick={this.loadMorePosts} disabled={!cardPosts.length}>
                                <FontAwesomeIcon icon={faBars} className="fa-fw mr-1" />
                                Weitere laden
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.auth.token !== null,
        posts: state.blog.list,
        loaded: state.blog.loaded,
        error: state.blog.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBlog: (limit) => dispatch(fetchBlog(limit)),
        fetchBlogMore: (limit) => dispatch(fetchBlogMore(limit))
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Blog);
