import React, { useState } from "react";
import style from "../style/Admin.module.css";
import areaStyle from "../style/createArea.module.css";
import { Input } from "antd";
import TableRow from "../components/TableRow";
import AdminChartsSection from "../components/AdminChartsSection";
import { connect } from "react-redux";
import { changeAlgo, fetchAdminStats, fetchTalkServerStatus, fetchUsers, triggerTalkServer } from "../store/admin";
import { convertDateTimeToDateAndTime } from "../store/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExternalLink, faSearch, faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Redirect } from "react-router";
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import projectRoles from "../data/projectRoles.json";
class Admin extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      selectedUsers: [],
      filterText: "",
    }

    this.columns = [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        width: "24em",
        cell: row => <div className={style.profileImage}><img src={row.image} alt="profile" /><span>{row.name}</span></div>
      },
      {
        name: 'E-Mail',
        selector: 'email',
        sortable: true,
        width: "25em",
        cell: row => row.email
      },
      {
        name: 'Letze Anmeldung',
        selector: 'last_login',
        sortable: true,
        cell: row => new Date(row.last_login).toLocaleString('de-DE', {
          day: "2-digit",
          year: "numeric",
          month: "2-digit",
          hour: "numeric",
          minute: "numeric" 
        })
      },
      {
        name: 'Projekte',
        selector: 'projects',
        sortable: false,
        cell: row => <div><p data-multiline="true" data-tip={row.projects.map(project=>(
          project[0]+" - "+projectRoles[project[1]-1]["name"]+"<br>"
          ))}>{row.projects.length}</p></div>
      },
      {
        name: 'Bestätigt',
        selector: 'verified',
        sortable: true,
        cell: row => <div>{row.verified ? (<FontAwesomeIcon icon={faCheckCircle}/>):(<FontAwesomeIcon icon={faTimesCircle}/>)}</div>
      }
    ]
  }
  handleRowChange = (state) => {
    this.setState({selectedUsers: state.selectedRows});
  };

  componentDidMount() {
    this.props.fetchAdminStats();
    this.props.fetchTalkServerStatus();
 
    const interval = setInterval(() => {
      if(window.location.pathname == "/admin")  
      {
        this.props.fetchTalkServerStatus();
      } else clearInterval(interval)
      
    }, 5000);
    this.props.fetchUsers();

     
  }


  componentDidUpdate() {
    
  }

  handleEventProjectChange = e => {
    this.props.changeAlgo(e.target.value)

  }

  getSubHeaderComponent = () => {
    const disabledButtons = this.state.selectedUsers.length;
    return (
      <div
      className={`${style.header} d-flex align-items-center justify-content-between`}
    >
      <FilterComponent
        onFilter={(e) => {
          let newFilterText = e.target.value;
          this.filteredItems = this.props.users.filter(
            (item) =>
              (item.name &&
              item.name.toLowerCase().includes(newFilterText.toLowerCase())) || (
                item.email &&
                item.email.toLowerCase().includes(newFilterText.toLowerCase()) 
              )
          );
          this.setState({ filterText: newFilterText });
        }}
        filterText={this.state.filterText}
      />
      {/* <div>
        <button 
          disabled={!disabledButtons} 
          onClick={this.handleVerify}
        >
          Bestätigen
        </button>
        <button 
          disabled={!disabledButtons}
          onClick={this.handleRemoveMembers}
        >
          Zurücknehmen
        </button>
      </div> */}
      </div>
    );
  };

  render(){
    
    const {talkServerData} = this.props

    if(!this.props.authenticated) {
      return <Redirect to={"/"} />
    }
    if(this.props.user) {
      this.filteredItems = this.props.users.filter(
        (item) =>
          item.name && item.name.toLowerCase().includes(this.state.filterText.toLowerCase()) ||  item.email && item.email.toLowerCase().includes(this.state.filterText.toLowerCase())
      );
    } else {
      this.filteredItems = []
    }

    return (
      <div className={style.Admin}>
        <ReactTooltip />

        <AdminChartsSection stats={this.props.stats}/>
        <div className="row">
        <div className={"col-md-12 pb-4"}>
              <h2 className={`${areaStyle.mainHeading} mt-5`}>Nutzer</h2>
              <div className={areaStyle.mainText}>Allgemeine Nutzerverwaltung</div>
              <div className={`${style.AdminTable} mt-2`}>
                <DataTable
                  subHeader
                  subHeaderComponent={this.getSubHeaderComponent()}
                  noHeader
                  columns={this.columns}
                  data={this.filteredItems}
                  pagination
                  selectableRows
                  Clicked
                  onSelectedRowsChange={this.handleRowChange}
                  selectableRowsComponent={Checkbox}
                  persistTableHead
                />
              </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md-3">
          <div className={style.AdminTable}>
          <h1>TPP Meet Server</h1>
            <div
              className={`${style.header} d-flex align-items-center justify-content-between`}
            >
              <div>
                <div className={style.serverStatus}>
                  <p><strong>Server status</strong>: <div className={`${style.badge} ${talkServerData.serverStatus == 2 && style.green} ${talkServerData.serverStatus == 3 && style.red}`}>{talkServerData.serverStatusName}</div></p>
                  <p><strong>Pipeline status</strong>: <div className={`${style.badge} ${talkServerData.status == 2 && style.green} ${talkServerData.status == 3 && style.red}`}>{talkServerData.statusName}</div></p>

                  {/* <p>Pipeline created: {convertDateTimeToDateAndTime(talkServerData.created)}</p>
                  <p>Pipeline update: {convertDateTimeToDateAndTime(talkServerData.updated)}</p> */}
                  <p><strong>Last action</strong>: <code>{talkServerData.action}</code></p>
                </div>
                <div className={style.lineSeparator}></div>
                {!talkServerData.serverStatus && <button disabled={[0,1].includes(talkServerData.status)} onClick={()=>this.props.triggerTalkServer("apply")}>
                  {[0,1].includes(talkServerData.status) ? ("Please wait..."):("Start server")}</button>}
                {talkServerData.serverStatus == 2 && 
                  <button 
                    disabled={[0,1].includes(talkServerData.status)}
                    onClick={()=>this.props.triggerTalkServer("destroy")}>
                  {[0,1].includes(talkServerData.status) ? ("Please wait..."):("Stop server")}</button>}
                  <button 
                    disabled={!talkServerData.serverStatus}
                    onClick={()=>window.open(talkServerData.serverHost, '_blank').focus()}>
                    Open <FontAwesomeIcon icon={faExternalLink} />
                  </button>
              {talkServerData.status == 1 && <p className={style.serverStatusInformation}>Please wait till pipeline finished. <br/>This can take up to 5min.</p>}
              </div>
            </div>
          </div>
        </div>
        <div className={style.AdminTable}>
        <h1>Plattform Algorithmen</h1> 
        <div
          className={`${style.header} d-flex align-items-center justify-content-between`}
        >
    
          <div className={style.serverStatus}>
            <FormControl sx={{ m: 1, width: 200 }}>
              <InputLabel>Aktionen & Projekte</InputLabel>
              <Select
                value={this.props.eventAlgo}
                onChange={this.handleEventProjectChange}
                autoWidth
                label="Aktionen & Projekte"
              >
                <MenuItem value={1}>Keine externen Aktionen</MenuItem>
                <MenuItem value={2}>Nur externe Aktionen(50km)</MenuItem>
                <MenuItem value={3}>Beides</MenuItem>
              </Select>
            </FormControl>
          </div>
            </div>
        </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.admin.statsLoading,
    stats: state.admin.stats,
    authenticated: state.auth.token !== null,
    staff: state.profile.is_staff,
    profileLoading: state.profile.loading,
    talkServerLoading: state.admin.talkServerLoading,
    talkServerData: state.admin.talkServerData,
    eventAlgo: state.admin.stats.event_algo,
    users: state.admin.users,
    usersFetchLoading: state.admin.usersFetchLoading

  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchAdminStats: () => dispatch(fetchAdminStats()),
    fetchTalkServerStatus: () => dispatch(fetchTalkServerStatus()),
    triggerTalkServer: action => dispatch(triggerTalkServer(action)),
    changeAlgo: algo => dispatch(changeAlgo(algo)),
    fetchUsers: () => dispatch(fetchUsers())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
const FilterComponent = ({ filterText, onFilter }) => (
  <Input
    placeholder="Suche nach Namen"
    className={style.search}
    prefix={
      <FontAwesomeIcon icon={faSearch} className="fa-fw" />
    }
    id="search"
    aria-label="Search Input"
    value={filterText}
    onChange={onFilter}
  />
);
const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <label className={style.containerCheckbox}  >
      <input 
        type="checkbox"
        ref={ref}
        {...rest} 
      />
      <span className={style.checkmark} onClick={onClick}></span>
  </label>
  
));
