import React from "react";
import style from "../style/createArea.module.css";
import { Link, Redirect } from "react-router-dom";
import { authLogin } from "../store/auth";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import GoogleLogin from "./GoogleLogin";
import FacebookLogin from "./FacebookLogin";
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faLock, faLockAlt, faSignInAlt, faUser } from "@fortawesome/pro-regular-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;


class Login extends React.Component {
  state = {
    username: "",
    password: "",
    passwordShown: false
  };
  

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  togglePasswordVisiblity = () => {
    this.setState({passwordShown: !this.state.passwordShown});
  };

  render() {
    const { error, loading, token } = this.props;
    const { username, password } = this.state;
    
    if (token) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const redirectTo = params.get('redirectTo');
      if (redirectTo) {
        return <Redirect to={redirectTo} />
      }
      else {
        return <Redirect to="/" />
      }
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Anmelden - TreePlantingProjects" /> 
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className={"col-md-6"}>
            <img className={"img-fluid"} src="/images/login_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <p onClick={this.props.history.goBack} className={style.backLink}>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
            </p>

            <div className={` d-flex justify-content-center`}>
            
              <div className={`${style.contentBox}`}>
                <h2 className={style.mainHeading} style={{marginTop: "0.2em"}}>Anmelden</h2>
                
                <div className={`mt-5 ${style.socialLogin}`}>
                  <GoogleLogin />
                  <FacebookLogin />
                </div>

                <div className={`mt-4 d-flex justify-content-center align-middle`}>
                  <p>- oder -</p>
                </div>

                <form className={"mt-4"} action="" onSubmit={this.handleSubmit}>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="email"
                      onChange={this.handleChange}
                      value={username}
                      required
                      name="username"
                      placeholder="E-Mail-Adresse"
                    />
                    
                    <FontAwesomeIcon icon={faUser} className={style.inputIcon} />
                  </div>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="password"
                      onChange={this.handleChange}
                      value={password}
                      name="password"
                      type={this.state.passwordShown ? "text" : "password"}
                      required
                      placeholder="Passwort"
                    />
                    <FontAwesomeIcon icon={faLockAlt} className={style.inputIcon} />
                  <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                  </div>

                  <Link to={"/reset_password"}>
                    <p>Passwort vergessen?</p>
                  </Link>

                  <button type="submit" className={`${style.formBtn}`}>
                  <FontAwesomeIcon icon={faSignInAlt} className="fa-fw" />
                    Anmelden
                  </button>
                </form>

                <div className={`${style.mainText}`}>
                    <p>Neu bei TreePlantingProjects?<Link to={"/signup"} style={{marginLeft: ".3em"}}><strong>Jetzt mitmachen</strong></Link></p>                  
                </div>

              </div>
            </div>
          </div >
        </div >
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login));
