import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React from "react";
import { Helmet } from 'react-helmet-async';
import ScrollToTop from './utils/ScrollToTop';
function App() {
  return (
    <div className="App">
        <BrowserRouter>
        <ScrollToTop>
          <Routes />
          <Helmet
            encodeSpecialCharacters={true}
            titleTemplate="%s - TreePlantingProjects"
            defaultTitle="TreePlantingProjects"
          >
            <meta name="description" content="Schau dir TreePlantingProjects an." />
            <meta name="og" content="Schau dir TreePlantingProjects an." />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg"/>
          </Helmet>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
          />
          </ScrollToTop>
        </BrowserRouter>
    </div>
  );
}

export default App;
