import React from "react";
import style from "../../style/createArea.module.css";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { editArea } from "../../store/areas";
import { toast } from "react-toastify";
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCube, faEdit, faFlag, faImage, faLink, faMapMarkedAlt, faMapMarkerAlt, faMapPin } from "@fortawesome/pro-regular-svg-icons";

class EditArea extends React.Component {
  constructor(props) {
    super(props);
    const { name, size, image, location_link, location_zip, description, locations} = this.props.areaDetails
    this.state = {
      fileUploadState: "",
      location: name,
      location_zip,
      size,
      link: location_link,
      image: null,
      image_bg: image,
      description,
      id: null,
      location_mark: locations[0] && locations[0]["location_mark"],
      location_number: locations[0] && locations[0]["location_number"],
    }
    this.inputReference = React.createRef();
  }


  fileUploadAction = () => this.inputReference.current.click();
  fileUploadInputChange = (e) => {
    var size = e.target.files[0].size / 1024 / 1024; // in MiB
    if(size > 10) {
      toast.error("💥 Die Bilddatei ist zu groß! (max. 10MB")
      return ""
    }
    this.setState({ fileUploadState: e.target.value });
    this.setState({ image: e.target.files[0] });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { id, location, size, link, image, description } = this.state;
      this.props.edit(id, location, size, link, image, description, this.props.user);
      this.props.history.goBack();
    
  };

  componentDidMount() {
    this.setState({id: this.props.areaDetails.id})
  }

  render() {
    const { location, size, link, image, image_bg, description, location_zip, location_mark, location_number } = this.state;
    if (!this.props.authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Fläche bearbeiten - TreePlantingProjects" /> 
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className="col-md-6">
            <img className={"img-fluid"} src={image_bg} alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <Link to="/areas" className={style.backLink}>
              <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </Link>
            <h2 style={{marginTop: "0.8em"}} className={style.mainHeading}>{location} bearbeiten</h2>
            <form className="mt-5" action="" onSubmit={this.handleSubmit}>
              <div className={"row"}>
                <div className={"col-md-6"}>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      onChange={this.handleChange}
                      value={location}
                      name="location"
                      required
                      placeholder="Name"
                    />
                    <FontAwesomeIcon icon={faMapMarkerAlt} className={style.inputIcon} />

                    <p className={style.required}>Unter welchem Namen ist die Fläche bekannt?</p>
                  </div>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="search"
                      name="location_zip"
                      value={location_zip}
                      readonly
                      disabled
                    />
                    <FontAwesomeIcon icon={faMapMarkerAlt} className={style.inputIcon} />
                    <p className={style.required}>Wird automatisch generiert</p>
                  </div>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      onChange={this.handleChange}
                      value={size}
                      required
                      name="size"
                      placeholder="Größe in Hektar"
                    />
                    <FontAwesomeIcon icon={faCube} className={style.inputIcon} />
                    <p className={style.required}>Wie Groß ist die Fläche (in Hektar)?</p>
                  </div>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      readOnly="readOnly"
                      required
                      placeholder={this.state.fileUploadState ? (this.state.fileUploadState.replace("C:\\fakepath\\", "")) : ("Bild ändern")}
                      onClick={this.fileUploadAction}
                    />
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="image"
                      hidden
                      accept="image/*"
                      data-type='image'
                      ref={this.inputReference}
                      onChange={this.fileUploadInputChange}
                    />
                    <FontAwesomeIcon icon={faImage} className={style.inputIcon} />
                  </div>
                </div>
                <div className={"col-md-6"}>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      required
                      value={location_mark}
                      name="location_mark"
                      placeholder="Gemarkung"
                      disabled
                    />
                    <FontAwesomeIcon icon={faFlag} className={style.inputIcon} />
                    <p className={style.required}>Gemarkung</p>
                  </div>
                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="text"
                      required
                      value={location_number}
                      name="location_number"
                      placeholder="Flurnummer"
                    />
                    <FontAwesomeIcon icon={faMapMarkerAlt} className={style.inputIcon} />
                    <p className={style.required}>Flurnummer</p>
                  </div>
                  <textarea
                    className={style.commonInput}
                    onChange={this.handleChange}
                    defaultValue={description}
                    name="description"
                    cols="30"
                    rows="10"
                    required
                    placeholder="Erzähl uns, was Du über Deine Fläche weißt!
                    Schreib hier einfach alles rein, was Du für wichtig hältst!"
                  ></textarea>
                  <div className={"text-right mt-3"}>
                  <button type={"submit"} className={style.formBtn}>
                    <FontAwesomeIcon icon={faEdit} className="fa-fw" />
                    Fläche aktualisieren</button>
                </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.auth.loading,
    authenticated: state.auth.token !== null,
    error: state.auth.error,
    areaDetails: state.areas.list[ownProps.match.params.areaName],
    user: state.profile.user_id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    edit: (id, location, size, link, image, description, owner) => {
      dispatch(editArea(id, location, size, link, image, description, owner))
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditArea);
