import React from "react";
import style from "../../style/goal.module.css";
import { Progress } from "antd";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

export default function ProjectsGoal({authenticated}) {
  return (
    <div className={style.goal} style={{marginBottom: "1em"}}>
      <div className={`d-block  d-md-flex justify-content-between`} style={{position: "relative"}}>
        <img src="/images/project-info.jpg" alt="" className={style.coverBg} />
        <div className={style.contentSide}>
          <div className={style.contentHeader}>

          </div>
          <h1 className={style.mainHeading}>
            Projekte
          </h1>
          <div className={style.mainText}>
            Hier siehst Du eine Übersicht der aktuellen Projekte. <br />
            Wenn Du Projekte in deiner Nähe finden willst, benutze einfach die Suchfunktion. Projekte, die Dich interessieren, kannst Du natürlich abonnieren, damit Du nichts mehr verpasst. Oder Du trägst Dich direkt als Helfer, Reporter oder Organisator für ein Projekt ein.
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginTop: "2em" }}
          >
            {!authenticated ? (
              <>
              <Link to={`/login?redirectTo=${window.location.pathname}`} className={`${style.greenBtn} mt-0`}>
                Jetzt bei TPP anmelden
              </Link>
              </>
            ):(""
            )
            }

          </div>
        </div>
        <div className={style.coverImage}>
          <img
            className={""}
            src="/images/project-info.jpg"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}
