import React from "react";
import style from "../style/createArea.module.css";
import { Link, Redirect } from "react-router-dom";
import { resetPasswordConfirm } from "../store/auth";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faLock, faLockAlt } from "@fortawesome/pro-regular-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

class ResetPasswordConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      id: "",
      token: "",
      passwordShown: false
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { id, token, password, passwordScore } = this.state;
    if (password && passwordScore) {
      this.props.resetPasswordConfirm(id, token, password);
      this.props.history.push(`/login?redirectTo=${window.location.pathname}`)
    }

  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });

  };

  handlePasswordScore = e => {
    this.setState({ passwordScore: e })
  }

  componentDidMount() {
    const { id, token } = this.props.match.params;
    this.setState({ id, token })
  }

  togglePasswordVisiblity = () => {
    this.setState({passwordShown: !this.state.passwordShown});
  };


  render() {
    const { error, loading, authenticated } = this.props;
    const { password } = this.state;

    if (authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <div className={style.form}>
        <ReactTitle title="Passwort zurücksetzen - TreePlantingProjects" />
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className={"col-md-6"}>
            <img className={"img-fluid"} src="/images/reset_password_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <h2 className={style.mainHeading}>Gleich geschafft</h2>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>

                  <div className={style.formInput}>
                    <input
                      className={style.commonInput}
                      type="password"
                      onChange={this.handleChange}
                      value={password}
                      name="password"
                      placeholder="Passwort"
                      type={this.state.passwordShown ? "text" : "password"}
                      minLength="8"
                      required
                    />
                    <FontAwesomeIcon icon={faLockAlt} className={style.inputIcon} />
                    <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                    <PasswordStrengthBar password={password}
                      scoreWords={["schwaches Passwort", "schwaches Passwort", "da geht noch was", "gutes Passwort", "starkes Passwort"]}
                      shortScoreWord="zu kurzes Passwort"
                      minLength="8"
                      onChangeScore={this.handlePasswordScore}
                    />
                  </div>
                  <div className="text-left mt-2">
                    <button type="submit" className={style.formBtn}>
                      <FontAwesomeIcon icon={faLockAlt} className="fa-fw" />
                        Neues Passwort setzen</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    authenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPasswordConfirm: (password, id, token) => dispatch(resetPasswordConfirm(password, id, token)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPasswordConfirm));
