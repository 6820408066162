import React from "react";
import style from "../../style/createArea.module.css";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { removeMember } from "../../store/projects"
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { getProjectIdBySlug } from "../../store/utility";

const userMinus = <FontAwesomeIcon icon={faUserMinus} />;


class ProjectsLeave extends React.Component {

  constructor(props) {
    super(props);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.removeMember(this.props.projectLeave);
    this.props.history.goBack();
  }

  render() {
    const {error, loading, token, projectName, projectLeave} = this.props;
    if (!token || !projectName) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    return (
        <div className={style.form}>
          <ReactTitle title={projectName+" verlassen - TreePlantingProjects"} />
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className="col-md-6">
              <img className={"img-fluid"} src="/images/join_project_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={style.mainHeading}>{projectName} verlassen</h2>
              <div className={style.mainText}>Bitte bestätige, dass du {projectName} verlassen möchtest.</div>
              <div className={"row pt-3"}>
              <div className={"col-md-12"}>
                  <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>
                    <div className="text-left mt-2">
                      <button type="submit" className={style.formBtn} style={{backgroundColor: "#E65D5D"}}>
                        <span>{userMinus}</span>
                        {/* <img src="/images/user-plus-solid.svg" alt="" className="mr-2"/> */}
                        Projekt verlassen</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  return {
    loading: state.auth.loading,
    profile: state.profile,
    project: id,
    projectLeave: state.projects.leave,
    projectName: state.projects.about[id].name,
    error: state.auth.error,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeMember: (member) => dispatch(removeMember(member)),
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsLeave));
