import React from "react";
import ProjectMembersCard from "./ProjectMembersCard";
import { motion } from "framer-motion";

export default function ProjectMembers({members, user}) {
  return (
    members.map((member => (
      <ProjectMembersCard key={member.name+member.role} member={member} user={user}/>
    )))
  );
}
