import React from "react";
import style from "../../style/goal.module.css";
import { withRouter } from "react-router-dom";
import Skeleton from '@material-ui/lab/Skeleton';

class LoadingGoal extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <div className={style.goal}>
        <div className={`d-block  d-md-flex justify-content-between`} style={{ position: "relative" }}>
          <Skeleton variant="rect" width={"100%"} height={"100%"} style={{borderRadius:"1.5em"}} />
          <div className={style.coverImage}>
          <Skeleton variant="rect" width={"32em"} height={"100%"} style={{position: "absolute", left: "0"}}/>
          </div>
          <div>
            <div className={style.contentSide} style={{marginRight: "0em"}}>
              <div className={style.contentHeader}>
                <div>
                <Skeleton height={5} width="50%"/>
                </div>
              </div>
              <h1 className={style.mainHeading}>
              <Skeleton height={20} width={"10em"}/>
              </h1>
              <div className={style.mainText}>
                <p><Skeleton height={10} width="80%"/></p>
                <p><Skeleton height={10} width="70%"/></p>
                <p><Skeleton height={10} width="60%"/></p>
              </div>
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "2em" }}
              >
                
            </div>
          </div>

        </div >
      </div >
      </div>
    );
  }
}



export default withRouter(LoadingGoal);
