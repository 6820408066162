import React, { useState } from "react";
import HomeGoal from "../components/HomeGoal";
import style from "../style/home.module.css";
import {connect} from "react-redux";
import {fetchBlog} from "../store/blog";
import { fetchNotifications, setTmpPlz } from "../store/profile";
import BlogCard from "../components/BlogCard";
import ButtonCard from "../components/ButtonCard";
import {Link} from "react-router-dom";
import Actiondata from "../data/homeActions.json";
import MetaTags from 'react-meta-tags';
import DashboardGoal from "../components/DashboardGoal";

import LoadingCard from "../components/projects/LoadingCard";
import { fetchEventsNearUser } from "../store/events";
import EventCard from "../components/EventCard";
import Confirm from "../components/Confirm";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPlzInput: false,
            showAllEvents: false
        }

    }

    setShowAllEvents = showAllEvents => {
        this.setState({showAllEvents})
    }

    componentDidMount() {
        this.props.getBlog();

        if(this.props.authenticated) {
            this.props.fetchNotifications()
            this.props.fetchEventsNearUser(this.props.authenticated, this.props.profile.plz);
        } else {
            if (!this.props.plz && navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.handleNewPosition, this.handleNewPositionByInput);

            } else {
                this.props.fetchEventsNearUser(false, this.props.plz)
            }
        }

    }

    handleNewPosition = (position) => {
        this.props.fetchEventsNearUser(false, {lat:position.coords.latitude, lng: position.coords.longitude});
    }

    handleNewPositionByInput = (plz, confirmed=false) => {
        if(!confirmed) this.setState({showPlzInput: true});
        else {
            this.setState({showPlzInput: false});
            this.props.setTmpPlz(plz);
            this.props.fetchEventsNearUser(false, plz);
        }

    }


    componentDidUpdate() {
    }

    profileCompleteCheck = () => {
        const {first_name, last_name, image_url, location} = this.props.profile;
        let completed = false;
        if(first_name && last_name && image_url && location) completed = true
        let data = Actiondata;
        if(this.props.authenticated && completed || !this.props.authenticated) {
            data = data.slice(1)
        } 
        return [data[0], data[1]]
    }

    render() {
        const {loaded, error, authenticated, events, eventsLoading} = this.props;
        const posts = this.props.posts
        let list = events
        if(events.length > 2 && !this.state.showAllEvents) list = [list[0], list[1]]
        return (
            <div className={style.ProjectDetail}>
                <MetaTags>
                    <title>Start - TreePlantingProjects</title>
                    <meta name="description" content="Schön, dass Du zu uns gefunden hast. Aktuell befindet sich die Plattform noch im Aufbau, daher können manche Funktionen noch fehleranfällig sein. Du kannst dich aber bereits anmelden, Dein Profil ausfüllen, nach Projekten suchen und diese abonnieren oder Dich als Helfer*in, Reporter*in oder Organisator*in eintragen. Außerdem kannst Du uns Flächen zur Verfügung stellen, die wir dann den Projektteams zur Bepflanzung anbieten." />
                    <meta property="og:title" content="Willkommen bei TreePlantingProjects" />
                    <meta property="og:image" content="https://treeplantingprojects.com/wp-content/uploads/2020/04/start_thun.jpg" />
                </MetaTags>
                {this.state.showPlzInput && <Confirm title="PLZ eintragen" type="input" text={"Bitte gebe eine PLZ ein, damit wir Aktionen in deiner Nähe finden können."} handleConfirm={e=>this.handleNewPositionByInput(e, true)} handleBack={()=>this.setState({showPlzInput:false})} open2={this.state.showPlzInput}/>}
                {authenticated ? (
                    <DashboardGoal profile={this.props.profile} />
                ):(
                    <HomeGoal authenticated={authenticated}/>
                )}
                
                <div
                    className={`d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start ${style.cards}`}
                >
                    {this.profileCompleteCheck().map((action => (
                        <Link to={action.target} key={action.title}
                        >
                            <ButtonCard action={action}/>
                        </Link>
                    )))}
                </div>
                    <div>
                <div
                    className="d-flex align-items-center"
                    style={{marginBottom: "2.5em"}}
                >
                    <h1>Aktionen in deiner Nähe</h1>
                    {authenticated ? (
                    <Link to="/settings">PLZ ändern</Link>
                    ):(
                        <p style={{marginTop: "1.5em"}}>
                            {this.props.plz} 
                            <a style={{cursor: "pointer", marginLeft: "0.5em"}}onClick={()=>this.handleNewPositionByInput(false)}>PLZ ändern</a>
                        </p> 
                    )}
                </div>
                <div
                    className={`d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start ${style.cards}`}
                >
                {!events.length && eventsLoading && Array.from(Array(3).keys()).map((key => (
                    <LoadingCard key={"l1"+key} variant="small"/>
                )))}
                    <>
                    {!events.length && <p style={{marginLeft: "1em"}}>Leider keine Aktionen in deiner Nähe gefunden.</p>}
                    {list.map((event => (
                        <Link to={'/projects/'+event.project_slug+'/events/'+event.id} key={"link1"+event.id}>
                            <EventCard event={event} key={"card1"+event.id} />
                        </Link>
                        )))}
                        {events.length > 2 &&  
                            <p style={{marginLeft: "1em", cursor: "pointer", fontSize: this.state.showAllEvents ? ("2em"):("3em")}} onClick={()=>this.setShowAllEvents(!this.state.showAllEvents)}>{this.state.showAllEvents ? 
                            ("Weniger Aktionen anzeigen"):("+ "+(events.length-2))}
                            </p>
                        } 
                        </>
                </div>
                </div>
                <div
                    className="d-flex align-items-center"
                    style={{marginBottom: "2.5em"}}
                >
                    <h1>Neuigkeiten</h1>
                    <Link to="/blog">Alle Blogeinträge anzeigen</Link>
                </div>
                <div
                    className={`d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start ${style.cards}`}
                >
                {!posts.length && Array.from(Array(3).keys()).map((key => (
                    <LoadingCard key={"l2"+key} variant="small"/>
                )))}
                    {posts.map((post => (
                                    <a
                                        href={post.link} key={post.title} target="_blank"
                                    >
                                    <BlogCard post={post}/>
                                    </a>
                        )))}
                </div>
            
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        authenticated: state.auth.token !== null,
        posts: state.blog.list,
        loaded: state.blog.loaded,
        error: state.blog.error,
        profile: state.profile,
        events: state.events.nearUser || [],
        plz: state.profile.plz || state.profile.tmpPlz,
        eventsLoading: state.events.fetchNearUserLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBlog: () => dispatch(fetchBlog(3)),
        fetchNotifications: () => dispatch(fetchNotifications()),
        fetchEventsNearUser: (auth, plz) => dispatch(fetchEventsNearUser(auth, plz)),
        setTmpPlz: plz => dispatch(setTmpPlz(plz))
    };
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
