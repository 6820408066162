import { createSlice } from "@reduxjs/toolkit";
import { getEventFromList, getEventIndexFromList } from "./utility";
import { toast } from 'react-toastify';


const slice = createSlice({
    name: 'events',
    initialState: {
        loaded: false,
        error: null,
        list: [],
        manageLoading: false,
        updateLoading: false,
        locationLoading: false,
        manage: {},
        locations: {},
        fetchNearUserLoading: false,
        nearUser : [],
        cooperationPartnerLoading: false,
        cooperationPartner: [],
        updateAdditionalParticipantsLoading: false
    },
    reducers: {
        eventsFetchLoading: (events, action) => {
            events.loaded = false;
            events.error = null;
        },
        eventsFetchSuccess: (events, action) => {
            events.list = action.payload.results
            events.loaded = true;
        },
        eventsFetchFail: (events, action) => {
            events.loaded = true;
            events.error = action.payload;
        },
        eventManageParticipationAddSuccess: (events, action) => {
            // const index = getEventIndexFromList(events.list, action.target)
            // events.list[index].participation = true;
            // events.list[index].current_participants += 1;
            // events.list[index].participationLoading = false;
            toast.success("👍 Das ist ja geil. Wir freuen uns auf dich!");
        },
        eventManageParticipationLoading: (events, action) => {
            const index = getEventIndexFromList(events.list, action.target)
            events.list[index].participationLoading = true;
        },
        eventManageParticipationFail: (events, action) => {
            const index = getEventIndexFromList(events.list, action.target)
            events.list[index].participationLoading = false;
        },
        eventManageParticipationDeleteSuccess: (events, action) => {
            const index = getEventIndexFromList(events.list, action.target);
            // events.list[index].participation = false;
            // events.list[index].current_participants -= 1;
            // events.list[index].participationLoading = false;
            toast.success("👋 Schade. Vielleicht ein anderers Mal.");
        },
        eventCreateSuccess: (events, action) => {
            events.updateLoading = false;
            toast.success("👍 Aktion erfolgreich angelegt.") ;
        },
        eventCreateLoading: (events) => {
            events.updateLoading = true;
        },
        eventCreateFail: (events) => {
            events.updateLoading = false;
        },
        eventDeleteSuccess: (events, target) => {
            toast.success("👍 Aktion erfolgreich gelöscht.") ;
            //delete events.list[getEventIndexFromList(events.list, target)]
        },
        eventUpdateLoading: (events, action) => {
            events.updateLoading = true;
        },
        eventUpdateSuccess: (events, action) => {
            toast.success("👍 Aktion erfolgreich aktualisiert");
            events.updateLoading = false;
        },
        eventUpdateFail: (events) => {
            events.updateLoading = false;
        },
        eventManageFetchLoading: (events) => {
            events.manageLoading = true;
        },
        eventManageFetchSuccess: (events, action) => {
            events.manage[action.target] = action.payload;
            events.manageLoading = false;
        },
        eventsManageFetchFail: (events) => {
            events.manageLoading = false;
        },
        eventLocationFetchLoading: (events) => {
            events.locationLoading = true;
        },
        eventLocationFetchSuccess: (events, action) => {
            events.locations[action.target] = action.payload;
            events.locationLoading = false;
        },
        eventLocationFetchFail: (events) => {
            events.locationLoading = false;
        },
        eventsFetchNearUserLoading: events => {
            events.fetchNearUserLoading = true;
        },
        eventsFetchNearUserSuccess: (events, action) => {
            events.fetchNearUserLoading = false;
            events.nearUser = action.payload
        },
        eventsFetchNearUserFail: events => {
            events.fetchNearUserLoading = false;
        },
        eventCooperationPartnerLoading: (events) => {
            events.cooperationPartnerLoading = true;
        },
        eventCooperationPartnerSuccess: (events, action) => {
            const index = events.cooperationPartner.findIndex(partner=>partner.id===action.payload.id);
		    index  === -1 ? events.cooperationPartner.push(action.payload) : events.cooperationPartner[index] = action.payload;
            events.cooperationPartnerLoading = false;
        },
        eventCooperationPartnerFail: (events) => {
            events.cooperationPartnerLoading = false;
        },
        updateAdditionalParticipantsLoading: (events) => {
            events.updateAdditionalParticipantsLoading = true
        },
        updateAdditionalParticipantsSuccess: (events) => {
            events.updateAdditionalParticipantsLoading = false
        },
        updateAdditionalParticipantsFail: (events) => {
            events.updateAdditionalParticipantsLoading = false
        }
    }
});

export const { eventsFetchLoading, eventsFetchSuccess, eventsFetchFail,
    eventManageParticipationAddSuccess, eventManageParticipationDeleteSuccess,
    eventCreateSuccess, eventDeleteSuccess, eventManageFetchLoading, eventManageFetchSuccess,
    eventsManageFetchFail, eventUpdateLoading, eventUpdateSuccess, eventUpdateFail,
    eventLocationFetchLoading, eventLocationFetchSuccess, eventLocationFetchFail,
    eventsFetchNearUserLoading, eventsFetchNearUserSuccess, eventsFetchNearUserFail,
    eventManageParticipationLoading, eventManageParticipationFail,
    eventCooperationPartnerLoading, eventCooperationPartnerSuccess, eventCooperationPartnerFail,
    updateAdditionalParticipantsLoading, updateAdditionalParticipantsSuccess, updateAdditionalParticipantsFail

} = slice.actions
export default slice.reducer;


// Actions
export const fetchProjectEvents = (project, auth) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/projects/" + project+"/events",
                method: "GET",
                auth,
                onLoading: eventsFetchLoading.type,
                onSuccess: eventsFetchSuccess.type,
                onError: eventsFetchFail.type
            }
        })
    };
};

export const manageParticipationAdd = (event, projectSlug) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/events/" + event+"/manage/",
                method: "POST",
                auth: true,
                onSuccess: eventManageParticipationAddSuccess.type,
                onLoading: eventManageParticipationLoading.type,
                onError: eventManageParticipationFail.type,
                target: event,
                onSuccessAction: ()=>fetchProjectEvents(projectSlug, true)
            }
        })
    }
}

export const manageParticipationDelete = (event, projectSlug) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/events/" + event+"/manage/",
                method: "DELETE",
                auth: true,
                onSuccess: eventManageParticipationDeleteSuccess.type,
                onLoading: eventManageParticipationLoading.type,
                onError: eventManageParticipationFail.type,
                target: event,
                onSuccessAction: ()=>fetchProjectEvents(projectSlug, true)
            }
        })
    }
}

export const createEvent = (project, projectSlug, event) => {

    return dispatch => {
        const date = event.date+' '+event.time+':00';
        const fd = new FormData();
        fd.append('title', event.title);
        fd.append('date', date);
        fd.append('information', event.information);
        fd.append('project', project);
        if (typeof event.image !== 'string' && event.image !== undefined) fd.append('image', event.image);
        dispatch({
            type: "apiCall", payload: {
                url: "/eventscreate/",
                method: "POST",
                data: fd,
                auth: true,
                onSuccess: eventCreateSuccess.type,
                onSuccessAction: ()=>fetchProjectEvents(projectSlug, true)
            }
        })
    }
}

export const deleteEvent = (project, projectSlug, event) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/eventsmanage/"+event,
                method: "DELETE",
                data: {project},
                auth: true,
                onSuccess: eventDeleteSuccess.type,
                target: event,
                onSuccessAction: ()=>fetchProjectEvents(projectSlug, true)
            }
        })
    }
}

export const updateEvent = (project, projectSlug, event) => {
    return dispatch => {
        const date = event.date+' '+event.time+':00';
        const fd = new FormData();
        fd.append('title', event.title);
        fd.append('date', date);
        fd.append('information', event.information);
        fd.append('project', project);
        fd.append('max_participants', event.max_participants)
        fd.append('public', event.status)
        fd.append('location', event.locationValue)
        fd.append('location_type', event.locationType)
        if(event.image === true) fd.append('image', '');
        else fd.append('image', event.image);

        dispatch({
            type: "apiCall", payload: {
                url: "/eventsmanage/"+event.eventId+"/",
                method: "POST",
                data: fd,
                auth: true,
                onSuccess: eventUpdateSuccess.type,
                onLoading: eventUpdateLoading.type,
                onError: eventUpdateFail.type,
                target: event.eventId,
                onSuccessAction: ()=>fetchProjectEvents(projectSlug, true)
            }
        })
    }
}


export const fetchEventManage = (event, project) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/eventsmanage/" + event,
                method: "GET",
                auth: true,
                data: {project},
                onLoading: eventManageFetchLoading.type,
                onSuccess: eventManageFetchSuccess.type,
                onError: eventsManageFetchFail.type,
                target: event,
            }
        })
    };
};

export const fetchEventLocation = (event) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/events/" + event+"/location",
                method: "GET",
                onLoading: eventLocationFetchLoading.type,
                onSuccess: eventLocationFetchSuccess.type,
                onError: eventLocationFetchFail.type,
                target: event,
            }
        })
    };
};

export const fetchEventsNearUser = (auth, plz) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/eventsByLocation/",
                method: "POST",
                auth,
                data:{plz},
                onLoading: eventsFetchNearUserLoading.type,
                onSuccess: eventsFetchNearUserSuccess.type,
                onError: eventsFetchNearUserFail.type
            }
        })
    };
};

export const fetchEventCooperationPartner = (event) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/events/"+event+"/cooperation_partner",
                method: "GET",
                onLoading: eventCooperationPartnerLoading.type,
                onSuccess: eventCooperationPartnerSuccess.type,
                onError: eventCooperationPartnerFail.type
            }
        })
    };
};

export const updateParticipants = (event, total_participants, projectSlug) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/events/" + event+"/additional_participants",
                method: "POST",
                auth: true,
                data: {total_participants},
                onLoading: updateAdditionalParticipantsLoading.type,
                onSuccess: updateAdditionalParticipantsSuccess.type,
                onError: updateAdditionalParticipantsFail.type,
                onSuccessAction: ()=>fetchProjectEvents(projectSlug, true)
            }
        })
    };
};