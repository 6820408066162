import React from "react";
import style from "../style/goal.module.css";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-regular-svg-icons";

export default function DashboardGoal({profile}) {
  return (
    <div className={style.goal}>
      <div className={`d-block  d-md-flex justify-content-between`} style={{position: "relative"}}>
      <img src="/images/HomeInfo.jpg" alt="" className={style.coverBg} />
        <div className={style.contentSide}>
          <div className={style.contentHeader}>

          </div>
          <h1 className={style.mainHeading}>
            Hi {profile.first_name+' '+profile.last_name} 👋
          </h1>
          <div className={style.mainText}>
            Schön, dass Du zu uns gefunden hast. <br />
            Aktuell befindet sich die Plattform noch im Aufbau, 
            daher können manche Funktionen noch fehleranfällig sein.
          </div>

            {profile.notifications.length ? (
              <div
                className="d-flex align-items-center"
                style={{ marginTop: "2em" }}
              >
                <Link to={"/notifications"} className={style.goalLink}>
                <p>
                  <FontAwesomeIcon icon={faBell} className="fa-fw"/>
                  Du hast <strong>{profile.notifications.length}</strong> neue Benachrichtigungen
                </p>
                </Link>
              </div>
            ):("")}

        </div>
        <div className={style.coverImage} style={{maxWidth: "30em", display: ""}}>
          <img
            className={""}
            src="/images/HomeInfo.jpg"
            alt=""
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
}
