import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { Link, withRouter } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import style from "../../style/MobProjectGoal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBellSlash, faBell, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faCog, faCube, faHandHoldingSeedling, faHandsHelping, faLink, faPenAlt, faPlus, faShareAll, faTimesCircle, faTreeAlt, faUsers } from "@fortawesome/pro-regular-svg-icons";
const bellSlash = <FontAwesomeIcon icon={faBellSlash} />;
const bell = <FontAwesomeIcon icon={faBell} />;
const userPlus = <FontAwesomeIcon icon={faUserPlus} />;
import { manageParticipationAdd, manageParticipationDelete } from "../../store/events";
import { leaveTeam } from "../../store/teams";
import { MobGoalDescription } from "../MobGoalDescription";


class MobTeamGoal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreDescription: false
    }
  }

  setShowMoreDescription = () => {
    this.setState({showMoreDescription: !this.state.showMoreDescription})
  }
 
  handleShare(team) {
    if (navigator.share) {
      navigator.share({
        title: team.name,
        text: team.about,
        url: window.location.href
      })
      .then(() => console.log('Successful share'))
      .catch(error => console.log('Error sharing:', error));
    }
    else {
      this.props.history.push("/share?type=teamInvite&id="+team.slug)
    }
  }

  render() {
    const { id, about, name, location, members_count, team_images, is_member, invite_token, slug, is_manager, trees_to_plant, trees_planted, can_leave } = this.props.data;
    const { authenticated, team } = this.props;
    const descriptionLength = about;
    let goalStyle = {
      height: "500px"
    }
    if(is_member) goalStyle.height = "540px"
    if(is_manager) goalStyle.height = "600px"
    if(this.state.showMoreDescription) goalStyle.height = "640px"
    if(this.state.showMoreDescription && is_manager) goalStyle.height = "680px"
    return (
      <div className={style.project_page} style={goalStyle}>
        <div className={style.project_page_imgbox}>
          <img
            src={team_images.image}
            alt=""
          />
          
        </div>
        {team_images.logo && <img src={team_images.logo} alt="" className={style.teamLogo}/> }

        <div className={style.project_page_content}>
          <div className={style.top}>
            <div className={style.top_left}>
              <div className={style.tile_location} style={{marginLeft: "0.5em"}}><FontAwesomeIcon icon={faUsers} className="fa-fw" style={{marginRight: "0.3em"}}/>{members_count}</div>
              <div className={style.tile_location} style={{marginLeft: "0.5em"}}><FontAwesomeIcon icon={faTreeAlt} className="fa-fw" style={{marginRight: "0.3em"}}/>{trees_planted}0/{trees_to_plant}</div>
            </div>
          
          </div>
          <div className={style.middle}>
          </div>
          <div className={style.bottom}>
            <div className={style.project_textbox}>
              <h2 className={style.eproject_textbox_heading}>
              {name}
              </h2>
              <MobGoalDescription description={about} show={this.state.showMoreDescription} />
              {descriptionLength && descriptionLength.length > 309 && <p onClick={this.setShowMoreDescription}>{this.state.showMoreDescription ? ("Weniger anzeigen"):("Mehr anzeigen")}</p>}
            </div>
          </div>
          <div className={style.bottomButtons}>
              {authenticated && is_member && 
                <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={()=>this.props.history.push('/share?type=teamInvite&id='+slug)}><FontAwesomeIcon icon={faUserPlus} /> Einladen</div>
                </div>
              }
              {authenticated && is_manager && is_member && !team_images.logo && 
                <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={()=>this.props.history.push(window.location.pathname+'/manage')}><FontAwesomeIcon icon={faPlus} /> Logo hinzufügen</div>
                </div>
              }
               {authenticated && is_manager && is_member && team_images.logo && 
                <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={()=>this.props.history.push(window.location.pathname+'/manage')}><FontAwesomeIcon icon={faCog} /> Einstellungen</div>
                </div>
              }
              {authenticated && is_member ? (
              <>
                <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={() => this.props.history.push("/teams/"+slug+"/suggestProject")}><FontAwesomeIcon icon={faHandHoldingSeedling} /> Projekt vorschlagen</div>
                </div>
                {can_leave && <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={() => this.props.handleCancellation(slug)}><FontAwesomeIcon icon={faTimesCircle} /> Verlassen</div>
                </div>}
                <div className={`${style.subscribeBtn} ml-2`}>
                  <div onClick={()=>this.handleShare(this.props.data)}><FontAwesomeIcon icon={faShareAll} /> Teilen</div>
                </div>
              </>
              ):(""
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleCancellation: (team) => dispatch(leaveTeam(team))
  };
};

export default withRouter(connect(null, mapDispatchToProps)(MobTeamGoal));
