import React from "react";
import style from "../style/smallCard.module.css";
export default function AreaSmallCard({area, selected}) {
  let style_selected = {width: "15em"}
  if(selected) style_selected["boxShadow"] = "rgba(0, 0, 0, 0.24) 0px 3px 8px"
  return (
    <div className={style.SmallCard} style={style_selected}>
      <img style={{display: "none"}}/>
      <div></div>
      <img src={area.image} alt="" />
      <div>
        <h6>{area.name}</h6>
        <p>{area.size} Hektar</p>
      </div>
    </div>
  );
}
