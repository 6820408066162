import React from "react";
import style from "../../style/projectCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCube, faHome, faInfo } from "@fortawesome/pro-regular-svg-icons";
import locationTypes from "../../data/locationTypes.json";


export default function LocationCard({ location }) {
  if(location == undefined) return ""
  if(!location.type) return ""
  const {
    id,
    image,
    type,
    link,
    address,
    area,
    size,
    starting_soon
  } = location;
  
  return (
      <div className={` ${style.BlogCard} ${style.LocationCard}`}>
        {image &&<img src={image} alt="" />}
        <div className={style.cardContent}>
          <h1>{locationTypes[type-1].name}</h1>
          <p>Diese Aktion findet {locationTypes[type-1].description}</p>
            {type === 1 &&
            <a href={link} target="_blank">
              <p className={style.project}>
              <FontAwesomeIcon icon={faCube} className="fa-fw" />
                {area} - {size} Hektar
              </p>
            </a>
            }
            {type === 2 ? (
              starting_soon ? (
                <a href={link} target="_blank" >
              <p className={style.project}>
                <strong>
                <FontAwesomeIcon icon={faArrowRight} className="fa-fw" />
                Klicke hier zum Beitreten
                </strong>
              </p>
              </a>
              ):(
              <p className={style.project}>
                <FontAwesomeIcon icon={faInfo} className="fa-fw" />
                Der Link erscheint 15min vor Beginn 
              </p>
            )):("")}
            {type === 3 &&
            <p className={style.project}>
              <FontAwesomeIcon icon={faHome} className="fa-fw" />
              {address}
            </p>
            }
        </div>
      </div>
  );
}
