import { faArrowRight, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import style from'../../style/longProjectCard.module.css';

class NotificationCard extends React.Component {


  render() {
    const {
        id,
        title,
        link,
        date
    } = this.props.data;
    return (
      <div className={style.tile_long} style={this.props.isFirst ? ({height: "auto"}):({})}>
        <div className={style.tile_long_left}>
          <p className={`${style.tile_long_text} ${style.tile_small_margin_left}`} style={{fontFamily: "berlin-regular", wordBreak: "break-all"}}>
           {title}
          </p>
          </div>
        <div className={style.tile_long_right}>
          {link ? (
            <Link to={link}>
              <div className={style.checkBtn}>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </Link>
          ):("")}
          <div onClick={this.props.handleSeen} className={`${style.checkBtn} ml-2`}>
            <FontAwesomeIcon icon={faCheckCircle} />
            </div>
          </div>
      </div>
    );
  }
}

export default NotificationCard;