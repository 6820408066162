import React from "react";
import projects, { prepareRemoveMember } from "../../store/projects";
import style from "../../style/smallCard.module.css";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { getProjectIdBySlug } from "../../store/utility";
import ProfileToggle from "../profile/ProfileToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

class TeamMembersCard extends React.Component {
  constructor(props) {
    super(props)
  }


  render() {
    const { name, image, isManager, profile} = this.props.member

    //if() {
    return (
      <div className={style.SmallCard}>
        <img src={`/images/smCardImage.svg`} alt="" class={!isManager && style.svgGray}/>
        <div className={style.tooltip} style={{display:"none"}}></div>
        <img src={image} alt="" />
        <div>
          <h6>{name}</h6>
          {isManager ? (profile == this.props.currentUser ? (
            <p><strong>Du bist ein Manager</strong></p>
          ):<p><a onClick={()=>this.props.onRoleChange(profile, 0)}>Member</a> | <strong><FontAwesomeIcon icon={faCheck} className="fa-fw"/>Manager</strong></p>):(
            <p><strong><FontAwesomeIcon icon={faCheck} className="fa-fw"/>Member</strong> | <a onClick={()=>this.props.onRoleChange(profile, 1)}>Manager</a></p>)}
          {profile != this.props.currentUser &&
            <>
          <img onClick={()=>{this.props.onRemove(false, {name: name,profile:profile})}} 
            className={style.remove} 
            src="/images/smCardImageRemove.svg" 
            alt="" />
            <div className={style.removeTooltip} onClick={()=>{this.props.onRemove(false, {name,profile})}}  >Aus Team entfernen</div>
            </>
          }
        </div>
      </div>
    );
  }
   
}

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.profile.profile_id
  };
};


const mapDispatchToProps = dispatch => {
  return {
    prepareRemoveMember: (id, role, project) => dispatch({type: prepareRemoveMember.type, payload: {user:id, role, project}}),
    // fetchMembers: (project) => dispatch(fetchProjectMemberDetails(true, project))
  };
};

export default withRouter(
  connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembersCard));
