
import React from "react";
import style from "../style/projectCard.module.css";
import { Progress } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faComments } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";


export default function NewsCard({ news, selected }) {
  const {
    image,
    title,
    date,
    description,
    link,
    messages
  } = news;
  let lDate = new Date(date).toLocaleDateString('de-DE', {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
  })
  let linkUrl = link
  if(image === "/images/defaultNews.jpeg") linkUrl = "#"
  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  const short_description =renderHTML(description.substring(0, 75) + ' - <strong>mehr lesen</strong>');
  let style_selected = {}
  if(selected) style_selected = {boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}
  return (
    <Link to={linkUrl}>
    <div className={style.BlogCard} style={style_selected}>
      <img src={image} alt="" />
      <div className={style.cardContent}>
        <h1>{renderHTML(title)}</h1>
        {description ? (
          <p>{short_description}</p>
        ) : ("")}
        <div className={`row ${style.cardFooter}`}>
        {date ? (
          <p className={style.project}>
            <FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/> {lDate}
          </p>
        ) : (
          ""
        )}
        <p className={style.project}>
            <FontAwesomeIcon icon={faComments} className="fa-fw" />{messages}
          </p>
        </div>

        {/* <p className={style.project}>
            <img src="/images/blogIconWhite.svg" alt=""/> von AUTHOR
          </p> */}
      </div>
    </div>
    </Link>
  );
}
