import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Home from "./pages/Home";
import Project from "./pages/Projects";
import Area from "./pages/Area";
import Blog from "./pages/Blog.js";
import CreateArea from "./components/areas/CreateArea";
import ProjectDetail from "./pages/ProjectDetail";
import Search from "./pages/Search";
import Login from "./components/Login";
import EditArea from "./components/areas/EditArea";

import DashboardLayout from "./pages/layouts/DashboardLayout";
import PlainLayout from "./pages/layouts/PlainLayout";
import DashboardFooterLayout from "./pages/layouts/DashboardFooterLayout";
import Signup from "./components/SignUp";
import ReportArea from "./components/areas/ReportArea";
import Settings from "./components/Settings";
import ProjectsJoin from "./components/projects/ProjectsJoin";
import ProjectsLeave from "./components/projects/ProjectsLeave";
import EmailVerify from "./components/EmailVerify";
import Maintenance from "./components/Maintenance";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ResetPasswordConfirm";
import ProjectSettings from "./components/projects/ProjectSettings";
import ProjectModerate from "./components/projects/ProjectModerate";
import ProjectPost from "./components/projects/ProjectPost";
import EventDetail from "./pages/EventDetail";
import EventsManage from "./components/events/EventsManage";
import NotificationsFull from "./components/profile/NotificationsFull";
import Share from "./components/Share";
import AreaManage from "./pages/AreaManage";

import Admin from "./pages/Admin";
import TeamDetail from "./pages/TeamDetail";
import TeamJoin from "./components/teams/TeamJoin";
import Teams from "./pages/Teams";
import NewTeam from "./components/teams/NewTeam";
import SuggestProject from "./components/teams/SuggestProject";
import ScrollToTop from "./utils/ScrollToTop";
import scrollToTop from "./utils/ScrollToTop";
import TeamManage from "./pages/TeamManage";
import TeamsS from "./pages/TeamsS";


class Routes extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    if(this.props.maintenance === true) return(
      <Route exact path={["*", "/"]}>
      <PlainLayout>
            <Switch>
              <Route exact path="/maintenance" component={Maintenance} />
              <Route exact path="*" component={Maintenance} />
            </Switch>
      </PlainLayout>
      </Route>
    )
    
    return (
      <Switch>
        <Route
          exact
          path={["/", "/projects", "/areas", "/blog", "/search", "/event", 
          "/projects/:projectName", "/projects/:projectName/events/:eventId", "/areas/:areaId/manage", "/admin", "/teams/:teamSlug",
          "/teams", "/teams/:teamSlug/manage"]}
        >
          <DashboardLayout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/projects" component={Search} />
              <Route exact path="/projects/:projectName" component={ProjectDetail} />
              <Route exact path="/areas" component={Area} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/projects/:projectName/events/:eventId" component={EventDetail} />
              <Route exact path="/areas/:areaId/manage" component={AreaManage} />
              <Route exact path="/admin" component={Admin} />
              <Route exact path="/teams/:teamSlug" component={TeamDetail} />
              <Route exact path="/teams" component={TeamsS} />
              <Route exact path="/teams/:teamSlug/manage" component={TeamManage} />
            </Switch>
          </DashboardLayout>
        </Route>
        <Route exact path={["/create_area", "/report_area", "/areas/:areaName"]}>
          <PlainLayout>
            <Switch>
              <Route exact path="/create_area" component={CreateArea} />
            </Switch>
            <Switch>
              <Route exact path="/report_area" component={ReportArea} />
            </Switch>
            <Switch>
              <Route exact path="/areas/:areaName" component={EditArea} />
            </Switch>
          </PlainLayout>
        </Route>
        <Route exact path={[
          "/login", "/signup", "/settings",
          "/projects/:projectName/join", "/projects/:projectName/leave", "/email_verify/:id/:token",
          "/maintenance", "/reset_password", "/reset_password/confirm/:id/:token",
          "/projects/:projectName/settings", "/projects/:projectName/moderate",
          "/projects/:projectName/posts/:postId", "/projects/:projectName/eventsmanage", "/notifications", "/share",
          "/teams/:teamSlug/join/:inviteToken", "/newTeam", "/teams/:teamSlug/suggestProject"
        ]}>
          <PlainLayout>
            <Switch>
              <Route exact path="/login" component={Login} />
            </Switch>
            <Switch>
              <Route exact path="/signup" component={Signup} />
            </Switch>
            <Switch>
              <Route exact path="/settings" component={Settings} />
            </Switch>
            <Switch>
              <Route exact path="/projects/:projectName/join" component={ProjectsJoin} />
            </Switch>
            <Switch>
              <Route exact path="/projects/:projectName/leave" component={ProjectsLeave} />
            </Switch>
            <Switch>
              <Route exact path="/email_verify/:id/:token" component={EmailVerify} />
            </Switch>
            <Switch>
              <Route exact path="/maintenance" component={Maintenance} />
            </Switch>
            <Switch>
              <Route exact path="/reset_password" component={ResetPassword} />
            </Switch>
            <Switch>
              <Route exact path="/reset_password/confirm/:id/:token" component={ResetPasswordConfirm} />
            </Switch>
            <Switch>
              <Route exact path="/projects/:projectName/settings" component={ProjectSettings} />
            </Switch>
            <Switch>
              <Route exact path="/projects/:projectName/moderate" component={ProjectModerate} />
            </Switch>
            <Switch>
              <Route exact path="/projects/:projectName/posts/:postId" component={ProjectPost} />
            </Switch>
            <Switch>
              <Route exact path="/projects/:projectName/eventsmanage" component={EventsManage} />
            </Switch>
            <Switch>
              <Route exact path="/notifications" component={NotificationsFull} />
            </Switch>
            <Switch>
              <Route exact path="/share" component={Share} />
            </Switch>
            <Switch>
              <Route exact path="/teams/:teamSlug/join/:inviteToken" component={TeamJoin} />
            </Switch>
            <Switch>
              <Route exact path="/newTeam" component={NewTeam} />
            </Switch>
            <Switch>
              <Route exact path="/teams/:teamSlug/suggestProject" component={SuggestProject} />
            </Switch>
          </PlainLayout>
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    maintenance: state.version.maintenance,
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Routes));
