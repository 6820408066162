import React from "react";
import style from "../style/projectCard.module.css";
import { Progress } from "antd";

export default function ButtonCard({ action }) {
  const {
    image,
    title,
    description,
  } = action;

  return (
    <div className={style.BlogCard}>
      <img src={image} alt="" />
      <div className={style.cardContent}>
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
}
