import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import style from "../style/goal.module.css";
export default function Confirm({text, handleConfirm, handleBack, open2, type, title}) {
  const [plz, setPlz] = React.useState("");
  const handleInputChange = e => {
    setPlz(e.target.value);
  };

  return (
      <div>
      <Dialog
        open={open2}
        onClose={handleBack}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <h2>{title || "Team löschen"}</h2>
        </DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>{text}</p>
          </DialogContentText>
          {type == "input" && 
          <TextField
            autoFocus
            margin="dense"
            id="plz"
            label="PLZ"
            type={"number"}
            fullWidth
            onChange={handleInputChange}
            variant="standard"
          />
        }
        </DialogContent>
        <DialogActions>
          <div className={style.goal} style={{marginBottom: "0"}}>
          {/* <button className={`${style.grayBtn} mr-1`} onClick={handleBack}>
            Zurück
          </button> */}
          {/* <Button onClick={handleBack}>Zurück</Button> */}
          <button className={`${style.greenBtn}`} onClick={type == "input" ? (()=>handleConfirm(plz)):(handleConfirm)} autoFocus>
            Bestätigen
          </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
