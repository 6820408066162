import React from "react";
import style from "../style/createArea.module.css";
import {Link, Redirect} from "react-router-dom";
import {authLogin, emailVerify} from "../store/auth";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import GoogleLogin from "./GoogleLogin";
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faUserPlus } from "@fortawesome/pro-regular-svg-icons";


class EmailVerify extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
  };

  componentDidMount() {
    const {id, token} = this.props.match.params;
    this.props.verify(id, token);
    this.props.history.push(`/login?redirectTo=${window.location.pathname}`);
  }

  render() {
    const {error, loading, authenticated} = this.props;

    if (authenticated) {
      return <Redirect to="/"/>;
    }
    return (
        <div className={style.form}>
          <ReactTitle title="Bestätige deine E-Mail - TreePlantingProjects" /> 
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className={"col-md-6"}>
              <img className={"img-fluid"} src="/images/login_bg.jpg" alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <h2 className={style.mainHeading}>Gleich geschafft</h2>
              <div className={"row"}>
                <div className={"col-md-12"}>
                  <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>
                    <div className="text-left mt-2">
                      <button type="submit" className={style.formBtn}>
                        <FontAwesomeIcon icon={faUserPlus} className="fa-fw" />
                        E-Mail bestätigen</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    authenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    verify: (id, token) => dispatch(emailVerify(id, token)),
  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerify));
