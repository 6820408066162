import style from "../../style/createArea.module.css";

export default function ProfileToggle({handleChange, checked}) {
    return (
        <label className={style.tree_switch}>
            <input 
            type="checkbox"
            onChange={handleChange}
            name="public_profile"
            defaultChecked={checked} />
            <div>
            <div>
                <svg viewBox="0 0 68 68">
                <defs>
                    <clipPath id="clip-path">
                    <circle id="Oval" cx="34" cy="34" r="34" fill="#fff" />
                    </clipPath>
                </defs>
                <g
                    id="Mask_Group_1"
                    data-name="Mask Group 1"
                    clipPath="url(#clip-path)"
                >
                    <g id="tree" transform="translate(16.979 21.208)">
                    <path
                        id="Path_1500"
                        data-name="Path 1500"
                        d="M184.8,300.255c.907-.841,1.875-2.936,2.927-7.179a.257.257,0,0,0,.009-.047,24.481,24.481,0,0,0-.907-10.547.484.484,0,0,1,.813-.49l.555,1.667a.485.485,0,0,0,.844-.328V280.3a.484.484,0,0,1,.533-.482l2.754.27a.486.486,0,0,1,.438.482v2.13a.485.485,0,0,0,.839.332l1.821-1.942a.485.485,0,0,1,.827.225l.237,1.045a.482.482,0,0,1-.185.5c-1.082.774-4.585,4.263-3.018,12.423a.349.349,0,0,0,.018.068,9.193,9.193,0,0,0,2.559,4.764.483.483,0,0,1-.209.822c-.537.135-1.206.316-1.7.492-1.443.51-3.4-.708-4.051-1.162a.478.478,0,0,0-.478-.039c-.74.35-2.9,1.317-3.738,1.077-.287-.082-.526-.164-.725-.24A.479.479,0,0,1,184.8,300.255Z"
                        transform="translate(-171.082 -253.281)"
                        fill="#997960"
                    />
                    <path
                        id="Path_1501"
                        data-name="Path 1501"
                        d="M75,14.8A9.025,9.025,0,1,0,60.463,4.162,6.251,6.251,0,0,0,50.254,8.273c-.114-.006-.222-.033-.338-.033a6.278,6.278,0,0,0-5.7,8.977,9.025,9.025,0,1,0,14.55,10.236,7.081,7.081,0,0,0,6.019-.026A9.023,9.023,0,1,0,75,14.8Z"
                        transform="translate(-41.589)"
                        fill="#5de68f"
                    />
                    <g
                        id="Group_291"
                        data-name="Group 291"
                        transform="translate(6.656 2.767)"
                    >
                        <circle
                        id="Ellipse_759"
                        data-name="Ellipse 759"
                        cx="2.039"
                        cy="2.039"
                        r="2.039"
                        transform="translate(6.01 4.078)"
                        fill="rgba(0,0,0,0.12)"
                        />
                        <circle
                        id="Ellipse_760"
                        data-name="Ellipse 760"
                        cx="2.039"
                        cy="2.039"
                        r="2.039"
                        transform="translate(18.768)"
                        fill="rgba(0,0,0,0.12)"
                        />
                        <circle
                        id="Ellipse_761"
                        data-name="Ellipse 761"
                        cx="2.455"
                        cy="2.455"
                        r="2.455"
                        transform="translate(14.636 11.081)"
                        fill="rgba(0,0,0,0.12)"
                        />
                        <circle
                        id="Ellipse_762"
                        data-name="Ellipse 762"
                        cx="3.753"
                        cy="3.753"
                        r="3.753"
                        transform="translate(0 9.458)"
                        fill="rgba(0,0,0,0.12)"
                        />
                        <circle
                        id="Ellipse_763"
                        data-name="Ellipse 763"
                        cx="3.177"
                        cy="3.177"
                        r="3.177"
                        transform="translate(0.721 19.814)"
                        fill="rgba(0,0,0,0.12)"
                        />
                        <circle
                        id="Ellipse_764"
                        data-name="Ellipse 764"
                        cx="4.822"
                        cy="4.822"
                        r="4.822"
                        transform="translate(21.168 17.466)"
                        fill="rgba(0,0,0,0.12)"
                        />
                    </g>
                    </g>
                </g>
                </svg>
            </div>
            <svg className={style.bubble_svg} viewBox="0 0 77.682 68.348">
                <circle
                id="Ellipse_759"
                data-name="Ellipse 759"
                cx="5.141"
                cy="5.141"
                r="5.141"
                transform="translate(15.152 10.282)"
                fill="rgba(0,0,0,0.12)"
                />
                <circle
                id="Ellipse_760"
                data-name="Ellipse 760"
                cx="5.141"
                cy="5.141"
                r="5.141"
                transform="translate(47.318)"
                fill="rgba(0,0,0,0.12)"
                />
                <circle
                id="Ellipse_761"
                data-name="Ellipse 761"
                cx="6.189"
                cy="6.189"
                r="6.189"
                transform="translate(36.901 27.939)"
                fill="rgba(0,0,0,0.12)"
                />
                <circle
                id="Ellipse_762"
                data-name="Ellipse 762"
                cx="9.463"
                cy="9.463"
                r="9.463"
                transform="translate(0 23.846)"
                fill="rgba(0,0,0,0.12)"
                />
                <circle
                id="Ellipse_763"
                data-name="Ellipse 763"
                cx="8.01"
                cy="8.01"
                r="8.01"
                transform="translate(1.817 49.957)"
                fill="rgba(0,0,0,0.12)"
                />
                <circle
                id="Ellipse_764"
                data-name="Ellipse 764"
                cx="12.156"
                cy="12.156"
                r="12.156"
                transform="translate(53.369 44.035)"
                fill="rgba(0,0,0,0.12)"
                />
            </svg>
            </div>
        </label>
    )
}