import React from "react";
import smallChartData from "../data/smallChartData.json";
import adminSmallChartCardData from "../data/adminSmallChartCardData.json";
import style from "../style/Admin.module.css";

export default function AdminChartsSection({stats}) {
  const Data = adminSmallChartCardData;
  return (
    <div className="row">
      <div className="col-lg-4 mb-3">
        <div className={style.chartBox}>
          <h1>Aktuelle Plattformnutzer</h1>
          <div className="d-flex align-items-end justify-content-between">
            <div style={{ marginBottom: "1em" }}>
              <h2>{stats.total_users}</h2>
              <img
                src="/images/chartImg.png"
                alt=""
                style={{ width: "8.0625em" }}
              />
            </div>

          </div>
        </div>
      </div>
      <div className="col-lg-8 mb-3">
        <div className="row">
          {Data.map((item) => (
            <div className="col-sm-6 col-md-4 col-lg-3 mb-2">
              <div className={style.chartBox}>
                <h6>{item.title}</h6>
                <div className="d-flex align-items-end justify-content-between">
                  <div>
                    <h5>{stats[item.stats_key]}</h5>
                    <h6 className="mb-0">{item.unit}</h6>
                  </div>
                  <div style={{ height: "3.375em", width: "70%" }}>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
