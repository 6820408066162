import React from "react";
import style from "../../style/createArea.module.css";
import {Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import { fetchProjectDetails, fetchProjectPostMessages, sendProjectPostMessage, removeProjectPostMessage } from "../../store/projects"
import {ReactTitle} from 'react-meta-tags';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMinus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft, faCalculatorAlt, faCalendar, faCalendarAlt, faClock, faCommentLines, faShare } from "@fortawesome/pro-regular-svg-icons";
import { getProjectIdBySlug } from "../../store/utility";

const userMinus = <FontAwesomeIcon icon={faUserMinus} />;


class ProjectsPost extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: ""
    }
    this.project_name = this.props.match.params.projectName;
    this.post_id = this.props.match.params.postId;
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.sendProjectPostMessage(this.post_id, this.state.message);
    this.setState({message: ""})
    window.scrollTo(0, 0)
    
  }

  componentDidMount() {
    if(this.props.token){
      this.props.getProjectDetails(this.project_name, this.props.token, false, this.props.project)
      this.props.fetchProjectPostMessages(this.post_id)
    }
  }

  handleChange = e => {
    let k = e.target.value
    this.setState({ [e.target.name]: k });
  };

  getProjectPost() {
    return this.props.projectNews.filter(
      (post) => {
          return post.id == parseInt(this.post_id)}

      )[0]
  }

  handleRemove(id) {
    this.props.removeProjectPostMessage(id)
  }

  render() {
    
    const {error, loading, token, projectName} = this.props;
    if (!token || !projectName) {
      return <Redirect to={`/login?redirectTo=${window.location.pathname}`}/>;
    }
    if(loading) return ""
    let lDate = new Date(this.getProjectPost().date).toLocaleDateString('de-DE', {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
    })
    const messages = this.props.messages || []
    return (
        <div className={style.form}>
          <ReactTitle title={projectName+" verlassen - TreePlantingProjects"} />
          <div className={`row d-flex vh-100 ${style.sideImage}`}>
            <div className="col-md-6">
              <img className={"img-fluid"} src={this.getProjectPost().image} alt=""/>
            </div>
            <div className={"col-md-6 py-4 pr-5 pl-5"}>
              <p onClick={this.props.history.goBack} className={style.backLink}>
                <FontAwesomeIcon icon={faArrowLeft} className="fa-fw" />
                zurück
              </p>
              <div className={style.post}>
                <h1>{this.getProjectPost().title}</h1>
                <p className={style.postDate}><FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/>{lDate} - {projectName}</p>
                <p>{this.getProjectPost().body}</p>
              </div>
              <div className={"row pt-3"}>
              <div className={`col-md-12`}>
                <div className={style.chatWindow}>
                <div>
                {messages.map((message => (
                  <div className={style.postMessage}>
                  <p><strong>{message.profile.name}:</strong> {message.text}</p>
                  {message.user_message ? (
                  <img onClick={()=>this.handleRemove(message.id)}
                    className={style.remove} 
                    src="/images/removeImage.svg" 
                    alt="" />
                    ):("")}
                  </div>
                )))}
                </div>
                {!messages.length ? (<div>
                  <FontAwesomeIcon icon={faClock} className="fa-fw" />
                  <p>Noch keine Kommentare</p>
                </div>):("")}
                </div>
                  <form className={"mt-5"} action="" onSubmit={this.handleSubmit}>
                    <div className="text-left mt-2">
                    <div className={style.formInput}>
                        <input
                          className={style.commonInput}
                          type="text"
                          name="message"
                          fluid="left"
                          required
                          maxLength="500"
                          placeholder="Neuer Kommentar"
                          value={this.state.message}
                          onChange={this.handleChange}
                          />
                          <FontAwesomeIcon icon={faCommentLines} className={style.inputIcon} />

                        </div>
                      <button type="submit" className={style.formBtn}>
                        <span><FontAwesomeIcon icon={faShare} /></span>
                        Kommentar senden</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = getProjectIdBySlug(ownProps.match.params.projectName, state.projects.mapping)[0].id
  const post_id = ownProps.match.params.postId;

    return {
    project: id,
    projectName: state.projects.about[id].name,
    projectNews: state.projects.news[id],
    messages: state.projects.messages[post_id],
    loading: state.projects.messagesLoading,
    token: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjectDetails: (projectId, auth, fetched, id_) =>
      dispatch(fetchProjectDetails(projectId, auth, fetched, id_)),
    fetchProjectPostMessages: (id) => dispatch(fetchProjectPostMessages(id)),
    sendProjectPostMessage: (id, text) => dispatch(sendProjectPostMessage(id, text)),
    removeProjectPostMessage: (id) => dispatch(removeProjectPostMessage(id))

  };
};

export default withRouter(
    connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsPost));
