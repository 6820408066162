import { createSlice } from "@reduxjs/toolkit";
import { getEventFromList, getEventIndexFromList, getProjectIndexFromTeamList } from "./utility";
import { toast } from 'react-toastify';


const slice = createSlice({
    name: 'events',
    initialState: {
        loading: false,
        error: null,
        list: {},
        myLoading: false,
        myError: null,
        joinLoading: false,
        joinSuccess: false,
        joinError: null,
        leaveLoading: false,
        leaveError: null,
        createLoading: false,
        createError: null,
        createSuccessData: {},
        suggestProjectLoading: false,
        suggestProjectSuccess: false,
        projectsFetchLoading: false,
        projects: {},
        members: {},
        teamMembersLoading: false,
        projectChangeSuggestStatusLoading: false,
        manageChangeLoading: false,
        memberChangeLoading: false,
        events: {},
        eventsLoading: false,
        deleteLoading: false,
        deleteSuccess: false,
        inviteEmailLoading: false,
        inviteEmailSuccess: false
    },
    reducers: {
        teamsFetchLoading: teams => {
            teams.loading = true;
            teams.error = null;
        },
        teamsFetchSuccess: (teams, action) => {
            teams.list = action.payload.results
            teams.loading = false;
        },
        teamsFetchFail: (teams, action) => {
            teams.loading = false;
            teams.error = action.payload;
        },
        teamFetchLoading: teams => {
            teams.list = {}
            teams.loading = true;
            teams.error = null;
        },
        teamFetchSuccess: (teams, action) => {
            teams.list[action.payload.slug] = action.payload
            teams.loading = false;
        },
        teamFetchFail: (teams, action) => {
            teams.loading = false;
            teams.error = action.payload;
        },
        teamJoinLoading: teams => {
            teams.joinLoading = true;
            teams.joinSucess = false;
        },
        teamJoinSucces: (teams, action) => {
            teams.joinLoading = false;
            teams.joinSuccess = true;
            toast.success('👍 Team beigetreten');
        },
        teamJoinFail: (teams, action) => {
            teams.joinLoading = false;
            teams.joinSuccess = false;
            teams.joinError = action.payload;
        },
        teamLeaveLoading: teams => {
            teams.leaveLoading = true;
        },
        teamLeaveSuccess: (teams, action) => {
            teams.list[action.target].is_member = false;
            teams.list[action.target].is_manager = false;
            teams.leaveLoading = false;
            toast.success('👍 Team verlassen');
        },
        teamLeaveFail: (teams, action) => {
            teams.leaveLoading = false;
            teams.leaveError = action.payload;
        },
        teamCreateLoading: teams => {
            teams.createLoading = true;
            teams.createError = null;
            teams.createSuccessData = false 
        },
        teamCreateSuccess: (teams, action) => {
            teams.createSuccessData = {slug:action.payload.slug, inviteToken:action.payload.invite_token}
            //teams.list[action.payload.slug] = action.payload;
            teams.createLoading = false;
            toast.success('👍 Team erstellt.');
        },
        teamCreateFail: (teams, action) => {
            teams.createLoading = false;
            teams.createError = action.payload;
        },
        teamProjectSuggestLoading: (teams) => {
            teams.suggestProjectLoading = true;
            teams.suggestProjectSuccess = false;
        },
        teamProjectSuggestSuccess: (teams) => {
            teams.suggestProjectLoading = false;
            teams.suggestProjectSuccess = true;
            toast.success("👍 Projekt vorgeschlagen")
        },
        teamProjectSuggestFail: (teams) => {
            teams.suggestProjectLoading = false;
            teams.suggestProjectSuccess = false;
        },
        teamProjectsFetchLoading: teams => {
            teams.projectsFetchLoading = true;
         },
        teamProjectsFetchSuccess: (teams, action) => {
            teams.projectsFetchLoading = false;
            teams.projects[action.target] = action.payload.results || [];
        },
        teamProjectsFetchFail: teams => {
            teams.projectsFetchLoading = false;
        },
        teamMembersFetchLoading: teams => {
            teams.teamMembersLoading = true;
        },
        teamMembersFetchSuccess: (teams, action) => {
            teams.teamMembersFetchLoading = false;
            teams.members[action.target] = action.payload
        },
        teamMembersFetchFail: teams => {
            teams.teamMembersLoading = false;
        },
        teamProjectChangeSuggestStatusLoading: teams => {
            teams.projectChangeSuggestStatusLoading = true;
        },
        teamProjectChangeSuggestStatusSuccess: (teams, action) => {
            teams.projectChangeSuggestStatusLoading = false;
            if(action.target.action) {
                toast.success("👍 Projekt akzeptiert")
            } else {
                toast.success("👍 Projekt entfernt")
            }
        },
        teamProjectChangeSuggestStatusFail: teams => {
            teams.projectChangeSuggestStatusLoading = false;
        },
        teamManageChangeLoading: teams => {
            teams.changeLoading = true;
        },
        teamManageChangeSuccess: teams => {
            teams.changeLoading = false;
            toast.success("👍 Team aktualisiert")
        },
        teamManageChangeFail: teams => {
            teams.changeLoading = false;
        },
        teamMemberChangeLoading: teams => {
            teams.memberChangeLoading = true;
        },
        teamMemberChangeSuccess: teams => {
            teams.memberChangeLoading = false;
            toast.success("👍 Team aktualisiert")
        },
        teamMemberChangeFail: teams => {
            teams.memberChangeLoading = false;
        },
        teamManageCleanSuccess: teams => {
            teams.joinSuccess = false;
        },
        teamFetchEventsLoading: teams => {
            teams.eventsloading = true;
        },
        teamFetchEventsSuccess: (teams, action) => {
            teams.eventsLoading = false;
            teams.events[action.target] = action.payload.results;
        },
        teamFetchEventFail: teams => {
            teams.eventsLoading = false;
        },
        teamDeleteLoading: teams => {
            teams.deleteLoading = true;
            teams.deleteSuccess = false;
        },
        teamDeleteSuccess: teams => {
            teams.deleteLoading = false;
            teams.deleteSuccess = true;
            toast.success('👍 Team gelöscht');
        },
        teamDeleteFail: teams => {
            teams.deleteLoading = false;
            teams.deleteSuccess = false;
        },
        teamInviteEmailLoading: teams => {
            teams.inviteEmailLoading = true;
            teams.inviteEmailSuccess = false;
        },
        teamInviteEmailSuccess: teams => {
            teams.inviteEmailLoading = false;
            teams.inviteEmailSuccess = true;
            toast.success('👍 Einladungen werden gesendet');
        },
        teamInviteEmailFail: teams => {
            teams.inviteEmailLoading = false;
        }
    }
});

export const { teamsFetchLoading, teamsFetchSuccess, teamsFetchFail,
    teamFetchLoading, teamFetchSuccess, teamFetchFail,
    teamJoinLoading, teamJoinSucces, teamJoinFail,
    teamLeaveLoading, teamLeaveSuccess, teamLeaveFail,
    teamCreateLoading, teamCreateSuccess, teamCreateFail,
    teamProjectSuggestLoading, teamProjectSuggestSuccess, teamProjectSuggestFail,
    teamProjectsFetchLoading, teamProjectsFetchSuccess, teamProjectsFetchFail,
    teamMembersFetchLoading, teamMembersFetchSuccess, teamMembersFetchFail,
    teamProjectChangeSuggestStatusLoading, teamProjectChangeSuggestStatusSuccess, teamProjectChangeSuggestStatusFail,
    teamManageChangeLoading, teamManageChangeSuccess, teamManageChangeFail,
    teamMemberChangeLoading, teamMemberChangeSuccess, teamMemberChangeFail, teamManageCleanSuccess,
    teamFetchEventsLoading, teamFetchEventsSuccess, teamFetchEventFail,
    teamDeleteLoading, teamDeleteSuccess, teamDeleteFail,
    teamInviteEmailLoading, teamInviteEmailSuccess, teamInviteEmailFail
} = slice.actions
export default slice.reducer;


// Actions
export const fetchTeams = (member) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams?member="+member,
                method: "GET",
                auth: member,
                onLoading: teamsFetchLoading.type,
                onSuccess: teamsFetchSuccess.type,
                onError: teamsFetchFail.type
            }
        })
    };
};

export const fetchTeam = (auth, team) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team,
                method: "GET",
                auth,
                onLoading: teamFetchLoading.type,
                onSuccess: teamFetchSuccess.type,
                onError: teamFetchFail.type
            }
        })
    };
};


export const joinTeam = (token, team) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/manage_member",
                method: "POST",
                auth: true,
                data:{token:token},
                onLoading: teamJoinLoading.type,
                onSuccess: teamJoinSucces.type,
                onError: teamJoinFail.type
            }
        })
    };
};

export const leaveTeam = (team) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/manage_member",
                method: "DELETE",
                auth: true,
                onLoading: teamLeaveLoading.type,
                onSuccess: teamLeaveSuccess.type,
                onError: teamLeaveFail.type,
                target: team
            }
        })
    };
};

export const createTeam = (name, about, image, link, treesToPlant) => {
    return dispatch => {
        const fd = new FormData();
        fd.append('name', name);
        fd.append('about', about);
        if(image) fd.append('image', image);
        fd.append('link', link);
        fd.append('trees_to_plant', treesToPlant)
        dispatch({
            type: "apiCall", payload: {
                url: "/teams",
                method: "POST",
                auth: true,
                data: fd,
                onLoading: teamCreateLoading.type,
                onSuccess: teamCreateSuccess.type,
                onError: teamCreateFail.type,
            }
        })
    };
};

export const suggestProject = (team, project) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/suggestProject",
                method: "POST",
                auth: true,
                data: {project},
                onLoading: teamProjectSuggestLoading.type,
                onSuccess: teamProjectSuggestSuccess.type,
                onError: teamProjectSuggestFail.type,
                target: team
            }
        })
    };
};

export const fetchTeamProjects = (auth, team) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/projects",
                method: "GET",
                auth: auth,
                onLoading: teamProjectsFetchLoading.type,
                onSuccess: teamProjectsFetchSuccess.type,
                onError: teamProjectsFetchFail.type,
                target: team
            }
        })
    };
};

export const fetchTeamMembers = (team) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/members",
                auth: true,
                onLoading: teamMembersFetchLoading.type,
                onSuccess: teamMembersFetchSuccess.type,
                onError: teamMembersFetchFail.type,
                target: team
            }
        })
    }
}

export const changeProjectSuggestStatus = (team, project, action) => {
    return dispatch => {
        var actionTrans = "accept";
        if(!action) actionTrans = "decline"

        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/projects/"+project+"/manage",
                auth: true,
                method: "POST",
                data: {action: actionTrans},
                onLoading: teamProjectChangeSuggestStatusLoading.type,
                onSuccess: teamProjectChangeSuggestStatusSuccess.type,
                onError: teamProjectChangeSuggestStatusFail.type,
                target: {team, project, action},
                onSuccessAction: ()=>fetchTeamProjects(true, team)
            } 
        })
    }
}

export const changeTeam = (slug, team) => {
    return dispatch => {
        const fd = new FormData();
        Object.keys(team).forEach((key, index)  => {
            if(team[key]) {
                fd.append([key], team[key])
            }
        });
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+slug+"/manage",
                auth: true,
                method: "PUT",
                data: fd,
                onLoading: teamManageChangeLoading.type,
                onSuccess: teamManageChangeSuccess.type,
                onError: teamManageChangeFail.type,
                onSuccessAction: ()=>fetchTeam(true, slug)
            } 
        })
    }
}

export const changeTeamMember = (slug, member, role) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+slug+"/members",
                auth: true,
                method: "POST",
                data: {member, role},
                onLoading: teamMemberChangeLoading.type,
                onSuccess: teamMemberChangeSuccess.type,
                onError: teamMemberChangeFail.type,
                onSuccessAction: ()=>fetchTeamMembers(slug)
            } 
        })
    }
}

export const removeTeamMember = (slug, member) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+slug+"/members",
                auth: true,
                method: "DELETE",
                data: {member},
                onLoading: teamMemberChangeLoading.type,
                onSuccess: teamMemberChangeSuccess.type,
                onError: teamMemberChangeFail.type,
                onSuccessAction: ()=>fetchTeamMembers(slug)
            } 
        })
    }
}

export const fetchTeamEvents = (auth, slug) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+slug+"/events",
                auth,
                method: "GET",
                onLoading: teamFetchEventsLoading.type,
                onSuccess: teamFetchEventsSuccess.type,
                onError: teamFetchEventFail.type,
                target: slug
            } 
        })
    }
}


export const deleteTeam = (slug) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+slug,
                auth: true,
                method: "DELETE",
                onLoading: teamDeleteLoading.type,
                onSuccess: teamDeleteSuccess.type,
                onError: teamDeleteFail.type,
            } 
        })
    }
}

export const inviteTeam = (team, emails) => {
    return dispatch => {
        dispatch({
            type: "apiCall", payload: {
                url: "/teams/"+team+"/invite",
                auth: true,
                method: "POST",
                data: {action: "email", list: emails},
                onLoading: teamInviteEmailLoading.type,
                onSuccess: teamInviteEmailSuccess.type,
                onError: teamInviteEmailFail.type,
            } 
        })
    }
}

export const cleanManageSuccess = () => {
    return dispatch => {
        dispatch({type: teamManageCleanSuccess.type})
    }
} 