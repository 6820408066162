import React from "react";
import style from "../style/createArea.module.css";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import {ReactTitle} from 'react-meta-tags';
import { LinearProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faInfo } from "@fortawesome/pro-regular-svg-icons";

class Maintenance extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    if (this.props.maintenance === false) this.props.history.goBack();
    const { maintenance, version , reason, until, progress} = this.props;
    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    let lDate = new Date(until).toLocaleString('de-DE', {
      day: "2-digit",
      year: "numeric",
      month: "2-digit",
      hour: "numeric",
      minute: "numeric" 
    })

    

    return (
      <div className={style.form}>
        <ReactTitle title="Wartungsarbeiten - TreePlantingProjects" /> 
        <div className={`row d-flex vh-100 ${style.sideImage}`}>
          <div className={"col-md-6"}>
            <img className={"img-fluid"} src="/images/maintenence_bg.jpg" alt="" />
          </div>
          <div className={"col-md-6 py-4 pr-5 pl-5"}>
            <h2 className={style.mainHeading}>Wartung / Aktualisierung</h2>
            <div className={"row"}>
              <div className={"col-md-12"}>
              <div className={style.progressBox}>
                    <div className="mb-2">
                      <p>Fortschritt - {progress}%</p>
                      <LinearProgress variant="determinate" color="primary" value={progress} />
                    </div>
                    {until ? (<p><FontAwesomeIcon icon={faCalendarAlt} className="fa-fw"/>Wartung geplant bis: {lDate} Uhr</p>):("")}
                    {version ? (<p><FontAwesomeIcon icon={faInfo} className="fa-fw"/>Aktuelle Version: {version}</p>):("")}
                  </div>
                  <div className={style.progressBox}>
                    {reason ? (renderHTML(reason)):(
                      "Wir aktualisieren gerade unser System. Du wirst automatisch weitergeleitet sobald wir fertig sind."
                    )}
                  </div>
                  
                
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenance: state.version.maintenance,
    version: state.version.name,
    reason: state.version.r,
    until: state.version.u,
    progress: state.version.progress
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Maintenance));
